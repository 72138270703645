import React from "react";

function Corolis() {
  return (
    <div className="container my-5">
      {/* TITRE PRINCIPAL */}
      <div className="text-center mb-5">
        <h1 className="display-4">Transports en commun de Beauvais – Corolis</h1>
        <p className="lead">
          Un réseau desservant l’agglomération du Beauvaisis, avec une histoire riche
          et une modernisation continue.
        </p>
      </div>

      {/* SECTION HISTORIQUE */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Historique</h2>
          <p className="text-center">
            Les origines du transport collectif à Beauvais remontent aux années 1960,
            avec des services limités. Au fil des décennies, le réseau s’est structuré
            et modernisé, pour devenir l’actuel <strong>Corolis</strong>.
          </p>
        </div>
      </div>

      {/* CARDS HISTORIQUES */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* CARD 1 : Années 1960-1978 */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Des débuts timides (années 1960-1978)</h5>
              <p className="card-text">
                C’est dans les années 1960 que naît l’idée d’un réseau de bus à
                Beauvais. En 1978, la ville se dote d’un premier service structuré
                appelé TUB (Transports urbains de Beauvais), formant un réseau
                « en étoile » centré autour de l’hôtel de ville.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 2 : Modernisation (2000-2010) */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Modernisation (années 2000)</h5>
              <p className="card-text">
                Dans les années 2000, la municipalité entreprend un important
                renouvellement de la flotte, avec des bus accessibles,
                confortables et plus respectueux de l’environnement (gaz naturel,
                puis hybrides). Des initiatives mêlant art et mobilité sont menées
                pour dynamiser l’image du réseau.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 3 : Passage à Corolis en 2010 */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Le nom Corolis (2010)</h5>
              <p className="card-text">
                En 2010, dans le cadre d’un nouveau contrat d’exploitation, les TUB
                deviennent <strong>Corolis</strong>. Le réseau adopte une nouvelle
                identité visuelle et la billettique sans contact se déploie. Des bornes
                d’information, un système d’aide à l’exploitation et des annonces
                sonores à bord s’installent progressivement.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 4 : Restructurations et évolutions récentes */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Évolutions (2015 à aujourd’hui)</h5>
              <p className="card-text">
                De 2015 à aujourd’hui, diverses réorganisations voient le jour, avec
                notamment la mise en place d’un billet unique à 1 € encourageant la
                fréquentation. Le réseau introduit aussi des bus électriques et
                hybrides, et réalise plusieurs ajustements (création de lignes
                « Chrono », navettes gratuites, etc.).
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION ORGANISATION */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Organisation du réseau</h2>
          <p className="text-center">
            La communauté d’agglomération du Beauvaisis est l’autorité organisatrice,
            tandis que l’exploitant appartient à un grand groupe de transport. Les
            décisions concernant le tracé, la tarification et les investissements
            relèvent de l’agglomération, qui délègue l’exploitation quotidienne
            (conducteurs, maintenance, gestion commerciale) à une filiale dédiée.
          </p>
        </div>
      </div>

      {/* CARDS ORGANISATION */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* CARD : DSP */}
        <div className="col">
          <div className="card h-100 shadow-sm border-success">
            <div className="card-body">
              <h5 className="card-title">Délégation de service public</h5>
              <p className="card-text">
                Depuis les années 2000, le réseau fait l’objet d’une délégation de
                service public. L’exploitant actuel a obtenu le contrat pour plusieurs
                années, s’engageant sur des objectifs de qualité de service (ponctualité,
                propreté des véhicules, information voyageurs, etc.).
              </p>
            </div>
          </div>
        </div>

        {/* CARD : Budget et financement */}
        <div className="col">
          <div className="card h-100 shadow-sm border-info">
            <div className="card-body">
              <h5 className="card-title">Budget et financement</h5>
              <p className="card-text">
                Le fonctionnement du réseau est principalement financé par le
                versement transport (assis sur la masse salariale des employeurs), et
                par la collectivité. Les recettes voyageurs représentent une part
                modeste, avec plusieurs services proposés gratuitement ou à tarif
                symbolique.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION RÉSEAU ACTUEL */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Le réseau Corolis aujourd’hui</h2>
          <p className="text-center">
            L’offre actuelle se compose de plusieurs lignes urbaines et interurbaines,
            d’une navette gratuite et de services spécifiques, afin de couvrir
            l’agglomération beauvaisienne.
          </p>
        </div>
      </div>

      {/* CARDS RÉSEAU ACTUEL */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* CARD : Lignes Chrono */}
        <div className="col">
          <div className="card h-100 shadow-sm border-warning">
            <div className="card-body">
              <h5 className="card-title">Lignes Chrono</h5>
              <p className="card-text">
                Les lignes <em>Chrono</em> (C1, C2) relient en fréquence renforcée les
                quartiers denses et des pôles commerciaux/éducatifs importants. Elles
                circulent avec un intervalle pouvant descendre à 10–15 minutes,
                proposant une amplitude horaire élargie et une circulation le
                week-end.
              </p>
            </div>
          </div>
        </div>

        {/* CARD : Réseau secondaire */}
        <div className="col">
          <div className="card h-100 shadow-sm border-primary">
            <div className="card-body">
              <h5 className="card-title">Lignes secondaires et suburbaines</h5>
              <p className="card-text">
                En complément, plusieurs lignes (numérotées 3 à 7) desservent le reste
                de la ville et certaines communes voisines. On trouve aussi
                trois lignes interurbaines intégrées au réseau depuis 2022 et un service
                de transport à la demande, couvrant l’ensemble du territoire.
              </p>
            </div>
          </div>
        </div>

        {/* CARD : Navettes et services spéciaux */}
        <div className="col">
          <div className="card h-100 shadow-sm border-success">
            <div className="card-body">
              <h5 className="card-title">Navettes et gratuité</h5>
              <p className="card-text">
                Une navette gratuite, baptisée GratuitBus, relie un parking-relais en
                périphérie au centre-ville. Des navettes spécifiques (ex. Navette
                Express Hôtels) assurent le lien avec l’aéroport de Beauvais-Tillé. Le
                service Corolis à la demande propose, quant à lui, des trajets
                réservables pour les zones excentrées.
              </p>
            </div>
          </div>
        </div>

        {/* CARD : Billetterie et évolutions récentes */}
        <div className="col">
          <div className="card h-100 shadow-sm border-info">
            <div className="card-body">
              <h5 className="card-title">Billetterie et évolutions</h5>
              <p className="card-text">
                La tarification comprend des abonnements mensuels ou annuels (certains
                gratuits selon ressources, statut retraité, etc.), ainsi qu’un billet
                unique à 1 € pour voyager toute la journée. Des nouveautés sont apparues
                récemment : carte de transport régionale et système de tarification
                dégressif basé sur le quotient familial.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION FLOTTES */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Flotte de véhicules</h2>
          <p className="text-center">
            Le parc Corolis comprend majoritairement des bus standards, dont un grand
            nombre alimentés au gaz naturel ou hybrides, voire électriques. Des minibus
            et midibus complètent l’offre, adaptés aux dessertes à plus faible affluence.
          </p>
        </div>
      </div>

      {/* CARDS FLOTTES */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* CARD : Bus standards */}
        <div className="col">
          <div className="card h-100 shadow-sm border-warning">
            <div className="card-body">
              <h5 className="card-title">Bus au gaz, hybrides, électriques</h5>
              <p className="card-text">
                Depuis les années 2000, Beauvais a misé sur des véhicules GNV (gaz
                naturel) et, plus récemment, sur l’hybride ou l’électrique. Certains
                bus électriques ont même été partiellement assemblés localement.
              </p>
            </div>
          </div>
        </div>

        {/* CARD : Accessibilité */}
        <div className="col">
          <div className="card h-100 shadow-sm border-success">
            <div className="card-body">
              <h5 className="card-title">Accessibilité et renouvellement</h5>
              <p className="card-text">
                L’objectif est que l’ensemble du matériel roulant soit accessible
                (plancher bas, rampe d’accès) pour les personnes à mobilité réduite,
                avec un renouvellement progressif chaque année. Ainsi, la grande
                majorité des bus en service répond déjà à ces normes.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION CONCLUSION */}
      <div className="row">
        <div className="col text-center">
          <h2>Conclusion</h2>
          <p>
            Le réseau <strong>Corolis</strong> incarne l’évolution des transports en
            commun de Beauvais, depuis les modestes TUB des années 1960-1970 jusqu’à un
            service plus complet, modernisé et respectueux de l’environnement.
            Malgré les défis (urbanisation, budget, etc.), l’offre s’est étoffée :
            lignes Chrono, navettes gratuites, bus hybrides et électriques. Corolis
            poursuit aujourd’hui son adaptation pour offrir une mobilité accessible
            et efficace aux habitants et visiteurs du Beauvaisis.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Corolis;
