// Reseau.js
import React from 'react';
import { Container, Row, Col, FormControl, Card, InputGroup, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { BiSearch } from 'react-icons/bi';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './Reseau.css';

const Reseau = ({ networks, setSelectedNetwork }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedDept, setSelectedDept] = React.useState("Tous");
  const navigate = useNavigate();

  // Extraire la liste des départements uniques
  const departments = Array.from(new Set(networks.map(n => n.dep_name).filter(Boolean)));
  departments.sort();
  departments.unshift("Tous");

  // Filtrer les réseaux par nom et département
  const filteredNetworks = networks.filter(network => {
    const matchesSearch = (network.agency_name?.toLowerCase() || '').includes(searchTerm.toLowerCase());
    const matchesDept = selectedDept === "Tous" || network.dep_name === selectedDept;
    return matchesSearch && matchesDept;
  });

  // Tri : réseaux de l'Oise en premier, puis par ordre alphabétique
  const sortedNetworks = filteredNetworks.sort((a, b) => {
    if (a.dep_name === 'Oise' && b.dep_name !== 'Oise') return -1;
    if (a.dep_name !== 'Oise' && b.dep_name === 'Oise') return 1;
    return a.agency_name.localeCompare(b.agency_name);
  });

  const handleNetworkSelect = (networkId) => {
    Cookies.set('selectedNetwork', networkId, { expires: 365 });
    setSelectedNetwork(networkId);
    navigate(`/network/${networkId}`);
  };

  return (
    <>
      <Helmet>
        <title>Bus Connect - Choix du réseau</title>
      </Helmet>
      <Container className="mt-2">
      <h2 className="text-center mb-4">
  Choisissez votre <br />
  <span className="mt-5 highlight-busconnect">
    réseau de bus
  </span>
</h2>


        
        <InputGroup className="mb-3">
          <InputGroup.Text>
            <BiSearch size={20} color="#0072ce" />
          </InputGroup.Text>
          <FormControl
            placeholder="Rechercher par nom..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        <Form.Group className="mb-4" controlId="formDeptSelect">
          <Form.Label>Ou sélectionnez votre département :</Form.Label>
          <Form.Select
            value={selectedDept}
            onChange={(e) => setSelectedDept(e.target.value)}
          >
            {departments.map((dept, index) => (
              <option key={index} value={dept}>
                {dept}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Row>
          {sortedNetworks.map((network) => {
            const [prefix, suffix] = network.agency_name.split(" - ");
            return (
              <Col key={network.network_id} xs={6} sm={6} md={4} className="mb-4">
            <Card
  onClick={() => handleNetworkSelect(network.network_id)}
  className="card-custom"
  style={{ cursor: 'pointer', position: 'relative' }} // Ajout de position: relative
>
  {/* Badge en haut à droite */}
  {network.type && (
    <span
      className={`network-type ${network.type.toLowerCase()}`}
      style={{ position: 'absolute', top: '10px', right: '10px' }}
    >
      {network.type}
    </span>
  )}

  <Card.Img
    variant="top"
    src={`/logo/${network.network_id}.png`}
    style={{
      height: '150px',
      objectFit: 'contain',
      padding: '10px',
      backgroundColor: '#f8f9fa'
    }}
    alt={prefix}
  />
  <Card.Body>
    <span className="network-name">{prefix}</span>
    {suffix && <span className="network-suffix">{suffix}</span>}
    <span className="network-dept">
      {network.dep_name} {network.dep_code && `(${network.dep_code})`}
    </span>
  </Card.Body>
</Card>

              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default Reseau;
