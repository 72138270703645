import React, { useState, useEffect } from 'react';
import { Polyline } from 'react-leaflet';

// Détermine si une couleur (format hexadécimal) est sombre
function isColorDark(color) {
  if (!color) return false;
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma < 128;
}

// Calcule la distance entre deux points (en mètres)
function distance(point1, point2) {
  const toRad = (x) => (x * Math.PI) / 180;
  const [lat1, lon1] = point1;
  const [lat2, lon2] = point2;
  const R = 6371000; // rayon de la Terre en mètres
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) ** 2;
  return R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
}

// Extrait la sous-partie du tracé entre deux progressions (de 0 à 1)
// startProgress et endProgress représentent des fractions de la distance totale
function getSubPolyline(shapePoints, startProgress, endProgress) {
  if (shapePoints.length === 0) return [];
  
  // Calcul de la longueur totale du tracé et stockage des distances entre chaque point
  let totalDistance = 0;
  const segmentDistances = [];
  for (let i = 0; i < shapePoints.length - 1; i++) {
    const d = distance(shapePoints[i], shapePoints[i + 1]);
    segmentDistances.push(d);
    totalDistance += d;
  }
  
  const startTarget = totalDistance * startProgress;
  const endTarget = totalDistance * endProgress;
  let currentDistance = 0;
  const subPolyline = [];
  
  for (let i = 0; i < shapePoints.length - 1; i++) {
    const d = segmentDistances[i];
    const segStart = currentDistance;
    const segEnd = currentDistance + d;
    
    // Si le segment est entièrement avant le début de la fenêtre, on passe
    if (segEnd < startTarget) {
      currentDistance += d;
      continue;
    }
    
    // Si le segment commence après la fin de la fenêtre, on arrête
    if (segStart > endTarget) break;
    
    // Si ce segment contient le point de début de la fenêtre
    if (segStart < startTarget && segEnd >= startTarget) {
      const fraction = (startTarget - segStart) / d;
      const lat = shapePoints[i][0] + (shapePoints[i + 1][0] - shapePoints[i][0]) * fraction;
      const lng = shapePoints[i][1] + (shapePoints[i + 1][1] - shapePoints[i][1]) * fraction;
      subPolyline.push([lat, lng]);
    } else if (segStart >= startTarget) {
      subPolyline.push(shapePoints[i]);
    }
    
    // Si ce segment contient le point de fin de la fenêtre
    if (segStart < endTarget && segEnd >= endTarget) {
      const fraction = (endTarget - segStart) / d;
      const lat = shapePoints[i][0] + (shapePoints[i + 1][0] - shapePoints[i][0]) * fraction;
      const lng = shapePoints[i][1] + (shapePoints[i + 1][1] - shapePoints[i][1]) * fraction;
      subPolyline.push([lat, lng]);
      break;
    }
    
    currentDistance += d;
  }
  return subPolyline;
}

function AnimatedPolyline({ 
  shapePoints, 
  routeColor = '#000000', // couleur du tracé principal, utilisée pour choisir la couleur d'animation
  drawingDuration = 2000, 
  windowTime = 2100, 
  pauseDuration = 10000 
}) {
  // Choisit la couleur d'animation en fonction de routeColor
  const animatedColor = isColorDark(routeColor) ? 'white' : 'black';

  // fixedWindowFraction définit la longueur fixe de la fenêtre visible
  const fixedWindowFraction = windowTime / drawingDuration;
  // progress évolue de 0 à 1 + fixedWindowFraction (phase de dessin puis d'effacement)
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let delayTimeout;
    let startTime;
    let animationFrame;
    let pauseTimeout;

    function animate(timestamp) {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
      const newProgress = Math.min(elapsed / drawingDuration, 1 + fixedWindowFraction);
      setProgress(newProgress);

      if (newProgress < 1 + fixedWindowFraction) {
        animationFrame = requestAnimationFrame(animate);
      } else {
        // Une fois l'animation terminée, on attend pauseDuration ms avant de redémarrer
        pauseTimeout = setTimeout(() => {
          startTime = null;
          setProgress(0);
          animationFrame = requestAnimationFrame(animate);
        }, pauseDuration);
      }
    }

    // On lance l'animation 3s après le montage du composant
    delayTimeout = setTimeout(() => {
      animationFrame = requestAnimationFrame(animate);
    }, 3000);

    return () => {
      clearTimeout(delayTimeout);
      if (animationFrame) cancelAnimationFrame(animationFrame);
      if (pauseTimeout) clearTimeout(pauseTimeout);
    };
  }, [shapePoints, drawingDuration, pauseDuration, fixedWindowFraction]);

  // Calcul du segment visible selon la progression
  let startProg, endProg;
  if (progress < fixedWindowFraction) {
    // Au début, le segment grandit depuis le point de départ
    startProg = 0;
    endProg = progress;
  } else if (progress <= 1) {
    // Pendant le dessin, la fenêtre a une longueur fixe
    startProg = progress - fixedWindowFraction;
    endProg = progress;
  } else {
    // Phase d'effacement : la portion visible se rétrécit
    startProg = progress - fixedWindowFraction;
    endProg = 1;
  }

  const segment = getSubPolyline(shapePoints, startProg, endProg);

  return (
    <Polyline
      positions={segment}
      pathOptions={{
        color: animatedColor,
        weight: 8,
        opacity: 0.5
      }}
    />
  );
}

export default AnimatedPolyline;
