import React, { useState, useEffect } from 'react';
import { FiClock, FiRefreshCw } from 'react-icons/fi';
import './Update.css'; // On importe le CSS personnalisé

const Update = () => {
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAgencies = async () => {
    try {
      const response = await fetch('update.php');
      if (!response.ok) {
        throw new Error('Erreur lors du chargement des données');
      }
      const data = await response.json();
      setAgencies(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction qui renvoie le premier mot en majuscules du nom de l'agence
  const getWatermarkText = (agencyName) => {
    return agencyName.split(' ')[0].toUpperCase();
  };

  // Fonction pour déterminer la couleur de fond de la card selon l'ancienneté
  const getCardClasses = (agency) => {
    if (agency.date) {
      const updateDate = new Date(agency.date);
      const now = new Date();
      const diffInDays = (now - updateDate) / (1000 * 3600 * 24);
      if (diffInDays < 2) {
        return "bg-success text-white"; // moins de 2 jours → vert
      } else if (diffInDays >= 2 && diffInDays < 14) {
        return "bg-warning text-dark";  // entre 2 et 14 jours → orange
      } else if (diffInDays >= 14) {
        return "bg-danger text-white";   // plus de 14 jours → rouge
      }
    }
    return "bg-secondary text-white"; // aucune mise à jour
  };

  useEffect(() => {
    fetchAgencies();
  }, []);

  return (
    <div className="container mt-5">
    

    {/* Card descriptive */}
<div className="row mb-3">
  <div className="col-12">
    <div className="card card-descriptive rounded-3 border-0 position-relative text-white">
      <div className="card-body position-relative">
        <h5 className="card-title">À propos de cette page</h5>
        <p className="card-text">
          Cette page affiche les dernières mises à jour des horaires et autres données via les différents réseaux de bus du serveur BUS CONNECT.
        </p>
      </div>
    </div>
  </div>
</div>


      {/* Légende des couleurs */}
      <div className="legend">
        <div className="legend-item">
          <div className="legend-color legend-green"></div>
          <div className="legend-text">
            Données fraîches (mise à jour récemment)
          </div>
        </div>
        <div className="legend-item">
          <div className="legend-color legend-orange"></div>
          <div className="legend-text">
            Mise à jour il y a quelques jours (prudence)
          </div>
        </div>
        <div className="legend-item">
          <div className="legend-color legend-red"></div>
          <div className="legend-text">
            Mise à jour il y a + de 14 jours (grande prudence)
          </div>
        </div>
      </div>

      {loading ? (
        <p>Chargement...</p>
      ) : (
        <div>
          {agencies.length > 0 ? agencies.map((agency) => (
            <div className="row mb-3" key={agency.agency_id}>
              <div className="col-12">
                <div className={`card rounded-3 border-0 position-relative ${getCardClasses(agency)}`}>
                  {/* Watermark en fond */}
                  <div className="card-overlay">
                    {getWatermarkText(agency.agency_name)}
                  </div>
                  <div className="card-body position-relative">
                    <h5 className="card-title">{agency.agency_name}</h5>
                    <p className="card-text">
                      <FiClock style={{ marginRight: '5px' }} />
                      Dernière mise à jour : {agency.date_formatted ? agency.date_formatted : 'Aucune mise à jour'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )) : (
            <p>Aucun réseau trouvé.</p>
          )}
        </div>
      )}
     
    </div>
  );
};

export default Update;
