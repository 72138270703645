import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

// Composant pour un item FAQ avec effet accordéon
const FAQItem = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="card my-2">
      <div 
        className="card-header" 
        onClick={() => setOpen(!open)} 
        style={{ cursor: "pointer" }}
      >
        <h5 className="mb-0">{question}</h5>
      </div>
      {open && (
        <div className="card-body">
          <p className="card-text">{answer}</p>
        </div>
      )}
    </div>
  );
};

// Composant principal FAQ regroupant les catégories
const FAQ = () => {
  const faqData = [
    {
      category: "Bus Connect",
      items: [
        {
          question: "Qu'est-ce que Bus Connect ?",
          answer: "C'est un système d'information qui permet aux usagers de prévoir leurs déplacements sur l'ensemble de la France. Actuellement, plus de 20 réseaux de bus sont disponibles et d'autres seront bientôt ajoutés."
        },
        {
          question: "Quel est l'objectif de Bus Connect ?",
          answer: "L'objectif de Bus Connect est de faciliter vos déplacements en vous offrant une solution intuitive et complète pour planifier vos trajets en bus. Nous visons à centraliser l'information de plusieurs réseaux de transport pour rendre la mobilité accessible à tous, tout en innovant constamment pour répondre aux besoins des voyageurs."
        }
      ]
    },
    {
      category: "Horaires",
      items: [
        {
          question: "Comment consulter les horaires ?",
          answer: "Tous les horaires sont disponibles dans l'application Bus Connect. Ils s'actualisent en temps réel. Pour obtenir des renseignements sur les horaires, sélectionne ton réseau puis ta ligne de bus."
        },
        {
          question: "Les horaires sont-ils mis à jour en temps réel ?",
          answer: "Oui, les horaires sont constamment actualisés pour vous fournir les informations les plus précises."
        }
      ]
    },
    {
      category: "Tarifs",
      items: [
        {
          question: "Comment fonctionnent les tarifs ?",
          answer: "Les tarifs varient en fonction du réseau de bus."
        }
      ]
    },
    {
      category: "Géolocalisation",
      items: [
        {
          question: "Comment suivre la géolocalisation des bus ?",
          answer: "La géolocalisation des bus est intégrée à l'application Bus Connect, te permettant de suivre en temps réel l'emplacement des bus pour mieux planifier ton trajet."
        }
      ]
    },
    {
      category: "SAEIV",
      items: [
        {
          question: "Qu'est-ce que le SAEIV ?",
          answer: "Le SAEIV (Système d'Aide à l'Exploitation des Informations Voyageurs) centralise et actualise en temps réel les données de circulation et les horaires des bus, garantissant ainsi des informations fiables."
        }
      ]
    },
    {
      category: "Support",
      items: [
        {
          question: "Comment accéder au support ?",
          answer: "Un chat est disponible 24h/24 en bas à droite de l'écran sous forme d'une bulle. N'hésite pas à l'utiliser pour toute question ou assistance."
        }
      ]
    },
    {
      category: "Problèmes et réclamations",
      items: [
        {
          question: "Pourquoi mon bus n'est-il pas passé ?",
          answer: "Vérifie bien si la mention 'véhicule connecté' est affichée dans l'application. Si c'est indiqué 'véhicule non connecté', il se peut que le bus ne passe pas ou que le conducteur ait oublié d'activer son trajet."
        },
        {
          question: "Comment faire une réclamation ?",
          answer: "La réclamation dépend de votre secteur. Vous pouvez obtenir les renseignements nécessaires dans l'onglet tchat afin de soumettre votre réclamation."
        },
        {
          question: "Objet oublié",
          answer: "En cas d'objet oublié, contactez le support qui vous fournira les coordonnées à joindre pour récupérer votre bien."
        }
      ]
    }
  ];

  return (
    <div className="container my-5">
      <h1 className="mb-4 text-center">Foire aux Questions - Bus Connect</h1>
      {faqData.map((section, index) => (
        <div key={index} className="mb-4">
          <h3 className="mb-3">{section.category}</h3>
          {section.items.map((item, i) => (
            <FAQItem key={i} question={item.question} answer={item.answer} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
