// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css'; 
import bcLogo from './assets/logo_footer.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-version">3.2.0</p>
      <div className="footer-content">
        <img src={bcLogo} className="footer-logo" alt="Logo" />
      </div>
      <ul className="footer-links">
        <li><Link to="/mentions" className="footer-link">Mentions légales</Link></li>
        <li><Link to="/privacy_policy" className="footer-link">Confidentialité</Link></li>
        <li><Link to="/Update" className="footer-link">Données</Link></li>
        <li><Link to="/FAQ" className="footer-link">(Aide) FAQ</Link></li>
        <li><Link to="/AccessibilityPage" className="footer-link">Accessibilité</Link></li>
      </ul>
      <div className="footer-copy">
        <p>© {new Date().getFullYear()} <strong>Bus Connect</strong> - Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
