import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './Accueil.css'; // Import du style
import { TbSquareRoundedArrowRightFilled } from "react-icons/tb";
import { Link } from "react-router-dom";

const Accueil = () => {
  return (
    <>
      <Helmet>
        <title>Bus Connect - Accueil</title>
      </Helmet>

      {/* Hero Section */}
      <section className="hero-section-citymapper">
        <Container fluid className="py-5">
          <Row className="align-items-center justify-content-center">
            {/* Partie texte à gauche */}
            <Col xs={12} md={6} className="text-center text-md-start mb-4 mb-md-0">
              <img
                src="https://bustohm.fr/static/media/logo_footer.fe94c408f0b64d2ec5d314259a468fc5.svg"
                alt="App mockup"
                className="img-fluid phone-mockup"
              />
              <p className="hero-subtitle">
                Découvrez une nouvelle façon de voyager avec notre application, 
                plus simple et plus intelligente pour tous vos déplacements en bus.
              </p>
              <div className="download-buttons">
                <Link
                  to="/reseau"
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  className="download-btn me-2 no-underline"
                >
                  <TbSquareRoundedArrowRightFilled
                    size={48}
                    style={{ marginLeft: '3px', marginRight: '3px' }}
                    className="arrow-loop-animation"
                  />
                  Consulter les réseaux de bus
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Cards Section */}
      <section className="cards-section-citymapper">
        {/* 
          Pour centrer la zone et éviter que ça prenne toute la largeur, 
          tu peux utiliser un Container "classique" et non fluid, 
          ou alors mettre un max-width en CSS. 
        */}
        <Container className="py-5">
          <Row className="mb-4 text-center">
            <Col>
              <h2 className="section-title">
                Pourquoi choisir <span className="highlight-busconnect">Bus Connect</span> ?
              </h2>
              <p className="section-subtitle">
                Quelques raisons qui font la différence
              </p>
            </Col>
          </Row>

          {/* On ajoute d-flex align-items-stretch pour que les cards aient la même hauteur */}
          <Row className="g-4 d-flex align-items-stretch">
            {/* Carte 1 - Horaires en temps réel */}
            <Col xs={12} sm={6} md={3} className="d-flex">
              <Card className="citymapper-card flex-fill text-center">
                <Card.Body>
                  <div className="card-icon mb-3">
                    <i className="bi bi-clock-fill"></i>
                  </div>
                  <Card.Title>Horaires en temps réel</Card.Title>
                  <Card.Text>
                    Les horaires sont actualisés en temps réel, en avance, en retard, à l'heure : vous êtes informé à la seconde.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Carte 2 - Géolocalisation des bus */}
            <Col xs={12} sm={6} md={3} className="d-flex">
              <Card className="citymapper-card flex-fill text-center">
                <Card.Body>
                  <div className="card-icon mb-3">
                    <i className="bi bi-geo-alt-fill"></i>
                  </div>
                  <Card.Title>Géolocalisation des bus</Card.Title>
                  <Card.Text>
                    Les bus sont géolocalisés sur la carte pour un suivi précis.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Carte 3 - Horaires par arrêt de bus */}
            <Col xs={12} sm={6} md={3} className="d-flex">
              <Card className="citymapper-card flex-fill text-center">
                <Card.Body>
                  <div className="card-icon mb-3">
                    <i className="bi bi-calendar3"></i>
                  </div>
                  <Card.Title>Horaires par arrêt</Card.Title>
                  <Card.Text>
                    Obtenez les horaires d'un arrêt de bus spécifique.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Carte 4 - Info trafic */}
            <Col xs={12} sm={6} md={3} className="d-flex">
              <Card className="citymapper-card flex-fill text-center">
                <Card.Body>
                  <div className="card-icon mb-3">
                    <i className="bi bi-exclamation-triangle-fill"></i>
                  </div>
                  <Card.Title>Info trafic</Card.Title>
                  <Card.Text>
                    Soyez informé en temps réel des perturbations.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* News Section */}
      <section className="cards-section-citymapper">
        <Container className="py-5">
          <Row className="mb-4 text-center">
            <Col>
              <h2 className="section-title">News</h2>
              <p className="section-subtitle">Dernières informations</p>
            </Col>
          </Row>

          <Row className="g-4 d-flex align-items-stretch">
            {/* News Card 1 */}
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Link to="/blogs/tohm" className="text-decoration-none flex-fill">
                <Card className="citymapper-card text-center flex-fill">
                  <Card.Img variant="top" src="blogs/1.png" alt="Histoire du réseau TOHM" />
                  <Card.Body>
                    <Card.Title>L’histoire du réseau TOHM : De sa création à aujourd’hui</Card.Title>
                    <Card.Text>
                      Découvrez l’évolution du réseau TOHM, de ses débuts à son développement actuel. 
                      Plongez dans son histoire, ses innovations et son impact sur la mobilité locale.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
    


           {/* News */}
           <Col xs={12} sm={6} md={4} className="d-flex">
              <Link to="/blogs/axo" className="text-decoration-none flex-fill">
                <Card className="citymapper-card text-center flex-fill">
                  <Card.Img variant="top" src="blogs/2.png" alt="Histoire du réseau AXO" />
                  <Card.Body>
                    <Card.Title>L’histoire du réseau AXO : De sa création à aujourd’hui</Card.Title>
                    <Card.Text>
                      Découvrez l’évolution du réseau AXO, de ses débuts à son développement actuel. 
                      Plongez dans son histoire, ses innovations et son impact sur la mobilité locale.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

              {/* News */}
           <Col xs={12} sm={6} md={4} className="d-flex">
              <Link to="/blogs/corolis" className="text-decoration-none flex-fill">
                <Card className="citymapper-card text-center flex-fill">
                  <Card.Img variant="top" src="blogs/3.png" alt="Histoire du réseau COROLIS" />
                  <Card.Body>
                    <Card.Title>L’histoire du réseau COROLIS : De sa création à aujourd’hui</Card.Title>
                    <Card.Text>
                      Découvrez l’évolution du réseau COROLIS, de ses débuts à son développement actuel. 
                      Plongez dans son histoire, ses innovations et son impact sur la mobilité locale.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>

              {/* News */}
           <Col xs={12} sm={6} md={4} className="d-flex">
              <Link to="/blogs/lebus" className="text-decoration-none flex-fill">
                <Card className="citymapper-card text-center flex-fill">
                  <Card.Img variant="top" src="blogs/4.png" alt="Histoire du réseau LEBUS" />
                  <Card.Body>
                    <Card.Title>L’histoire du réseau LEBUS : De sa création à aujourd’hui</Card.Title>
                    <Card.Text>
                      Découvrez l’évolution du réseau LEBUS, de ses débuts à son développement actuel. 
                      Plongez dans son histoire, ses innovations et son impact sur la mobilité locale.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>


              {/* News */}
           <Col xs={12} sm={6} md={4} className="d-flex">
              <Link to="/blogs/tic" className="text-decoration-none flex-fill">
                <Card className="citymapper-card text-center flex-fill">
                  <Card.Img variant="top" src="blogs/5.png" alt="Histoire du réseau TIC" />
                  <Card.Body>
                    <Card.Title>L’histoire du réseau TIC : De sa création à aujourd’hui</Card.Title>
                    <Card.Text>
                      Découvrez l’évolution du réseau TIC, de ses débuts à son développement actuel. 
                      Plongez dans son histoire, ses innovations et son impact sur la mobilité locale.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>


              {/* News */}
           <Col xs={12} sm={6} md={4} className="d-flex">
              <Link to="/blogs/airemob" className="text-decoration-none flex-fill">
                <Card className="citymapper-card text-center flex-fill">
                  <Card.Img variant="top" src="blogs/6.png" alt="Histoire du réseau AIREMOB" />
                  <Card.Body>
                    <Card.Title>L’histoire du réseau AIREMOB : De sa création à aujourd’hui</Card.Title>
                    <Card.Text>
                      Découvrez l’évolution du réseau AIREMOB, de ses débuts à son développement actuel. 
                      Plongez dans son histoire, ses innovations et son impact sur la mobilité locale.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
        
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Accueil;
