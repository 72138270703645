import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const smoothScrollTo = (targetY, duration = 500) => {
  const startY = window.pageYOffset;
  const distance = targetY - startY;
  let startTime = null;

  const step = (currentTime) => {
    if (!startTime) startTime = currentTime;
    const progress = Math.min((currentTime - startTime) / duration, 1);
    window.scrollTo(0, startY + distance * progress);
    if (progress < 1) {
      requestAnimationFrame(step);
    }
  };

  requestAnimationFrame(step);
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Défilement en douceur vers le haut sur 500ms
    smoothScrollTo(0, 500);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
