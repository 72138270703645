import React from "react";

function Tic() {
  return (
    <div className="container my-5">
      {/* --- TITRE PRINCIPAL --- */}
      <div className="text-center mb-5">
        <h1 className="display-4">
          Transports intercommunaux du Compiégnois (TIC)
        </h1>
        <p className="lead">
          Le réseau de transport gratuit de l’agglomération de Compiègne
        </p>
      </div>

      {/* --- SECTION PRESENTATION --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Présentation générale</h2>
          <p>
            Les <strong>Transports intercommunaux du Compiégnois (TIC)</strong> 
            constituent le <em>réseau de transport en commun de l'agglomération 
            de Compiègne</em>. Particularité notable : il est entièrement 
            <strong> gratuit</strong>, à l’instar de plusieurs autres réseaux dans 
            l’Oise. Exploité par <strong>Transdev Picardie Acary</strong>, filiale du 
            groupe Transdev, le réseau a pour dépôt principal le site de Clairoix, 
            au nord de l’agglomération.
          </p>
          <p>
            D’après les estimations récentes (2023), le réseau transporte jusqu’à 
            <strong>6,5 millions de voyageurs</strong> par an. L’autorité 
            organisatrice est l’<strong>Agglomération de la Région de Compiègne 
            et de la Basse Automne</strong>.
          </p>
          <p>
            Dès <strong>1975</strong>, le maire de Compiègne, Jean Legendre, met 
            en place une première ligne de bus gratuite, une décision alors inédite 
            en Europe.  
            Le réseau a connu plusieurs évolutions : 
            <ul>
              <li>
                <strong>19 juin 2017</strong> : création de la ligne spéciale 
                <em>ARC Express</em>, reliant Compiègne à Verberie, à la suite de la 
                fusion de l’agglomération de Compiègne et la Communauté de communes 
                de la Basse Automne.
              </li>
              <li>
                <strong>3 septembre 2018</strong> : la ligne 2 express devient la ligne 
                6, desservant la gare de Compiègne, la zone d’activités du Bois de 
                Plaisance et le quartier des Sablons.
              </li>
              <li>
                <strong>2 novembre 2019</strong> : amélioration de la desserte du quartier 
                Bellicart (ligne 3).
              </li>
              <li>
                <strong>2 septembre 2021</strong> : mise en service du premier autobus 
                articulé du réseau (ligne 5).
              </li>
              <li>
                <strong>Janvier 2023</strong> : arrivée des premiers autobus fonctionnant 
                au gaz naturel, station GNV installée à Clairoix.
              </li>
              <li>
                <strong>Avril 2024</strong> : extension des lignes 4 et 6 vers la zone 
                d’activités du Bois de Plaisance, avec de nouveaux arrêts 
                (« Bois de Plaisance 2 », « Bois de Plaisance 4 », etc.).
              </li>
            </ul>
          </p>
        </div>
      </div>

      {/* --- SECTION RESEAU --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Le réseau actuel</h2>
          <p>
            Le réseau TIC se compose de :
          </p>
          <ul>
            <li><strong>6 lignes urbaines</strong> (lundi - samedi)</li>
            <li><strong>7 lignes périurbaines</strong></li>
            <li><strong>2 lignes dominicales</strong> (D1, D2, hors été)</li>
            <li><strong>2 lignes spéciales</strong> : ARC Express et Navette Hauts de Margny</li>
            <li><strong>7 lignes de transport à la demande « AlloTIC »</strong>, numérotées de 13 à 19</li>
          </ul>
        </div>
      </div>

      {/* --- SECTION LIGNES URBAINES --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Lignes urbaines (1 à 6)</h3>
          <p>
            Ces lignes circulent du lundi au samedi. En voici les principales 
            caractéristiques (trajets, horaires, arrêts, etc.).
          </p>
        </div>
      </div>

      {/* CARDS LIGNES URBAINES */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* LIGNE 1 */}
        <div className="col">
          <div className="card shadow-sm h-100">
            <div className="card-body">
              <h5 className="card-title">Ligne 1 : Compiègne – Gare &lt;&gt; Compiègne – Hôpital</h5>
              <p className="card-text">
                <strong>Longueur :</strong> 10 km  
                <br />
                <strong>Nombre d’arrêts :</strong> 31  
                <br />
                <strong>Durée :</strong> 35-40 min  
                <br />
                <strong>Desserte :</strong> Desservant la gare, le centre-ville, 
                plusieurs collèges (Ferdinand Bac, Jacques Monod, etc.), 
                l’Université de technologie (UTC), le centre hospitalier, etc.  
                <br />
                <strong>Horaires :</strong> du lundi au vendredi (5h25 à 22h10), 
                samedi (5h35 à 22h10), fréquence 25-35 min.  
                <br />
                <em>Matériel :</em> Citaro C2, Lion's City, Citywide LF GNV, Urbanway 12 GNV, Irisbus Citelis 12.
              </p>
            </div>
          </div>
        </div>

        {/* LIGNE 2 */}
        <div className="col">
          <div className="card shadow-sm h-100">
            <div className="card-body">
              <h5 className="card-title">Ligne 2 : Ouïnels / Clairoix – Centre &lt;&gt; Centre Commercial Jaux-Venette (Monnet)</h5>
              <p className="card-text">
                <strong>Longueur :</strong> 10-14 km  
                <br />
                <strong>Nombre d’arrêts :</strong> 39  
                <br />
                <strong>Durée :</strong> 45-50 min  
                <br />
                <strong>Desserte :</strong> Clairoix, Margny-lès-Compiègne, centre-ville de Compiègne, 
                Polyclinique Saint-Côme, zone commerciale Jaux-Venette, etc.  
                <br />
                <strong>Horaires :</strong> L-V hors été (6h à 21h30/22h20), samedi + vacances (6h à 21h30/22h25), 
                fréquence ~30-40 min.  
                <br />
                <em>Particularités :</em> certains terminus à Blaise Pascal, renforts scolaires, etc.
              </p>
            </div>
          </div>
        </div>

        {/* LIGNE 3 */}
        <div className="col">
          <div className="card shadow-sm h-100">
            <div className="card-body">
              <h5 className="card-title">Ligne 3 : Choisy-au-Bac – Marronniers / Bellicart &lt;&gt; Ferdinand de Lesseps / Belin</h5>
              <p className="card-text">
                <strong>Longueur :</strong> 10-15 km  
                <br />
                <strong>Nombre d’arrêts :</strong> 41  
                <br />
                <strong>Durée :</strong> 35-45 min  
                <br />
                <strong>Desserte :</strong> Choisy-au-Bac, zone industrielle nord, 
                gare de Compiègne, centre-ville, AFPA, Espace Jean Legendre, 
                zone de Mercières, centre hospitalier…  
                <br />
                <strong>Horaires :</strong> L-V hors été (5h50-21h20), samedi + vacances (5h50-21h20), 
                fréquence 30-35 min.  
                <br />
                <em>Particularités :</em> terminus partiels à la gare, desserte de Choisy-au-Bac en heures de pointe, 
                Belin desservi à certaines heures.
              </p>
            </div>
          </div>
        </div>

        {/* LIGNE 4 */}
        <div className="col">
          <div className="card shadow-sm h-100">
            <div className="card-body">
              <h5 className="card-title">Ligne 4 : Compiègne – Gare &lt;&gt; Centre Commercial Jaux-Venette / Bois de Plaisance</h5>
              <p className="card-text">
                <strong>Longueur :</strong> 7,30-10,30 km  
                <br />
                <strong>Nombre d’arrêts :</strong> 24  
                <br />
                <strong>Durée :</strong> 25-30 min  
                <br />
                <strong>Desserte :</strong> Gare de Compiègne, Margny-lès-Compiègne, Venette, 
                parc d’activités du Bois de Plaisance, etc.  
                <br />
                <strong>Horaires :</strong> L-V hors été (6h36-21h12/22h54 vendredi), samedi + vacances (6h33-22h54), 
                fréquence 30-60 min selon heures.  
                <br />
                <em>Particularités :</em> dessert le Multiplexe (cinéma Majestic) en soirée les vendredis et samedis.
              </p>
            </div>
          </div>
        </div>

        {/* LIGNE 5 */}
        <div className="col">
          <div className="card shadow-sm h-100">
            <div className="card-body">
              <h5 className="card-title">Ligne 5 : Compiègne – Gare &lt;&gt; Compiègne – Hôpital</h5>
              <p className="card-text">
                <strong>Longueur :</strong> 7-8,70 km  
                <br />
                <strong>Nombre d’arrêts :</strong> 28  
                <br />
                <strong>Durée :</strong> 25-30 min  
                <br />
                <strong>Desserte :</strong> centre-ville de Compiègne, UTC, 
                polyclinique Saint-Côme, parc tertiaire de Lacroix-Saint-Ouen, 
                centre hospitalier…  
                <br />
                <strong>Horaires :</strong> L-V hors été (5h40-22h00, 10 min en HP, 15 min en HC), 
                samedi + vacances (5h40-22h00, 15-20 min)  
                <br />
                <em>Particularités :</em> dessert partiellement certains arrêts 
                (Saint-Corneille, Solférino en creuse…), potentiellement un bus articulé.
              </p>
            </div>
          </div>
        </div>

        {/* LIGNE 6 */}
        <div className="col">
          <div className="card shadow-sm h-100">
            <div className="card-body">
              <h5 className="card-title">Ligne 6 : Compiègne – Gare &lt;&gt; Bois de Plaisance</h5>
              <p className="card-text">
                <strong>Longueur :</strong> env. 12,20 km  
                <br />
                <strong>Nombre d’arrêts :</strong> 23  
                <br />
                <strong>Durée :</strong> 30-35 min  
                <br />
                <strong>Desserte :</strong> centre-ville, polyclinique, UTC, 
                zone commerciale Jaux-Venette, parc d’activités du Bois de Plaisance…  
                <br />
                <strong>Horaires :</strong> L-V hors été (6h33-19h49), samedi + vacances (6h33-19h03), 
                fréquence ~65 min.  
                <br />
                <em>Particularités :</em> en extrémité, prolongement via la ligne 4, terminus 
                « Bois de Plaisance 4 ».
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* --- SECTION LIGNES PERIURBAINES --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Lignes périurbaines (101, 103, 105, 106, 107, 109, 112)</h3>
          <p>
            Ces lignes relient Compiègne à des communes plus éloignées 
            (Saint-Jean-aux-Bois, Saint-Sauveur, Béthisy-Saint-Pierre, Choisy-au-Bac, 
            Armancourt, Clairoix, etc.). Elles circulent en semaine et le samedi, 
            parfois avec des horaires réduits en vacances et certaines courses sur réservation.
          </p>
          <ul>
            <li><strong>101 :</strong> Compiègne &lt;&gt; Saint-Jean-aux-Bois / Vieux-Moulin</li>
            <li><strong>103 :</strong> Compiègne &lt;&gt; Saint-Sauveur</li>
            <li><strong>105 :</strong> Compiègne &lt;&gt; Néry / Verberie / Béthisy-Saint-Pierre</li>
            <li><strong>106 :</strong> Compiègne &lt;&gt; Choisy-au-Bac</li>
            <li><strong>107 :</strong> Compiègne &lt;&gt; Armancourt / Jonquières</li>
            <li><strong>109 :</strong> Compiègne &lt;&gt; Clairoix / Janville / Bienville</li>
            <li><strong>112 :</strong> Compiègne &lt;&gt; Verberie / Saintines</li>
          </ul>
        </div>
      </div>

      {/* --- SECTION LIGNES SPECIALES --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Lignes spéciales</h3>
          <p>
            Deux lignes sont classées « spéciales » : <em>ARC Express</em> et la 
            <em>Navette Hauts de Margny (HM)</em>.
          </p>
          <ul>
            <li>
              <strong>ARC Express</strong> : créée le 19 juin 2017, relie 
              Compiègne (gare) à Lacroix-Saint-Ouen (parc tertiaire) et 
              Verberie (collège d’Aramont), avec des horaires variables en semaine 
              et quelques courses le samedi (souvent sur réservation).
            </li>
            <li>
              <strong>Navette Hauts de Margny (HM)</strong> : mise en place 
              le 18 avril 2016, assure la liaison entre la gare de Compiègne et 
              le pôle de développement des Hauts de Margny. En semaine : 4 à 5 
              allers-retours, le samedi : 1 aller-retour l’après-midi.
            </li>
          </ul>
        </div>
      </div>

      {/* --- SECTION LIGNES DOMINICALES --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Lignes dominicales (D1, D2)</h3>
          <p>
            Deux lignes (D1, D2) fonctionnent <strong>uniquement</strong> les 
            dimanches et jours fériés, hors période estivale et excepté le 1er mai, 
            25 décembre et 1er janvier.
          </p>
          <ul>
            <li>
              <strong>D1</strong> : Compiègne – Gare &lt;&gt; Jaux – Multiplexe  
              (13h05 à 22h20, 4 allers-retours), dessert Bellicart 2 fois par jour 
              uniquement vers Multiplexe.
            </li>
            <li>
              <strong>D2</strong> : Compiègne – Gare &lt;&gt; Jaux – Multiplexe  
              (13h10 à 21h30, 4 allers-retours), dessert Bellicart 2 fois/jour 
              uniquement vers la gare.
            </li>
          </ul>
        </div>
      </div>

      {/* --- SECTION TRANSPORT A LA DEMANDE --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Transport à la demande AlloTIC</h3>
          <p>
            Le service <em>AlloTIC</em> permet de compléter les lignes régulières 
            dans les secteurs ou horaires moins desservis. Il comporte <strong>7 lignes</strong> 
            (n°13 à 19), avec des passages possibles sur réservation téléphonique ou via l’appli.
          </p>
          <ul>
            <li><strong>13 :</strong> Compiègne – Gare &lt;&gt; Saint-Sauveur – Mabonnerie / Saint-Vaast-de-Longmont</li>
            <li><strong>14 :</strong> Compiègne – Gare &lt;&gt; Jonquières – Château</li>
            <li><strong>15 :</strong> Compiègne – Hôpital &lt;&gt; Saint-Jean-aux-Bois – La Brévière</li>
            <li><strong>16 :</strong> Compiègne – Hôpital &lt;&gt; Compiègne – Armistice</li>
            <li><strong>17 :</strong> Compiègne – Hôpital &lt;&gt; Jaux – Varanval</li>
            <li><strong>18 :</strong> Compiègne – Hôpital &lt;&gt; Lachelle – Place</li>
            <li><strong>19 :</strong> Compiègne – Hôpital &lt;&gt; Janville – Île Jean Lenoble</li>
          </ul>
          <p>
            Généralement, ces lignes TAD fonctionnent <strong>du lundi au samedi</strong> 
            (5h30 - 20h environ), avec des intervalles de 60-90 minutes selon la demande.
          </p>
        </div>
      </div>

      {/* --- SECTION AMPLITUDE --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Amplitude horaire globale</h3>
          <p>
            <strong>Lundi - Jeudi :</strong> 5h28 à 22h08  
            <br />
            <strong>Vendredi :</strong> 5h28 à 22h54  
            <br />
            <strong>Samedi :</strong> 5h38 à 22h54  
            <br />
            <strong>Dimanches et jours fériés :</strong> 13h05 à 22h17 (pour D1, D2)
          </p>
        </div>
      </div>

      {/* --- SECTION MATERIEL ROULANT --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Matériel roulant</h2>
          <p>
            La flotte du réseau TIC est principalement composée de :
          </p>
          <ul>
            <li><strong>Mercedes-Benz Citaro C2</strong> (la base du parc)</li>
            <li>Des <em>MAN Lion's City</em></li>
            <li>Des véhicules GNV (Urbanway 12 GNV, Urbanway 18 GNV, Scania Citywide LF GNV)</li>
            <li>Quelques <em>Irisbus Citelis 12</em> (dont un récupéré du réseau Sablons Bus)</li>
            <li>Pour les lignes périurbaines et scolaires : <em>Irisbus Récréo II</em> et <em>Iveco Crossway</em></li>
          </ul>
          <p>
            En <strong>2023</strong>, la transition vers le gaz naturel s’est 
            intensifiée, avec mise en service de nouveaux bus GNV (2 standards 
            + 1 articulé au début 2023). La station GNV de Clairoix permet 
            d’alimenter ces autobus ainsi que certains autocars régionaux.  
            En 2024, deux autres Urbanway 12 GNV ont été réceptionnés, et les 
            plus anciens Citaro C2 sont parfois revendus ou remplacés (un 
            Citelis récupéré pour renfort).
          </p>
        </div>
      </div>

      {/* --- SECTION DIVERS : TARIFICATION, EXPLOITATION --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Exploitation et tarification</h2>
          <p>
            Le réseau est exploité par <strong>Transdev Picardie Acary</strong>, 
            filiale du groupe Transdev.  
            La particularité : <strong>toutes les lignes sont gratuites</strong> 
            pour les usagers (modèle de transport sans billet ni pass 
            obligatoire), financées par l’agglomération (via le Versement Transport 
            et d’autres ressources).
          </p>
          <p>
            Le dépôt principal se trouve à Clairoix, où sont stationnés et 
            entretenus les bus et autocars. L’amplitude horaire s’étend 
            majoritairement du matin (5h28) jusqu’en soirée (22h, voire 22h54 
            les vendredis et samedis pour certaines lignes).
          </p>
        </div>
      </div>

      {/* --- SECTION CONCLUSION --- */}
      <div className="row">
        <div className="col text-center">
          <h2>Conclusion</h2>
          <p>
            Les <strong>Transports intercommunaux du Compiégnois (TIC)</strong> 
            constituent un réseau gratuit, complet et en constante évolution. 
            Avec ses lignes urbaines, périurbaines, dominicales, spéciales, 
            ainsi que le transport à la demande AlloTIC, l’agglomération de 
            Compiègne propose une offre diversifiée pour répondre aux besoins 
            de mobilité de ses habitants. L’investissement progressif dans des 
            véhicules plus récents et plus propres (GNV) témoigne de la volonté 
            de concilier performance, accessibilité et respect de l’environnement.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Tic;
