import React, { useState, /* useEffect */ } from 'react';
import { Row, Col, Placeholder, Image } from 'react-bootstrap';

const AlternatingAd = ({ advertisement, adLoading }) => {
  // Forcer l'affichage de la publicité manuelle
  const [useManualAd] = useState(true);

  // Au montage, on ne modifie pas useManualAd car on veut toujours la pub manuelle
  // useEffect(() => {
  //   setUseManualAd(Math.random() < 0.5);
  // }, []);

  // Désactivation temporaire de Google AdSense : code commenté
  // useEffect(() => {
  //   if (!useManualAd) {
  //     if (!document.getElementById('adsbygoogle-js')) {
  //       const script = document.createElement('script');
  //       script.id = 'adsbygoogle-js';
  //       script.async = true;
  //       script.src =
  //         "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4806202949834621";
  //       script.crossOrigin = "anonymous";
  //       document.body.appendChild(script);
  //       script.onload = () => {
  //         try {
  //           (window.adsbygoogle = window.adsbygoogle || []).push({});
  //         } catch (e) {
  //           console.error(e);
  //         }
  //       };
  //     } else {
  //       try {
  //         (window.adsbygoogle = window.adsbygoogle || []).push({});
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     }
  //   }
  // }, [useManualAd]);

  // Comme Google AdSense est désactivé, on affiche toujours la pub manuelle
  return (
    <Row className="justify-content-center mb-3">
      <Col md="auto" className="d-flex justify-content-center">
        {adLoading ? (
          <Placeholder as="div" animation="glow">
            <Placeholder
              style={{ width: '350px', height: '50px', borderRadius: '12px' }}
            />
          </Placeholder>
        ) : advertisement ? (
          advertisement.href ? (
            <a
              href={advertisement.href}
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <div style={{ position: 'relative' }}>
                <div
                  className="ad-badge"
                  style={{
                    position: 'absolute',
                    top: '-6px',
                    right: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    color: '#fff',
                    padding: '2px 6px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    zIndex: 1,
                  }}
                >
                  Publicité
                </div>
                <Image
                  src={`/publicite/${advertisement.image}`}
                  alt="Publicité"
                  fluid
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    borderRadius: '12px',
                  }}
                />
              </div>
            </a>
          ) : (
            <div style={{ position: 'relative' }}>
              <div
                className="ad-badge"
                style={{
                  position: 'absolute',
                  top: '-6px',
                  right: '8px',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: '#fff',
                  padding: '2px 6px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  zIndex: 1,
                }}
              >
                Publicité
              </div>
              <Image
                src={`/publicite/${advertisement.image}`}
                alt="Publicité"
                fluid
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: '12px',
                }}
              />
            </div>
          )
        ) : null}
      </Col>
    </Row>
  );
};

export default AlternatingAd;



















/*
   Ce composant AlternatingAd est utilisé pour afficher une publicité.
   Il utilise React-Bootstrap pour la mise en page et les composants UI.

   La publicité peut être en cours de chargement, dans ce cas un Placeholder est affiché.
   Si la publicité est chargée et contient un lien (advertisement.href),
   l'image est enveloppée dans une balise <a> pour permettre le clic.
   Sinon, l'image est affichée directement.

   La logique de sélection de la publicité manuelle est gérée par l'état useManualAd,
   qui est forcé à true ici.
   Les effets liés à Google AdSense ont été désactivés en commentant le code associé.

   Les styles appliqués à l'image garantissent qu'elle est affichée de manière fluide,
   avec une largeur de 100%, une hauteur automatique et un object-fit en cover,
   ainsi qu'un borderRadius de 12px pour des coins arrondis.

   Le badge "Publicité" est positionné en absolu dans le container afin d'être superposé à l'image.
   Ce container est désormais défini uniquement via un style inline (position: 'relative')
   afin de ne pas utiliser la classe "ad-container".

   Ce composant est conçu pour être intégré dans une grille,
   d'où l'utilisation des composants Row et Col de React-Bootstrap.

   Vous pouvez personnaliser ce composant selon vos besoins,
   notamment en ajustant les styles ou en réactivant les fonctionnalités commentées.

   Pour plus d'informations sur React-Bootstrap, consultez la documentation officielle :
   https://react-bootstrap.github.io/

   Fin du commentaire explicatif.

   Remarque : Ce code a été modifié pour afficher l'image publicitaire
   avec des coins arrondis et le badge "Publicité", sans le container de type "card"
   ni l'utilisation de la classe "ad-container".

   Assurez-vous que le chemin de l'image (/publicite/) est correct et accessible.
*/
