import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AccessibilityPage = () => {
  return (
    <div className="container my-5">
      <h1 className="mb-4 text-center">Accessibilité</h1>
      
      <p>
        Chez Bus Connect, l'accessibilité est une priorité. Nous nous engageons à rendre notre application accessible à tous, y compris aux personnes en situation de handicap.
      </p>
      
      <h2>Conformité aux normes</h2>
      <p>
        Nous nous efforçons de respecter les normes et recommandations du RGAA (Référentiel Général d'Amélioration de l'Accessibilité) et des WCAG (Web Content Accessibility Guidelines). Ces standards nous aident à garantir que l'application est utilisable et compréhensible par tous les utilisateurs.
      </p>
      
      <h2>Fonctionnalités d'accessibilité</h2>
      <ul>
        <li>Navigation clavier complète pour un accès facile à toutes les fonctionnalités</li>
        <li>Descriptions textuelles alternatives pour les images</li>
        <li>Contraste de couleurs optimisé pour une meilleure lisibilité</li>
        <li>Structure de page conforme aux bonnes pratiques grâce aux balises HTML sémantiques</li>
        <li>Options de personnalisation de la taille du texte</li>
      </ul>
      
      <h2>Utilisation avec des technologies d'assistance</h2>
      <p>
        Notre application a été testée avec des lecteurs d'écran et autres technologies d'assistance afin de garantir que les informations soient accessibles à tous.
      </p>
      
      <h2>Commentaires et améliorations</h2>
      <p>
        Nous sommes à l'écoute de vos retours pour améliorer en continu l'accessibilité de Bus Connect. Si vous rencontrez des difficultés ou avez des suggestions, n'hésitez pas à nous contacter à l'adresse suivante : <a href="mailto:contact@busconnect.fr">contact@busconnect.fr</a>.
      </p>
      
    </div>
  );
};

export default AccessibilityPage;
