import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Form, Button, Alert, Spinner, Card } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Sondage = () => {
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [easyToUse, setEasyToUse] = useState("");
  const [geoUseful, setGeoUseful] = useState("");
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [remainingVotes, setRemainingVotes] = useState(null);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/getNetworks.php")
      .then((res) => {
        if (Array.isArray(res.data)) {
          setNetworks(res.data);
        }
      })
      .catch((error) =>
        console.error("Erreur lors de la récupération des réseaux :", error)
      );

    axios
      .get("/sondage.php?action=check")
      .then((res) => {
        if (res.data.remainingVotes !== undefined) {
          setRemainingVotes(res.data.remainingVotes);
          localStorage.setItem("remainingVotes", res.data.remainingVotes);

          if (res.data.remainingVotes === 0) {
            setMessage("Vous avez déjà participé 3 fois au sondage, vous ne pouvez plus y participer.");
            setIsError(true);
            setShowForm(false);
          } else {
            setShowForm(true);
          }
        }
      })
      .catch((error) =>
        console.error("Erreur lors de la vérification des votes :", error)
      )
      .finally(() => setIsLoading(false));
  }, []);

  // Utilisation d'un useEffect pour remonter en haut dès qu'un message est défini
  useEffect(() => {
    if (message) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [message]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedNetwork || !age || !gender || !easyToUse || !geoUseful || rating === 0) {
      setIsError(true);
      setMessage("Veuillez remplir tous les champs.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "/sondage.php",
        new URLSearchParams({
          selectedNetwork,
          age,
          gender,
          easyToUse,
          geoUseful,
          rating,
          review,
        }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      if (response.data.success) {
        setRemainingVotes(response.data.remainingVotes);
        localStorage.setItem("remainingVotes", response.data.remainingVotes);
        setMessage(response.data.message);
        setIsError(false);
        // On masque le formulaire après un envoi réussi, même s'il reste des votes
        setShowForm(false);
      } else {
        setIsError(true);
        setMessage(response.data.message || "Une erreur est survenue.");
      }
    } catch (error) {
      setIsError(true);
      setMessage("Impossible d'envoyer votre réponse.");
    }

    setLoading(false);
  };

  if (isLoading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status" />
        <p>Chargement...</p>
      </Container>
    );
  }

  // Taille de police commune pour tous les textes
  const commonFontSize = "1.3rem";

  const cardStyle = showForm
    ? {
        width: "100%",
        maxWidth: "500px",
        padding: "20px",
        borderRadius: "15px",
        margin: "20px 20px 0px",
        fontSize: commonFontSize,
      }
    : {
        width: "100%",
        maxWidth: "400px",
        padding: "20px 20px 10px",
        borderRadius: "15px",
        margin: "20px 20px 0px",
        fontSize: commonFontSize,
      };

  const cardClassName = showForm ? "shadow-lg w-100" : "shadow-lg";

  return (
    <Container
      className={showForm ? "d-flex justify-content-center align-items-center min-vh-100" : "d-flex justify-content-center"}
      style={!showForm ? { paddingTop: "50px" } : {}}
    >
      <Helmet>
        <title>Sondage - Bus Connect</title>
      </Helmet>

      <Card className={cardClassName} style={cardStyle}>
        <h2 className="text-center mb-3">Votre avis compte !</h2>
        <p className="text-center text-muted">
          Aidez-nous à améliorer l'application en répondant à ce sondage rapide.
        </p>

        {message && (
          <>
            {/* Réduction de la taille du texte pour les messages */}
            <Alert variant={isError ? "danger" : "success"} className="text-center" style={{ fontSize: "1.1rem" }}>
              {message}
            </Alert>

            {/* Bouton Retour */}
            <div
              className="w-100 text-center mt-3 mb-4"
              style={{
                backgroundColor: "#0a78a4",
                borderRadius: "40px",
                fontWeight: "bold",
                textTransform: "uppercase",
                padding: "15px",
                fontSize: commonFontSize,
                color: "white",
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={() => window.history.back()}
            >
              Retour
            </div>
          </>
        )}

        {/* On affiche le formulaire seulement si showForm est à true */}
        {showForm && (
          <Form onSubmit={handleSubmit} className="mt-3">
            <Form.Group>
              <Form.Label>Choix de votre réseau :</Form.Label>
              <Form.Control as="select" value={selectedNetwork} onChange={(e) => setSelectedNetwork(e.target.value)}>
                <option value="">Sélectionnez...</option>
                {networks.map((network) => (
                  <option key={network.network_id} value={network.network_id}>
                    {network.agency_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Votre âge :</Form.Label>
              <Form.Control type="number" value={age} onChange={(e) => setAge(e.target.value)} required />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Sexe :</Form.Label>
              <Form.Control as="select" value={gender} onChange={(e) => setGender(e.target.value)} required>
                <option value="">Sélectionnez...</option>
                <option value="Homme">Homme</option>
                <option value="Femme">Femme</option>
                <option value="Neutre">Neutre</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Facilité d'utilisation :</Form.Label>
              <div className="d-flex">
                <div className="d-flex align-items-center" style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    id="easyOui"
                    name="easyToUse"
                    value="Oui"
                    checked={easyToUse === "Oui"}
                    onChange={(e) => setEasyToUse(e.target.value)}
                    required
                    style={{ marginRight: "15px", transform: "scale(2.0)" }}
                  />
                  <label htmlFor="easyOui" style={{ fontSize: commonFontSize }} className="mb-0">
                    Oui
                  </label>
                </div>
                <div className="d-flex align-items-center" style={{ marginLeft: "20px" }}>
                  <input
                    type="radio"
                    id="easyNon"
                    name="easyToUse"
                    value="Non"
                    checked={easyToUse === "Non"}
                    onChange={(e) => setEasyToUse(e.target.value)}
                    style={{ marginRight: "15px", transform: "scale(2.0)" }}
                  />
                  <label htmlFor="easyNon" style={{ fontSize: commonFontSize }} className="mb-0">
                    Non
                  </label>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>La géolocalisation est-elle utile ?</Form.Label>
              <div className="d-flex">
                <div className="d-flex align-items-center" style={{ marginRight: "20px" }}>
                  <input
                    type="radio"
                    id="geoOui"
                    name="geoUseful"
                    value="Oui"
                    checked={geoUseful === "Oui"}
                    onChange={(e) => setGeoUseful(e.target.value)}
                    required
                    style={{ marginRight: "15px", transform: "scale(2.0)" }}
                  />
                  <label htmlFor="geoOui" style={{ fontSize: commonFontSize }} className="mb-0">
                    Oui
                  </label>
                </div>
                <div className="d-flex align-items-center" style={{ marginLeft: "20px" }}>
                  <input
                    type="radio"
                    id="geoNon"
                    name="geoUseful"
                    value="Non"
                    checked={geoUseful === "Non"}
                    onChange={(e) => setGeoUseful(e.target.value)}
                    style={{ marginRight: "15px", transform: "scale(2.0)" }}
                  />
                  <label htmlFor="geoNon" style={{ fontSize: commonFontSize }} className="mb-0">
                    Non
                  </label>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Votre note :</Form.Label>
              <div>
                {[1, 2, 3, 4, 5].map((star) => (
                  <FaStar
                    key={star}
                    size={30}
                    color={star <= rating ? "#FFD700" : "#CCCCCC"}
                    onClick={() => setRating(star)}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              </div>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Votre avis :</Form.Label>
              <Form.Control as="textarea" rows={3} value={review} onChange={(e) => setReview(e.target.value)} />
            </Form.Group>

            <Button
              type="submit"
              className="w-100 mt-4"
              disabled={loading}
              style={{
                backgroundColor: "#15c300",
                borderRadius: "40px",
                fontWeight: "bold",
                textTransform: "uppercase",
                padding: "15px",
                fontSize: commonFontSize,
                color: "white",
                border: "none",
              }}
            >
              {loading ? "Envoi en cours..." : "Envoyer"}
            </Button>
          </Form>
        )}
      </Card>
    </Container>
  );
};

export default Sondage;
