import React from "react";

function Axo() {
  return (
    <div className="container my-5">
      {/* TITRE PRINCIPAL */}
      <div className="text-center mb-5">
        <h1 className="display-4">Réseau AXO - Creil</h1>
        <p className="lead">
          Un aperçu complet du service de transport urbain dans l’agglomération de Creil
        </p>
      </div>

      {/* SECTION HISTORIQUE */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Historique</h2>
          <p className="text-center">
            Autrefois desservi par le STAC, le réseau de Creil a progressivement 
            évolué pour se regrouper sous l’identité <strong>AXO</strong> 
            à partir du <em>30 août 2021</em>. Cette marque traduit la volonté 
            de l’agglomération <strong>Creil Sud Oise</strong> d’offrir un 
            service de transport moderne, adapté aux besoins de ses habitants.
          </p>
        </div>
      </div>

      {/* CARDS HISTORIQUES */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* CARD 1 : Origines du STAC */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Des débuts (années 1950-1970)</h5>
              <p className="card-text">
                Les premiers services de transport collectif à Creil remontent aux
                années 1950-1960. Par la suite, un réseau plus structuré est né sous
                le nom de STAC (Service de Transport de l’Agglomération Creilloise),
                desservant Creil, Montataire, Nogent-sur-Oise et Villers-Saint-Paul.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 2 : Modernisation dans les années 2000 */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Modernisation (années 2000)</h5>
              <p className="card-text">
                Les années 2000 marquent l’arrivée de bus accessibles (plancher bas),
                de nouvelles lignes et d’un déploiement progressif de systèmes
                d’information et de billettique. Le **STAC** se modernise et met en
                place des dessertes plus régulières, des services Flexo en soirée, et
                un meilleur cadencement.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 3 : Passage sous RATP Dev */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Nouveau contrat (2019)</h5>
              <p className="card-text">
                Au 1er septembre 2019, la gestion du réseau de Creil et de l’ex-PSO
                (Pierre Sud Oise) est confiée au groupe <strong>RATP Dev</strong>,
                rebaptisé alors <em>RD Creil</em>. Le projet prévoit une amplitude
                horaire plus large, un renforcement des lignes à forte fréquentation et
                l’intégration de nouvelles dessertes, avec des décalages dus à la
                crise sanitaire.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 4 : Naissance d'AXO */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">AXO (2021)</h5>
              <p className="card-text">
                En août 2021, le réseau prend officiellement le nom 
                <strong> AXO</strong>. Les lignes urbaines sont réorganisées pour 
                simplifier les itinéraires et renforcer les fréquences. On voit 
                aussi se développer le service à la demande <em>AXO+</em>, 
                permettant de desservir plus efficacement les zones périurbaines 
                ou certains créneaux horaires spécifiques.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION ORGANISATION */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Organisation actuelle</h2>
          <p className="text-center">
            Aujourd’hui, <strong>AXO</strong> est organisé par la communauté
            d’agglomération <em>Creil Sud Oise</em>. L’exploitation quotidienne
            (maintenance, conduite, information voyageurs) est assurée par la 
            filiale locale de RATP Dev, <strong>RD Creil</strong>.
          </p>
        </div>
      </div>

      {/* CARDS ORGANISATION */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* CARD 1 : Lignes urbaines */}
        <div className="col">
          <div className="card h-100 shadow-sm border-success">
            <div className="card-body">
              <h5 className="card-title">Lignes urbaines</h5>
              <p className="card-text">
                Plusieurs lignes structurantes (A, B, C1, C2, D…) assurent la
                liaison entre Creil, Nogent-sur-Oise, Montataire, Saint-Maximin 
                et d’autres communes clés. Certaines lignes fonctionnent aussi 
                le week-end, avec des fréquences adaptées selon la période.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 2 : Transport à la demande (AXO+) */}
        <div className="col">
          <div className="card h-100 shadow-sm border-info">
            <div className="card-body">
              <h5 className="card-title">AXO+</h5>
              <p className="card-text">
                En complément des lignes régulières, le service 
                <strong> AXO+</strong> couvre les zones rurales ou les heures 
                creuses, sur réservation. Les passagers peuvent réserver en ligne, 
                via l’application ou par téléphone, pour être pris en charge sur 
                un arrêt spécifique et rejoindre une zone d’échange (par exemple 
                la gare de Creil).
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION FLOTTE ET TARIFICATION */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Flotte et tarification</h2>
          <p className="text-center">
            Le réseau AXO bénéficie de bus <em>accessibles</em> (plancher bas,
            annonces sonores, rampes pour fauteuils roulants) et de véhicules 
            plus récents (hybrides, électriques). Le <em>SISMO</em> (Système 
            Intégré des Services à la Mobilité dans l'Oise) a été déployé pour 
            harmoniser la billettique et l’information voyageurs dans le 
            département.
          </p>
        </div>
      </div>

      {/* CARDS FLOTTE / TARIFS */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* CARD 1 : Flotte de véhicules */}
        <div className="col">
          <div className="card h-100 shadow-sm border-warning">
            <div className="card-body">
              <h5 className="card-title">Flotte de véhicules</h5>
              <p className="card-text">
                Le parc se compose de bus standards et articulés (Heuliez, Iveco
                Urbanway, Mercedes Citaro, etc.), dont certains hybrides ou GNV.
                Des minibus assurent la desserte à la demande (AXO+), offrant un
                meilleur accès aux quartiers résidentiels et zones moins densément
                peuplées.
              </p>
            </div>
          </div>
        </div>

        {/* CARD 2 : Billetterie et Pass Pass */}
        <div className="col">
          <div className="card h-100 shadow-sm border-primary">
            <div className="card-body">
              <h5 className="card-title">Tarification</h5>
              <p className="card-text">
                La carte <strong>Pass Pass</strong> (billettique régionale) 
                a remplacé le Pass Oise Mobilité. Les voyageurs peuvent y charger 
                abonnements ou carnets de tickets, la politique tarifaire restant 
                proche de l’ancien système STAC. Des gratuités et réductions 
                spécifiques s’appliquent selon les catégories (étudiants, seniors, etc.).
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION CONCLUSION */}
      <div className="row">
        <div className="col text-center">
          <h2>Conclusion</h2>
          <p>
            Le réseau <strong>AXO</strong> illustre la transformation constante
            des transports urbains dans l’agglomération de Creil. Depuis les 
            débuts du STAC jusqu’à cette nouvelle identité, le service s’est 
            élargi et modernisé pour mieux répondre aux attentes de ses usagers. 
            Avec des lignes plus fréquentes, des horaires élargis et un service 
            à la demande, AXO s’impose comme un réseau <em>complet</em> et 
            <em>adapté</em> aux besoins de chacun.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Axo;
