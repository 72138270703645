// Header.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Container, Offcanvas, Modal, Button } from 'react-bootstrap';
import './App.css';
import logoBlanc from './assets/logocolor.svg';
import { FaHome } from 'react-icons/fa';
import { TbMessage2Exclamation } from 'react-icons/tb';
import { FaDatabase } from 'react-icons/fa';
import { MdChangeCircle } from 'react-icons/md';
import { FaQuestionCircle } from "react-icons/fa";
import { IoMenu } from 'react-icons/io5';
import Cookies from 'js-cookie';
import { MdArrowDropDown } from "react-icons/md";
import { MdArrowDropUp } from "react-icons/md";


const MODAL_VERSION = "3.1.3";

const Header = ({ selectedNetwork, networks, showTutorial, setShowTutorial }) => {
  // Modal d'information
  const [showModal, setShowModal] = React.useState(false);
  React.useEffect(() => {
    const modalCookie = Cookies.get('modalVersion');
    if (modalCookie !== MODAL_VERSION) {
      setShowModal(true);
    }
  }, []);
  const handleCloseModal = () => {
    Cookies.set('modalVersion', MODAL_VERSION, { expires: 365 });
    setShowModal(false);
  };

  // Offcanvas et Navbar
  const [show, setShow] = React.useState(false);
  const location = useLocation();
  const changeNetworkRef = React.useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isFixed = location.pathname !== '/schedule';
  const isAccueilActive = location.pathname === '/' || location.pathname.startsWith('/network');

  // Récupération du nom du réseau sélectionné
  const selectedNetworkData = networks.find(net => net.network_id === selectedNetwork);
  const networkName = selectedNetworkData ? selectedNetworkData.agency_name : "";
  let mainPart = "";
  if (networkName) {
    const parts = networkName.split(" - ");
    mainPart = parts[0] ? parts[0].toUpperCase() : "";
  }

  // Tutoriel overlay
  const handleTutorialClose = () => {
    setShowTutorial(false);
    Cookies.set('tutorialShown', 'true', { expires: 365 });
  };
  const [iconPos, setIconPos] = React.useState({ x: 0, y: 0 });
  const [iconSize, setIconSize] = React.useState({ width: 0, height: 0 });
  React.useEffect(() => {
    if (changeNetworkRef.current) {
      const rect = changeNetworkRef.current.getBoundingClientRect();
      setIconPos({ x: rect.left + window.scrollX, y: rect.top + window.scrollY });
      setIconSize({ width: rect.width, height: rect.height });
    }
  }, [showTutorial]);

  // Gestion du sous-menu pour "Blogs"
  const [openSubmenu, setOpenSubmenu] = React.useState(false);
  const toggleSubmenu = () => {
    setOpenSubmenu(!openSubmenu);
  };

  return (
    <>
      {/* Modal d'information */}
      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '0.80rem' }}>
          <p>
            <strong>Nouvelle fonctionnalité sur Bus Connect !</strong>
            <br />
            Vous pouvez désormais indiquer l'affluence du véhicule directement depuis l'application.
            <br />
            Cela permet à chacun d'anticiper ses trajets et d'améliorer l'expérience de tous.
            <br />
            Nous comptons sur vous pour fournir des informations fiables afin que ce service reste utile et bénéfique pour l’ensemble des usagers.
          </p>
          <i style={{ fontSize: '0.50rem' }}>{MODAL_VERSION}</i>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>OK</Button>
        </Modal.Footer>
      </Modal>

      {/* Navbar (inchangée) */}
      <Navbar
        className={`navbar-custom ${isFixed ? 'fixed-top' : ''}`}
        expand="lg"
        style={{
          background: 'linear-gradient(135deg, #00b5ff 0%, #00bfff 50%, #00deff 100%)',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
      >
        <Container style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
          {mainPart && (
            <div style={{ color: 'black', marginLeft: '20px', fontSize: '12px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <div style={{ fontSize: '10px' }}>Réseau :</div>
              <div style={{ fontWeight: 'bold' }}>{mainPart}</div>
            </div>
          )}
          {mainPart && (
            <Link to="/reseau" style={{ marginLeft: '10px', color: '#000' }} title="Changer de réseau" ref={changeNetworkRef}>
              <MdChangeCircle
                size={30}
                style={{ cursor: 'pointer', transition: '0.3s', marginTop: '-2px' }}
                onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              />
            </Link>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <IoMenu
              size={54}
              onClick={handleShow}
              style={{ padding: '5px 10px', cursor: 'pointer', color: '#000' }}
            />
          </div>
        </Container>
      </Navbar>

      {/* Nouveau Offcanvas Menu moderne */}
      <Offcanvas show={show} onHide={handleClose} placement="end" className="modern-offcanvas">
        <Offcanvas.Header className="modern-offcanvas-header">
          <img src={logoBlanc} alt="Logo" className="modern-logo" />
          <button onClick={handleClose} className="modern-close-btn" aria-label="Close">
            &times;
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="modern-offcanvas-body">
          <nav className="modern-menu">
            <Link 
              to="/" 
              className={`modern-menu-item ${isAccueilActive ? 'active' : ''}`} 
              onClick={handleClose}
            >
              <FaHome size={20} className="modern-menu-icon" />
              <span className="modern-menu-text">Accueil</span>
            </Link>
            <Link
              to="/infotrafic"
              className={`modern-menu-item ${location.pathname === '/infotrafic' ? 'active' : ''}`}
              onClick={handleClose}
            >
              <TbMessage2Exclamation size={20} className="modern-menu-icon" />
              <span className="modern-menu-text">Infos Trafic</span>
            </Link>
            <Link
              to="/Update"
              className={`modern-menu-item ${location.pathname === '/Update' ? 'active' : ''}`}
              onClick={handleClose}
            >
              <FaDatabase size={20} className="modern-menu-icon" />
              <span className="modern-menu-text">Données</span>
            </Link>
            <Link
              to="/FAQ"
              className={`modern-menu-item ${location.pathname === '/FAQ' ? 'active' : ''}`}
              onClick={handleClose}
            >
              <FaQuestionCircle  size={20} className="modern-menu-icon" />
              <span className="modern-menu-text">(Aide) FAQ</span>
            </Link>
            <Link
              to="/reseau"
              className={`modern-menu-item ${location.pathname === '/reseau' ? 'active' : ''}`}
              onClick={handleClose}
            >
              <MdChangeCircle size={20} className="modern-menu-icon" />
              <span className="modern-menu-text">Changer de réseau</span>
            </Link>
            <div className="modern-menu-item modern-submenu-toggle" onClick={toggleSubmenu}>
  <span className="modern-menu-text">Blogs</span>
  {openSubmenu ? <MdArrowDropUp size={20} /> : <MdArrowDropDown size={20} />}
</div>
{openSubmenu && (
  <div className="modern-submenu">
    <Link to="/blogs/tohm" className="modern-submenu-link" onClick={handleClose}>
    Histoire du réseau TOHM
    </Link>
    <Link to="/blogs/axo" className="modern-submenu-link" onClick={handleClose}>
    Histoire du réseau AXO
    </Link>
    <Link to="/blogs/corolis" className="modern-submenu-link" onClick={handleClose}>
    Histoire du réseau COROLIS
    </Link>
    <Link to="/blogs/airemob" className="modern-submenu-link" onClick={handleClose}>
    Histoire du réseau Airemob
    </Link>
    <Link to="/blogs/tic" className="modern-submenu-link" onClick={handleClose}>
    Histoire du réseau TIC
    </Link>
    <Link to="/blogs/lebus" className="modern-submenu-link" onClick={handleClose}>
    Histoire du réseau LE BUS
    </Link>
  </div>
)}

          </nav>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Overlay du tutoriel */}
      {showTutorial && (
        <div
          className="tutorial-overlay"
          style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.7)', zIndex: 9999 }}
          onClick={handleTutorialClose}
        >
          <div
            style={{
              position: 'absolute',
              top: iconPos.y + iconSize.height + 10 + 'px',
              left: iconPos.x + iconSize.width / 2 - 150 + 'px',
              width: '300px',
              background: 'white',
              color: 'black',
              padding: '10px',
              borderRadius: '5px',
              textAlign: 'center'
            }}
          >
            <p style={{ margin: '0', fontWeight: 'bold' }}>Changer de réseau</p>
            <p style={{ fontSize: '10px', margin: '5px 0' }}>
              Cliquez ici pour changer de réseau de bus
            </p>
            <div
              onClick={handleTutorialClose}
              style={{
                background: '#0a78a4',
                color: '#fff',
                padding: '5px 10px',
                borderRadius: '5px',
                cursor: 'pointer',
                fontWeight: 'bold',
                marginTop: '10px',
                display: 'inline-block'
              }}
            >
              OK
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: iconPos.y + iconSize.height + 5 + 'px',
              left: iconPos.x + iconSize.width / 2 - 5 + 'px',
              width: '10px',
              height: '10px',
              transform: 'rotate(45deg)',
              background: 'white'
            }}
          />
        </div>
      )}

      <style jsx>{`
        /* Navbar styles inchangés */
        .navbar-custom.fixed-top {
          position: fixed;
          width: 100%;
          top: 0;
          z-index: 1030;
        }
        body {
          padding-top: ${isFixed ? '70px' : '0'};
        }

        /* Styles modernes pour le Offcanvas */
        .modern-offcanvas {
          width: 300px;
          background: #fff;
          box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
          border-left: 1px solid #eee;
        }
       .modern-offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px; /* 10px vertical, 20px horizontal */
  border-bottom: 1px solid #eee;
  background: #f7f7f7;
}

        .modern-logo {
          max-height: 100px;
        }
        .modern-close-btn {
          background: transparent;
          border: none;
          font-size: 2.5rem;
          cursor: pointer;
          color: #333;
        }
        .modern-offcanvas-body {
          padding: 20px;
        }
        .modern-menu {
          display: flex;
          flex-direction: column;
        }
        .modern-menu-item {
          display: flex;
          align-items: center;
          padding: 12px 15px;
          margin-bottom: 10px;
          border-radius: 8px;
          transition: background 0.3s;
          color: #333;
          text-decoration: none;
          font-weight: 500;
          background: #fafafa;
        }
        .modern-menu-item:hover {
          background: #f0f0f0;
        }
        .modern-menu-item.active {
          background: #e0e0e0;
        }
        .modern-menu-icon {
          margin-right: 10px;
        }
        .modern-menu-text {
          flex: 1;
        }
        .modern-submenu-toggle {
          cursor: pointer;
          justify-content: space-between;
        }
        .modern-submenu {
          margin-left: 20px;
          margin-top: 5px;
          display: flex;
          flex-direction: column;
        }
        .modern-submenu-link {
          padding: 10px 15px;
          border-radius: 6px;
          text-decoration: none;
          color: #555;
          background: #f9f9f9;
          margin-bottom: 5px;
          transition: background 0.3s;
        }
        .modern-submenu-link:hover {
          background: #f0f0f0;
        }
      `}</style>
    </>
  );
};

export default Header;
