import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";  // <-- Ajout de Helmet
import { Spinner, Container, Alert, Card, Accordion, Badge } from 'react-bootstrap';
import { TbMessage2Exclamation } from "react-icons/tb";
import { BsClockHistory } from 'react-icons/bs';
import { FaCircleCheck } from "react-icons/fa6";

const Infotrafic = () => {
  const [disruptions, setDisruptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [networksList, setNetworksList] = useState([]);

  // Appel direct à l'API d'infotrafic
  useEffect(() => {
    axios.get('https://api.oisemob.cityway.fr/disrupt/api/v1/fr/disruptions')
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          setDisruptions(response.data.data);
        } else {
          console.error('Structure inattendue de la réponse API', response.data);
          setError(true);
        }
      })
      .catch(err => {
        console.error('Erreur lors de la récupération des infos trafic:', err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const favs = getFavoritesFromCookie();
    // Tri des favoris par route_short_name de façon ascendante (numérique si possible)
    favs.sort((a,b) => {
      const numA = parseInt(a.route_short_name,10);
      const numB = parseInt(b.route_short_name,10);
      if(!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      }
      return a.route_short_name.localeCompare(b.route_short_name, 'fr', {numeric:true});
    });
    setFavorites(favs);
  }, []);

  useEffect(() => {
    axios.get('/getNetworks.php')
      .then(response => {
        if (Array.isArray(response.data)) {
          setNetworksList(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch(error => console.error('Error fetching networks:', error));
  }, []);

  const getFavoritesFromCookie = () => {
    const name = 'favorites=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    let favorites = [];
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        favorites = JSON.parse(c.substring(name.length, c.length));
        break;
      }
    }
    return Array.isArray(favorites) ? favorites : [];
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
        <Spinner animation="border" role="status" style={{ color: '#0a78a4' }}>
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Container className="mt-4">
        <Alert variant="danger">
          Une erreur est survenue lors de la récupération des informations de trafic.
        </Alert>
      </Container>
    );
  }

  if (!disruptions || disruptions.length === 0) {
    return (
      <Container className="mt-4">
        <Alert variant="warning">
          Aucune information de trafic disponible pour le moment.
        </Alert>
      </Container>
    );
  }

  const getTextColor = (bgColor) => {
    if (!bgColor || !bgColor.startsWith('#') || (bgColor.length !== 7 && bgColor.length !== 4)) {
      return '#000'; 
    }
    let color = bgColor.replace('#','');
    if (color.length===3) {
      color = color[0]+color[0]+color[1]+color[1]+color[2]+color[2];
    }

    const r = parseInt(color.substring(0,2),16);
    const g = parseInt(color.substring(2,4),16);
    const b = parseInt(color.substring(4,6),16);

    const brightness = (0.299*r + 0.587*g + 0.114*b);
    return (brightness > 128) ? '#000' : '#fff';
  };

  const disruptionsByNetwork = {};

  disruptions.forEach(disruption => {
    const { affectedLines } = disruption;
    if (affectedLines && affectedLines.length > 0) {
      affectedLines.forEach(line => {
        const { networkName } = line;
        if (!disruptionsByNetwork[networkName]) {
          disruptionsByNetwork[networkName] = [];
        }
        if (!disruptionsByNetwork[networkName].some(d => d.internalId === disruption.internalId)) {
          disruptionsByNetwork[networkName].push(disruption);
        }
      });
    } else {
      const otherKey = "Autre Réseau";
      if (!disruptionsByNetwork[otherKey]) {
        disruptionsByNetwork[otherKey] = [];
      }
      disruptionsByNetwork[otherKey].push(disruption);
    }
  });

  const networkKeys = Object.keys(disruptionsByNetwork).sort();

  const getAgencyShortName = (network_id) => {
    const net = networksList.find(n => n.network_id === network_id);
    if (net && net.agency_name) {
      return net.agency_name.split(' - ')[0];
    }
    return 'Réseau inconnu'; 
  };

  const normalizeString = (str) => {
    if (!str) return '';
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const getDisruptionsForFavoriteLine = (favLine) => {
    const networkIdInt = parseInt(favLine.network_id, 10);
    // Correspondance stricte
    let found = disruptions.filter(disruption =>
      disruption.affectedLines && disruption.affectedLines.some(al =>
        al.number === favLine.route_short_name && al.networkId === networkIdInt
      )
    );
    if (found.length > 0) return found;

    // Correspondance sur le number uniquement
    found = disruptions.filter(disruption =>
      disruption.affectedLines && disruption.affectedLines.some(al =>
        al.number === favLine.route_short_name
      )
    );
    if (found.length > 0) return found;

    // Correspondance approximative sur le nom
    found = disruptions.filter(disruption =>
      disruption.affectedLines && disruption.affectedLines.some(al =>
        normalizeString(al.name).includes(normalizeString(favLine.route_long_name))
      )
    );
    return found;
  };

  const getLineNameFromApiForFavorite = (favLine, lineDisruptions) => {
    if (lineDisruptions.length === 0) {
      return favLine.route_long_name; 
    }
    for (const disruption of lineDisruptions) {
      if (disruption.affectedLines) {
        const matchedLine = disruption.affectedLines.find(al =>
          (al.number === favLine.route_short_name && al.networkId === parseInt(favLine.network_id,10))
          || al.number === favLine.route_short_name
          || normalizeString(al.name).includes(normalizeString(favLine.route_long_name))
        );
        if (matchedLine) {
          return matchedLine.name;
        }
      }
    }
    return favLine.route_long_name;
  };

  return (
    <>
      {/* Helmet pour le titre */}
      <Helmet>
        <title>Bus Connect - Infos Trafic</title>
      </Helmet>
      
      <Container className="mt-4" style={{ maxWidth: '1000px' }}>
        {/* Header stylé */}
        <div style={{
          display:'flex',
          alignItems:'center',
          marginBottom:'30px',
          padding:'20px',
          borderRadius:'10px',
          background: 'linear-gradient(135deg, #c0392b 0%, #e74c3c 100%)',
          color:'#fff',
          boxShadow:'0 4px 8px rgba(0,0,0,0.1)'
        }}>
          <TbMessage2Exclamation size={50} style={{ marginRight:'15px' }}/>
          <h2 style={{ margin:0, fontWeight:'bold' }}>Infos Trafic</h2>
        </div>

        {favorites && favorites.length > 0 && (
          <div style={{ marginBottom: '30px' }}>
            <h4 style={{ fontWeight:'bold', marginBottom:'15px' }}>Vos lignes de bus favoris :</h4>
            <div style={{
              display:'flex',
              flexDirection:'column',
              gap:'20px',
              padding:'10px',
              background:'#fafafa',
              border:'1px solid #ccc',
              borderRadius:'10px',
              boxShadow:'0 4px 6px rgba(0,0,0,0.1)'
            }}>
              {favorites.map((favLine, idx) => {
                const bgColor = `#${favLine.route_color}`;
                const textColor = getTextColor(bgColor);
                const lineDisruptions = getDisruptionsForFavoriteLine(favLine);
                const lineNameFromApi = getLineNameFromApiForFavorite(favLine, lineDisruptions);
                const networkName = getAgencyShortName(favLine.network_id);

                return (
                  <Card key={idx} style={{ borderRadius:'10px', overflow:'hidden', border:'1px solid #ddd' }}>
                    <div style={{
                      background: `linear-gradient(135deg, ${bgColor} 0%, ${lightenColor(bgColor, 40)} 100%)`,
                      color: textColor,
                      padding:'15px',
                      fontWeight:'bold',
                      display:'flex',
                      alignItems:'center'
                    }}>
                      <span>
                        {favLine.route_short_name} ({networkName}) -{' '}
                        <span dangerouslySetInnerHTML={{ __html: lineNameFromApi }} />
                      </span>
                    </div>
                    <Card.Body style={{ backgroundColor:'#fff' }}>
                      {lineDisruptions.length > 0 ? (
                        <Accordion flush alwaysOpen={false}>
                          {lineDisruptions.map((disruption, i) => {
                            const { title, description, type, effectiveStartDate, effectiveEndDate } = disruption;
                            const startDate = new Date(effectiveStartDate).toLocaleString('fr-FR', { 
                              year: 'numeric', 
                              month: 'long', 
                              day: 'numeric', 
                              hour: '2-digit', 
                              minute:'2-digit' 
                            });
                            const endDate = new Date(effectiveEndDate).toLocaleString('fr-FR', { 
                              year: 'numeric', 
                              month: 'long', 
                              day: 'numeric', 
                              hour: '2-digit', 
                              minute:'2-digit' 
                            });

                            return (
                              <Accordion.Item eventKey={String(i)} key={i}>
                                <Accordion.Header>
                                  <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                    <Badge bg="warning" text="dark" style={{ marginBottom:'5px' }}>{type}</Badge>
                                    <span dangerouslySetInnerHTML={{ __html: title }} style={{ fontWeight:'600' }}></span>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div 
                                    style={{ marginBottom:'10px' }}
                                    dangerouslySetInnerHTML={{ __html: description }}
                                  ></div>
                                  <div>
                                    <BsClockHistory style={{ marginRight: '5px' }} />
                                    <strong>Du :</strong> {startDate}<br />
                                    <strong>Au :</strong> {endDate}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      ) : (
                        <div style={{ 
                          backgroundColor: '#fff', 
                          color: '#000', 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'space-between', 
                          borderRadius: '5px', 
                          padding: '10px', 
                          border: '1px solid #2ecc71' 
                        }}>
                          <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                            Aucune perturbation sur cette ligne
                          </span>
                          <FaCircleCheck size={24} color="#2ecc71" />
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </div>
        )}

        {networkKeys.map(networkName => {
          const items = disruptionsByNetwork[networkName];
          return (
            <Card key={networkName} className="mb-4" style={{ 
              borderRadius: '10px', 
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
            }}>
              <Card.Header
                style={{
                  background: "linear-gradient(135deg, #0A78A4 0%, #66A7C5 100%)",
                  color: "#fff",
                  borderRadius: "10px 10px 0 0",
                  padding: '15px',
                  fontWeight: 'bold',
                  fontSize: '1.2rem'
                }}
              >
                {networkName}
              </Card.Header>
              <Card.Body style={{ backgroundColor: '#f9f9f9' }}>
                <Accordion flush>
                  {items.map((disruption, index) => {
                    const {
                      title,
                      description,
                      type,
                      effectiveStartDate,
                      effectiveEndDate,
                      affectedLines
                    } = disruption;

                    const startDate = new Date(effectiveStartDate).toLocaleString('fr-FR', { 
                      year: 'numeric', 
                      month: 'long', 
                      day: 'numeric', 
                      hour: '2-digit', 
                      minute:'2-digit' 
                    });
                    const endDate = new Date(effectiveEndDate).toLocaleString('fr-FR', { 
                      year: 'numeric', 
                      month: 'long', 
                      day: 'numeric', 
                      hour: '2-digit', 
                      minute:'2-digit' 
                    });

                    const linesForThisNetwork = affectedLines.filter(line => line.networkName === networkName);

                    return (
                      <Accordion.Item eventKey={String(index)} key={index}>
                        <Accordion.Header>
                          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                            <Badge bg="warning" text="dark" style={{ marginBottom:'5px' }}>{type}</Badge>
                            <span style={{ fontWeight: '600' }} dangerouslySetInnerHTML={{ __html: title }} />
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div style={{ marginBottom: '10px' }} dangerouslySetInnerHTML={{ __html: description }} />
                          <div style={{ marginBottom: '10px' }}>
                            <BsClockHistory style={{ marginRight: '5px' }} />
                            <strong>Du :</strong> {startDate}<br />
                            <strong>Au :</strong> {endDate}
                          </div>
                          {linesForThisNetwork.length > 0 && (
                            <div style={{ marginBottom: '10px' }}>
                              <strong>Lignes affectées :</strong>
                              <ul style={{ listStyleType: 'none', paddingLeft: '0', marginTop: '10px' }}>
                                {linesForThisNetwork.map((line, idx) => {
                                  const bgColor = line.color || '#ccc';
                                  const textColor = getTextColor(bgColor);

                                  return (
                                    <li key={idx} style={{ marginBottom:'5px' }}>
                                      <span 
                                        style={{ 
                                          backgroundColor: bgColor,
                                          borderRadius: '20px',
                                          padding: '2px 8px', 
                                          color: textColor,
                                          fontWeight: '600'
                                        }}
                                        dangerouslySetInnerHTML={{ __html: line.number }}
                                      />
                                      {line.name && (
                                        <> <span dangerouslySetInnerHTML={{ __html: line.name }} /></>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Card.Body>
            </Card>
          );
        })}
      </Container>
    </>
  );
};

function lightenColor(hex, percent) {
  let c = hex.replace('#','');
  if (c.length===3) {
    c = c[0]+c[0]+c[1]+c[1]+c[2]+c[2];
  }
  const r = parseInt(c.substring(0,2),16);
  const g = parseInt(c.substring(2,4),16);
  const b = parseInt(c.substring(4,6),16);

  const nr = Math.min(255, Math.floor(r + (255 - r)*(percent/100)));
  const ng = Math.min(255, Math.floor(g + (255 - g)*(percent/100)));
  const nb = Math.min(255, Math.floor(b + (255 - b)*(percent/100)));

  return '#' + nr.toString(16).padStart(2,'0') + ng.toString(16).padStart(2,'0') + nb.toString(16).padStart(2,'0');
}

export default Infotrafic;
