import React, { useState, useEffect, useMemo } from 'react';
import {
  MapContainer,
  TileLayer,
  Popup,
  GeoJSON,
  useMap,
  CircleMarker,
  Pane,
  Marker,
  Tooltip
} from 'react-leaflet';
import axios from 'axios';
import protobuf from 'protobufjs';
import { useParams, useNavigate } from 'react-router-dom';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { FaTimes, FaBus, FaLocationArrow } from 'react-icons/fa';
import { IoMdSubway } from 'react-icons/io';
import { MdTram } from 'react-icons/md';
import { IoIosBoat } from 'react-icons/io';
import { renderToStaticMarkup } from 'react-dom/server';
import useSupercluster from 'use-supercluster';
import { BiSolidRightArrow } from "react-icons/bi";

/* ------------------------------------------------------------------
   Fonction pour choisir l'URL des tuiles Mapbox via ton proxy PHP
------------------------------------------------------------------ */
const getMapTileUrl = () => {
  const hour = new Date().getHours();
  if (hour >= 7 && hour < 19) {
    return "/get_tile.php?tile_url=https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2VpYmVsY2xlbWVudDYwIiwiYSI6ImNtMm9yZ3JpaDA4OGQybHIxcTBibHk4NXQifQ.iUZ4I9uI1lIWgamjWnDIYg";
  } else {
    return "/get_tile.php?tile_url=https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2VpYmVsY2xlbWVudDYwIiwiYSI6ImNtMm9yZ3JpaDA4OGQybHIxcTBibHk4NXQifQ.iUZ4I9uI1lIWgamjWnDIYg";
  }
};

/* ------------------------------------------------------------------
   Utilitaires de couleur
------------------------------------------------------------------ */
function isColorLight(hex) {
  const c = hex.replace('#', '');
  const rgb = parseInt(c, 16);
  const r = (rgb >> 16) & 255;
  const g = (rgb >> 8) & 255;
  const b = rgb & 255;
  return (0.2126 * r + 0.7152 * g + 0.0722 * b) > 128;
}

function darkenColor(hex, amount = 0.1) {
  let c = hex.replace('#', '');
  if (c.length === 3) {
    c = c.split('').map(x => x + x).join('');
  }
  let r = parseInt(c.substring(0, 2), 16);
  let g = parseInt(c.substring(2, 4), 16);
  let b = parseInt(c.substring(4, 6), 16);

  r = Math.floor(r * (1 - amount));
  g = Math.floor(g * (1 - amount));
  b = Math.floor(b * (1 - amount));

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  const rr = r.toString(16).padStart(2, '0');
  const gg = g.toString(16).padStart(2, '0');
  const bb = b.toString(16).padStart(2, '0');
  return `#${rr}${gg}${bb}`;
}

/* ------------------------------------------------------------------
   Création du marker divIcon pour les véhicules
------------------------------------------------------------------ */
function createVehicleIcon(color, bearing = 0, vehicleType = 1) {
  const markerColor = darkenColor(color, 0.1);
  const iconColor = isColorLight(markerColor) ? '#000' : '#fff';

  let icon;
  switch (vehicleType) {
    case 2:
      icon = <IoMdSubway style={{ color: iconColor, fontSize: '20px' }} />;
      break;
    case 3:
      icon = <MdTram style={{ color: iconColor, fontSize: '20px' }} />;
      break;
    case 4:
      icon = <IoIosBoat style={{ color: iconColor, fontSize: '20px' }} />;
      break;
    case 1:
    default:
      icon = <FaBus style={{ color: iconColor, fontSize: '20px' }} />;
      break;
  }

  const html = renderToStaticMarkup(
    <div
      style={{
        position: 'relative',
        width: 35,
        height: 35,
        transform: `rotate(${bearing}deg)`
      }}
    >
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          borderBottom: `30px solid ${markerColor}`,
          position: 'absolute',
          top: '-15px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1
        }}
      />
      <div
        style={{
          width: 0,
          height: 0,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderBottom: `20px solid ${iconColor}`,
          position: 'absolute',
          top: '-15px',
          left: '50%',
          transform: 'translateX(-50%) translateY(5px)',
          zIndex: 1
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '35px',
          height: '35px',
          borderRadius: '50%',
          backgroundColor: markerColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2
        }}
      >
        {icon}
      </div>
    </div>
  );

  return L.divIcon({
    className: '',
    html,
    iconSize: [35, 35]
  });
}

/* ------------------------------------------------------------------
   Création d'un icône pour les clusters d'arrêts avec bulles de taille variable
------------------------------------------------------------------ */
function createClusterIcon(count) {
  const size = Math.max(30, Math.min(60, 30 + Math.log(count) * 10));
  const fontSize = Math.floor(size / 2.5);
  const html = `<div style="display:flex;align-items:center;justify-content:center;background:rgba(0,0,0,0.6);color:white;width:${size}px;height:${size}px;border-radius:50%;font-size:${fontSize}px;">${count}</div>`;
  return L.divIcon({
    html,
    className: '',
    iconSize: [size, size]
  });
}

/* ------------------------------------------------------------------
   Vérification d'un GeoJSON "FeatureCollection" standard
------------------------------------------------------------------ */
function isValidGeoJSON(geo) {
  return geo && geo.type === 'FeatureCollection' && Array.isArray(geo.features);
}

/* ------------------------------------------------------------------
   Composant pour recentrer automatiquement la carte
------------------------------------------------------------------ */
function AutoCenter({ selectedLines, shapesMap, stopsGeoJSON }) {
  const map = useMap();
  useEffect(() => {
    let bounds = null;
    if (selectedLines.length > 0) {
      const selectedShapes = selectedLines
        .map(line => shapesMap[line.route_id])
        .filter(Boolean);
      if (selectedShapes.length > 0) {
        bounds = L.latLngBounds([]);
        selectedShapes.forEach(shapeGeo => {
          if (shapeGeo.features && shapeGeo.features.length > 0) {
            shapeGeo.features.forEach(feature => {
              if (feature.geometry.type === 'LineString') {
                feature.geometry.coordinates.forEach(coord => {
                  bounds.extend(L.latLng(coord[1], coord[0]));
                });
              } else if (feature.geometry.type === 'MultiLineString') {
                feature.geometry.coordinates.forEach(lineCoords => {
                  lineCoords.forEach(coord => {
                    bounds.extend(L.latLng(coord[1], coord[0]));
                  });
                });
              }
            });
          }
        });
      }
    } else if (stopsGeoJSON && stopsGeoJSON.features) {
      bounds = L.latLngBounds([]);
      stopsGeoJSON.features.forEach(feature => {
        bounds.extend(L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
      });
    }
    if (bounds && bounds.isValid()) {
      map.fitBounds(bounds, { padding: [40, 40] });
    }
  }, [selectedLines, shapesMap, stopsGeoJSON, map]);
  return null;
}

/* ------------------------------------------------------------------
   Composant de clustering des arrêts avec use‑supercluster
------------------------------------------------------------------ */
function SuperclusterStopMarkers({ stops, networkId, navigate, nearestStopId }) {
  const map = useMap();
  const [zoom, setZoom] = useState(map.getZoom());
  const [bounds, setBounds] = useState(map.getBounds());

  useEffect(() => {
    const onMove = () => {
      setZoom(map.getZoom());
      setBounds(map.getBounds());
    };
    map.on('moveend', onMove);
    return () => map.off('moveend', onMove);
  }, [map]);

  const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

  // Options dynamiques selon la densité
  const dynamicOptions = () => {
    const stopCount = stops.length;
    if (stopCount < 50) {
      return { radius: 40, maxZoom: 22 };
    } else if (stopCount < 200) {
      return { radius: 80, maxZoom: 22 };
    } else {
      return { radius: 150, maxZoom: 22 };
    }
  };

  // Création des points pour le clustering
  const points = useMemo(
    () =>
      stops.map(stop => ({
        type: 'Feature',
        properties: {
          cluster: false,
          stop_id: stop.properties.stop_id,
          stop_name: stop.properties.stop_name,
          ...stop.properties
        },
        geometry: {
          type: 'Point',
          coordinates: stop.geometry.coordinates
        }
      })),
    [stops]
  );

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: bbox,
    zoom,
    options: dynamicOptions()
  });

  return (
    <>
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;
        // Si c'est un cluster avec au moins 3 arrêts, on affiche la bulle cluster
        if (isCluster) {
          if (pointCount >= 3 && zoom < 20) { 
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                position={[latitude, longitude]}
                icon={createClusterIcon(pointCount)}
                pane="stopsPane"
                eventHandlers={{
                  click: () => {
                    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                    map.setView([latitude, longitude], expansionZoom);
                  }
                }}
              />
            );
          } else {
            // Si le cluster contient moins de 3 arrêts, on récupère ses feuilles et on les affiche individuellement
            const leaves = supercluster.getLeaves(cluster.id, Infinity);
            return leaves.map(leaf => {
              const [lng, lat] = leaf.geometry.coordinates;
              const isNearest = leaf.properties.stop_id === nearestStopId;
              return (
                <CircleMarker
                  key={`stop-${leaf.properties.stop_id}`}
                  center={[lat, lng]}
                  radius={10}
                  pathOptions={{ color: isNearest ? '#07b1f6' : 'black', fillColor: 'white', fillOpacity: 1 }}
                  pane="stopsPane"
                >
                  {isNearest && (
                    <Tooltip permanent direction="top" offset={[0, -8]}>
                      Arrêt le plus proche
                    </Tooltip>
                  )}
                  <Popup autoPan={false}>
                    <strong style={{
                      fontSize: '1rem',
                      color: '#333',
                      textAlign: 'center',
                      display: 'block',
                      textTransform: 'uppercase'
                    }}>
                      {leaf.properties.stop_name}
                    </strong>
                    <a
                      onClick={() =>
                        navigate(`/network/${networkId}/stop/${leaf.properties.stop_id}`)
                      }
                      style={{
                        marginTop: '10px',
                        backgroundColor: '#0A78A4',
                        color: '#fff',
                        padding: '8px 12px',
                        borderRadius: '20px',
                        cursor: 'pointer',
                        fontSize: '14px',
                        display: 'block',
                        width: '100%',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        transition: 'background-color 0.3s, transform 0.2s'
                      }}
                      onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                      onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
                    >
                      Voir les Horaires
                    </a>
                  </Popup>
                </CircleMarker>
              );
            });
          }
        }
        // Pour les points individuels qui ne font pas partie d'un cluster
        const isNearest = cluster.properties.stop_id === nearestStopId;
        return (
          <CircleMarker
            key={`stop-${cluster.properties.stop_id}`}
            center={[latitude, longitude]}
            radius={10}
            pathOptions={{ color: isNearest ? '#07b1f6' : 'black', fillColor: 'white', fillOpacity: 1 }}
            pane="stopsPane"
          >
            {isNearest && (
              <Tooltip permanent direction="top" offset={[0, -20]}>
                Arrêt proche   :   <b>  {cluster.properties.stop_name}</b>
              </Tooltip>
            )}
            <Popup autoPan={false}>
              <strong style={{
                fontSize: '1rem',
                color: '#333',
                textAlign: 'center',
                display: 'block',
                textTransform: 'uppercase'
              }}>
                {cluster.properties.stop_name}
              </strong>
              <a
                onClick={() =>
                  navigate(`/network/${networkId}/stop/${cluster.properties.stop_id}`)
                }
                style={{
                  marginTop: '10px',
                  backgroundColor: '#0A78A4',
                  color: '#fff',
                  padding: '8px 12px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  display: 'block',
                  width: '100%',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  transition: 'background-color 0.3s, transform 0.2s'
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
              >
                Voir les Horaires
              </a>
            </Popup>
          </CircleMarker>
        );
      })}
    </>
  );
}

/* ------------------------------------------------------------------
   Composant principal
------------------------------------------------------------------ */
export default function StopsMap() {
  const { networkId } = useParams();
  const navigate = useNavigate();

  const [stopsGeoJSON, setStopsGeoJSON] = useState(null);
  const [busLines, setBusLines] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [shapesMap, setShapesMap] = useState({});
  const [tripRouteMap, setTripRouteMap] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [vehicleTripHeadsigns, setVehicleTripHeadsigns] = useState({});
  const [vehicleNextStops, setVehicleNextStops] = useState({});
  const [userPosition, setUserPosition] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);

  // Filtrage des arrêts pour éliminer les doublons ou positions trop proches
  const filteredStops = useMemo(() => {
    if (!stopsGeoJSON || !stopsGeoJSON.features) return [];
    const uniqueStops = [];
    const threshold = 5; // seuil en mètres
    stopsGeoJSON.features.forEach(stop => {
      const [lng, lat] = stop.geometry.coordinates;
      const exists = uniqueStops.find(existingStop => {
        const [exLng, exLat] = existingStop.geometry.coordinates;
        const distance = L.latLng(lat, lng).distanceTo(L.latLng(exLat, exLng));
        return distance < threshold;
      });
      if (!exists) {
        uniqueStops.push(stop);
      }
    });
    return uniqueStops;
  }, [stopsGeoJSON]);

  useEffect(() => {
    const welcomeShown = localStorage.getItem('welcomeMessageShown');
    if (!welcomeShown) {
      setShowWelcome(true);
    }
  }, []);

  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          setUserPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => console.error('Erreur de géolocalisation:', error),
        {
          enableHighAccuracy: true,
          maximumAge: 10000,
          timeout: 5000,
        }
      );
    } else {
      console.error("La géolocalisation n'est pas supportée par ce navigateur.");
    }
    return () => {
      if (watchId) navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  useEffect(() => {
    axios.get(`/getStopsGeoJSON.php?network_id=${networkId}`)
      .then(({ data }) => setStopsGeoJSON(data))
      .catch(console.error);

    axios.get(`/getBusLines.php?network_id=${networkId}`)
      .then(({ data }) => {
        if (Array.isArray(data)) setBusLines(data);
      })
      .catch(console.error);

    axios.get(`/getTripRoutes.php?network_id=${networkId}`)
      .then(({ data }) => setTripRouteMap(data))
      .catch(console.error);
  }, [networkId]);

  useEffect(() => {
    selectedLines.forEach(line => {
      if (!shapesMap[line.route_id]) {
        axios
          .get(`/getLineShape.php?network_id=${networkId}&route_id=${line.route_id}`)
          .then(({ data }) => {
            setShapesMap(prev => ({ ...prev, [line.route_id]: data }));
          })
          .catch(err => {
            console.warn(`Shape fetch error (route_id=${line.route_id}):`, err);
          });
      }
    });
  }, [selectedLines, networkId, shapesMap]);

  useEffect(() => {
    if (!selectedLines.length) {
      setVehicles([]);
      return;
    }
    let cancelled = false;

    const fetchVehicles = async () => {
      try {
        const { data: agency } = await axios.get(`/getAgency.php?network_id=${networkId}`);
        const feedRes = await axios.get(agency.gtfs_vehicule, { responseType: 'arraybuffer' });
        const root = await protobuf.load('/protos/gtfs-realtime.proto');
        const FeedMessage = root.lookupType('transit_realtime.FeedMessage');
        const feed = FeedMessage.decode(new Uint8Array(feedRes.data));

        const selectedRouteIds = selectedLines.map(l => l.route_id);
        const filtered = feed.entity.filter(e => {
          const feedRouteId = e.vehicle?.trip?.routeId;
          const dbRouteId = tripRouteMap[e.vehicle?.trip?.tripId];
          const finalRouteId = dbRouteId || feedRouteId;
          return finalRouteId && selectedRouteIds.includes(finalRouteId);
        });

        if (!cancelled) setVehicles(filtered);
      } catch (err) {
        console.error('Realtime fetch error:', err);
      }
    };

    fetchVehicles();
    const intervalId = setInterval(fetchVehicles, 10000);
    return () => {
      cancelled = true;
      clearInterval(intervalId);
    };
  }, [selectedLines, networkId, tripRouteMap]);

  useEffect(() => {
    vehicles.forEach(v => {
      const vehicleId = v.vehicle?.vehicle?.id;
      if (vehicleId && !vehicleDetails[vehicleId]) {
        axios.get(`/getVehicleDetails.php?vehicle_id=${vehicleId}`)
          .then(response => {
            setVehicleDetails(prev => {
              if (!prev[vehicleId]) {
                return { ...prev, [vehicleId]: response.data };
              }
              return prev;
            });
          })
          .catch(err => console.error(`Erreur pour le véhicule ${vehicleId}:`, err));
      }
    });
  }, [vehicles, vehicleDetails]);

  useEffect(() => {
    vehicles.forEach(v => {
      const tripId = v.vehicle?.trip?.tripId;
      if (tripId && !vehicleTripHeadsigns[tripId]) {
        axios.get(`/getTripHeadsign.php?network_id=${networkId}&trip_id=${tripId}`)
          .then(({ data }) => {
            if (data.trip_headsign) {
              setVehicleTripHeadsigns(prev => ({ ...prev, [tripId]: data.trip_headsign }));
            }
          })
          .catch(err => console.error(`Erreur pour le trip ${tripId}:`, err));
      }
    });
  }, [vehicles, vehicleTripHeadsigns, networkId]);

  useEffect(() => {
    vehicles.forEach(v => {
      const nextStopId = v.vehicle?.stopId;
      if (nextStopId && !vehicleNextStops[nextStopId]) {
        axios.get(`/getStopDetails.php?network_id=${networkId}&stop_id=${nextStopId}`)
          .then(({ data }) => {
            if (data.stop_name) {
              setVehicleNextStops(prev => ({ ...prev, [nextStopId]: data.stop_name }));
            }
          })
          .catch(err => console.error(`Erreur pour le stop ${nextStopId}:`, err));
      }
    });
  }, [vehicles, networkId]);

  const toggleLine = (line) => {
    setSelectedLines(prev => {
      const exists = prev.some(l => l.route_id === line.route_id);
      if (exists) return prev.filter(l => l.route_id !== line.route_id);
      if (prev.length < 5) return [...prev, line];
      return prev;
    });
  };

  const nearestStop = useMemo(() => {
    if (!userPosition || !stopsGeoJSON) return null;
    const userLatLng = L.latLng(userPosition.lat, userPosition.lng);
    let minDistance = Infinity;
    let closestStop = null;
    stopsGeoJSON.features.forEach(feature => {
      const stopLatLng = L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
      const distance = userLatLng.distanceTo(stopLatLng);
      if (distance < minDistance) {
        minDistance = distance;
        closestStop = feature;
      }
    });
    return { stop: closestStop, distance: minDistance };
  }, [userPosition, stopsGeoJSON]);

  const nearestVehicle = useMemo(() => {
    if (!userPosition || vehicles.length === 0) return null;
    const userLatLng = L.latLng(userPosition.lat, userPosition.lng);
    let minDistance = Infinity;
    let closestVehicle = null;
    vehicles.forEach(v => {
      if (v.vehicle && v.vehicle.position) {
        const vehLatLng = L.latLng(v.vehicle.position.latitude, v.vehicle.position.longitude);
        const distance = userLatLng.distanceTo(vehLatLng);
        if (distance < minDistance) {
          minDistance = distance;
          closestVehicle = v;
        }
      }
    });
    return { vehicle: closestVehicle, distance: minDistance };
  }, [userPosition, vehicles]);

  const activeVehicles = useMemo(() => {
    const currentTimeSec = Date.now() / 1000;
    return vehicles.filter(v => {
      if (v.vehicle && v.vehicle.timestamp) {
        return (currentTimeSec - v.vehicle.timestamp <= 120);
      }
      return true;
    });
  }, [vehicles]);

  const SHOW_NEAREST_BUS_MAX_DISTANCE = 300; // en mètres

  const nearestBusInfo = useMemo(() => {
    if (!userPosition || activeVehicles.length === 0 || !stopsGeoJSON) return '';
    const userLatLng = L.latLng(userPosition.lat, userPosition.lng);
    let minUserDist = Infinity;
    let closestVeh = null;
    activeVehicles.forEach(v => {
      const pos = v.vehicle?.position;
      if (pos) {
        const dist = userLatLng.distanceTo(L.latLng(pos.latitude, pos.longitude));
        if (dist < minUserDist) {
          minUserDist = dist;
          closestVeh = v;
        }
      }
    });
    if (!closestVeh || minUserDist > SHOW_NEAREST_BUS_MAX_DISTANCE) return '';
    const routeId = closestVeh.vehicle.trip.routeId || tripRouteMap[closestVeh.vehicle.trip.tripId];
    const line = busLines.find(b => b.route_id === routeId);
    const nextStopId = closestVeh.vehicle.stopId;
    if (!line || !nextStopId) return '';
    const stopFeature = stopsGeoJSON.features.find(f => f.properties.stop_id === nextStopId);
    if (!stopFeature) return '';
    const vehLatLng = L.latLng(closestVeh.vehicle.position.latitude, closestVeh.vehicle.position.longitude);
    const stopLatLng = L.latLng(stopFeature.geometry.coordinates[1], stopFeature.geometry.coordinates[0]);
    const distToStop = Math.round(vehLatLng.distanceTo(stopLatLng));
    return `Bus le plus proche : un bus LIGNE ${line.route_short_name} arrive à "${stopFeature.properties.stop_name}" dans ${distToStop} m`;
  }, [activeVehicles, userPosition, busLines, tripRouteMap, stopsGeoJSON]);

  const defaultPos = [48.8566, 2.3522];
  const panelHeight = window.innerWidth < 600 ? '60vh' : 400;

  // Si les arrêts ne sont pas encore chargés, on affiche un écran de chargement
  if (!stopsGeoJSON) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Chargement en cours...
      </div>
    );
  }

  // Calcul du centre initial basé sur le premier arrêt
  const initialCenter = stopsGeoJSON.features.length > 0
    ? [
        stopsGeoJSON.features[0].geometry.coordinates[1],
        stopsGeoJSON.features[0].geometry.coordinates[0]
      ]
    : defaultPos;

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 9999 }}>
      <style>{`
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0px rgba(0, 181, 255, 1);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.pulse {  
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 3s infinite;
}
      `}</style>

      {showWelcome && (
        <div style={{
          position: 'absolute',
          top: 80,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1200,
          background: 'rgba(255,255,255,0.95)',
          padding: '16px 24px',
          borderRadius: '8px',
          boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
          maxWidth: '100%',
          textAlign: 'center'
        }}>
          <p style={{ margin: 0, fontSize: '14px', color: '#333' }}>
            Bienvenue sur la carte intéractive, elle est en test, des améliorations seront ajoutées prochainement 
            avec la possibilité de voir la destination des bus. Vous pouvez voir tous les bus sur la carte en choisissant 5 lignes MAXIMUM en cliquant sur "Géolocalisation".
          </p>
          <button onClick={() => {
            setShowWelcome(false);
            localStorage.setItem('welcomeMessageShown', 'true');
          }} style={{
            marginTop: '12px',
            background: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '6px',
            padding: '8px 16px',
            cursor: 'pointer',
            fontSize: '14px'
          }}>
            Fermer
          </button>
        </div>
      )}

      <FaTimes
        size={40}
        onClick={() => navigate(`/network/${networkId}`)}
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 1100,
          background: '#fff',
          borderRadius: '50%',
          padding: 8,
          cursor: 'pointer',
          boxShadow: '0 2px 6px rgba(0,0,0,.3)',
        }}
      />

      <div style={{
        position: 'absolute',
        top: 75,
        right: -2,
        zIndex: 1100
      }}>
        <button
          onClick={() => setPanelOpen(o => !o)}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#fff',
            fontSize: '12px',
            border: 'none',
            borderRadius: "8px 0 0 8px",
            padding: 10,
            boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
            cursor: 'pointer'
          }}
        >
          <FaBus size={16} />
          <span style={{ marginLeft: 8 }}>Géolocalisation</span>
        </button>
      </div>

      {panelOpen && (
        <div
          style={{
            position: 'absolute',
            top: 100,
            right: 20,
            zIndex: 1100,
            width: window.innerWidth < 600 ? 'calc(100vw - 40px)' : 300,
            background: '#fff',
            borderRadius: 8,
            boxShadow: '0 2px 8px rgba(0,0,0,0.3)'
          }}
        >
          <div
            style={{
              padding: 12,
              borderBottom: '1px solid #ddd',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <strong>Choisir une ligne ({selectedLines.length}/5)</strong>
            <FaTimes size={26} onClick={() => setPanelOpen(false)} style={{ cursor: 'pointer' }} />
          </div>
          <div style={{ maxHeight: panelHeight, overflowY: 'auto', padding: 12 }}>
            {busLines.map(line => {
              const hex = `#${line.route_color}`;
              const selected = selectedLines.some(l => l.route_id === line.route_id);
              return (
                <button
                  key={line.route_id}
                  onClick={() => toggleLine(line)}
                  style={{
                    display: 'block',
                    width: '100%',
                    margin: '6px 0',
                    padding: '8px',
                    background: hex,
                    color: isColorLight(hex) ? '#000' : '#fff',
                    border: 'none',
                    borderRadius: 4,
                    cursor: 'pointer',
                    opacity: selected ? 1 : 0.8,
                  }}
                >
                  {line.route_short_name} — {line.route_long_name}
                </button>
              );
            })}
          </div>
        </div>
      )}

      <MapContainer 
        center={initialCenter}
        attributionControl={false}
        zoom={12}
        style={{ width: '100%', height: '100%' }}
        whenCreated={setMapInstance}
      >
        <Pane name="shapesPane" style={{ zIndex: 200 }} />
        <Pane name="stopsPane" style={{ zIndex: 400 }} />
        <Pane name="vehiclesPane" style={{ zIndex: 600 }} />

        <TileLayer
          url={getMapTileUrl()}
          attribution='&copy; <a href="https://www.mapbox.com/">Mapbox</a> contributors'
        />

        <AutoCenter selectedLines={selectedLines} shapesMap={shapesMap} stopsGeoJSON={stopsGeoJSON} />

        {Object.entries(shapesMap)
          .filter(([route_id]) =>
            selectedLines.some(line => line.route_id === route_id)
          )
          .map(([route_id, shapeGeo]) => {
            if (!isValidGeoJSON(shapeGeo)) {
              console.warn(`Shape du route_id=${route_id} invalide ou absent`);
              return null;
            }
            const lineCfg = busLines.find(b => b.route_id === route_id);
            const shapeColor = lineCfg ? `#${lineCfg.route_color}` : '#000';
            const darkShapeColor = darkenColor(shapeColor, 0.1);

            return (
              <GeoJSON
                key={route_id}
                data={shapeGeo}
                style={{
                  color: darkShapeColor,
                  weight: 6,
                  opacity: 0.9,
                }}
                pane="shapesPane"
              />
            );
          })}

        {stopsGeoJSON && stopsGeoJSON.features && (
          <SuperclusterStopMarkers
            stops={filteredStops}
            networkId={networkId}
            navigate={navigate}
            nearestStopId={nearestStop?.stop?.properties?.stop_id}
          />
        )}

        {activeVehicles.map(v => {
          const routeId = v.vehicle?.trip?.routeId || tripRouteMap[v.vehicle?.trip?.tripId];
          const line = busLines.find(b => b.route_id === routeId);
          const color = line ? `#${line.route_color}` : '#000';
          const bearing = v.vehicle?.position?.bearing || 0;
          const vehicleId = v.vehicle?.vehicle?.id;
          const tripId = v.vehicle?.trip?.tripId;
          const tripHeadsign = vehicleTripHeadsigns[tripId];
          const nextStopId = v.vehicle?.stopId;
          const nextStopName = vehicleNextStops[nextStopId];

          return (
            <Marker
              key={v.id}
              position={[v.vehicle.position.latitude, v.vehicle.position.longitude]}
              icon={createVehicleIcon(color, bearing, 1)}
              pane="vehiclesPane"
            >
              <Popup
                autoClose={true}
                closeOnClick={true}
                closeOnEscapeKey={false}
                autoPan={false}
                minWidth={220}
                maxWidth={220}
              >
                <div style={{
                  fontWeight: '700',
                  fontSize: '40px',
                  marginBottom: '8px',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  color: line ? darkenColor(`#${line.route_color}`) : '#ccc',
                  padding: '4px 8px',
                  borderRadius: '4px',
                }}>
                  {line ? `${line.route_short_name}` : 'Ligne inconnue'}
                </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                  color: line ? darkenColor(`#${line.route_color}`) : '#ccc',
                  marginBottom: '8px'
                }}>
                  <BiSolidRightArrow style={{ fontSize: '18px', flexShrink: 0, marginRight: '5px' }} />
                  <b style={{ textTransform: 'uppercase' }}>{tripHeadsign ?? 'Chargement...'}</b>
                </div>
                <div style={{ fontSize: '14px', color: '#333', textAlign: 'center', marginBottom: '8px' }}>
                  Prochain arrêt : <b style={{ textTransform: 'uppercase' }}>{nextStopName ?? 'Chargement...'}</b>
                </div>
                {vehicleDetails[vehicleId] ? (
                  <div style={{ marginBottom: '-10px', color: '#333', lineHeight: '1.5' }}>
                    <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '-5px', textTransform: 'uppercase' }}>
                      {vehicleDetails[vehicleId].brand}
                    </div>
                    <div style={{ fontSize: '12px', color: '#555' }}>
                      {vehicleDetails[vehicleId].model}
                    </div>
                    <div style={{ textAlign: 'right', fontSize: '8px', color: '#555' }}>
                      ID : {vehicleId}
                    </div>
                  </div>
                ) : (
                  <div style={{ marginBottom: '-10px', fontStyle: 'italic', fontSize: '8px', color: '#474747' }}>
                    Ce véhicule n'est pas dans la base de données. Contacte le support pour l'ajouter.
                  </div>
                )}
              </Popup>
            </Marker>
          );
        })}

        {userPosition && (
          <Marker
            position={[userPosition.lat, userPosition.lng]}
            icon={createUserLocationIcon()}
          />
        )}
      </MapContainer>

      {nearestBusInfo && (
        <div style={{
          position: 'absolute',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          background: 'rgba(255,255,255,0.9)',
          padding: '8px 12px',
          borderRadius: '8px',
          fontSize: '14px',
          zIndex: 1100,
          boxShadow: '0 2px 6px rgba(0,0,0,0.3)'
        }}>
          {nearestBusInfo}
        </div>
      )}

      {loading && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          background: '#fff',
          padding: '16px 24px',
          borderRadius: 8,
          boxShadow: '0 2px 8px rgba(0,0,0,.3)',
          zIndex: 2000,
        }}>
          Chargement en cours...
        </div>
      )}
    </div>
  );
}

/* ------------------------------------------------------------------
   Création d'un icône pour la géolocalisation (pulse + centrage)
------------------------------------------------------------------ */
function createUserLocationIcon() {
  const html = renderToStaticMarkup(
    <div style={{ position: 'relative', width: '40px', height: '40px' }}>
      <div className="pulse" style={{
        position: 'relative',
        transform: 'translate(-50%, -50%)',
        top: '0%',
        left: '0%'
      }}></div>
      <div style={{
        position: 'absolute',
        top: '25%',
        left: '25%',
        width: '25px',
        height: '25px',
        background: 'linear-gradient(90deg, #00b5ff, #00bfff, #01afc9)', 
        border: '4px solid white',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)'
      }}></div>
      <div style={{
        position: 'absolute',
        bottom: '100%',
        left: '20%',
        transform: 'translate(-50%, -5px)',
        background: 'rgba(255,255,255,0.8)',
        padding: '2px 4px',
        borderRadius: '4px',
        fontSize: '10px',
        whiteSpace: 'nowrap'
      }}>
        Vous êtes ici
      </div>
    </div>
  );
  return L.divIcon({
    html,
    className: '',
    iconSize: [40, 40],
    iconAnchor: [20, 20]
  });
}
