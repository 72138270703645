import React from "react";

function Airemob() {
  return (
    <div className="container my-5">
      {/* TITRE PRINCIPAL */}
      <div className="text-center mb-5">
        <h1 className="display-4">Transports en commun de l’Aire Cantilienne – Airemob</h1>
        <p className="lead">
          Une histoire et un réseau au service des communes de Chantilly, Gouvieux et Lamorlaye
        </p>
      </div>

      {/* SECTION HISTORIQUE */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Historique</h2>
          <p className="text-center">
            Le réseau de transports en commun de l’Aire Cantilienne s’est développé pour répondre aux besoins de mobilité des habitants des communes situées autour de Chantilly, Gouvieux et Lamorlaye.
          </p>
        </div>
      </div>

      {/* CARDS HISTORIQUES */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* Card 1 : Les débuts */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Les débuts (années 1970)</h5>
              <p className="card-text">
                Les premiers services de bus apparaissent dans les années 1970, limités à quelques circuits reliant le centre de Chantilly et ses abords, avec pour objectif d’assurer les déplacements domicile-travail et domicile-école.
              </p>
            </div>
          </div>
        </div>

        {/* Card 2 : Expansion et restructuration */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Expansion et restructuration (années 1980-1990)</h5>
              <p className="card-text">
                Face à une croissance démographique et à un essor économique régional, le réseau s’étend dans les années 1980-1990 avec la création de nouvelles lignes pour couvrir un territoire de plus en plus vaste et relier efficacement les zones résidentielles et commerciales.
              </p>
            </div>
          </div>
        </div>

        {/* Card 3 : Modernisation */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Modernisation (début des années 2000)</h5>
              <p className="card-text">
                Au début du XXIe siècle, le renouvellement de la flotte, l’amélioration de l’accessibilité des arrêts et la mise en place de systèmes d’information voyageurs modernisent le réseau pour répondre aux exigences contemporaines.
              </p>
            </div>
          </div>
        </div>

        {/* Card 4 : L’avènement d’Airemob */}
        <div className="col">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">L’avènement d’Airemob</h5>
              <p className="card-text">
                Sous le nom d’Airemob, le réseau se structure pour mieux organiser ses services et optimiser les correspondances entre Chantilly, Gouvieux, Lamorlaye et les communes limitrophes, affichant une offre intégrée et moderne.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION RÉSEAU & SERVICES */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Réseau et services</h2>
          <p className="text-center">
            Le système de transports en commun se compose de plusieurs services distincts qui se complètent pour couvrir l’ensemble de l’Aire Cantilienne.
          </p>
        </div>
      </div>

      {/* CARDS RÉSEAU & SERVICES */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* Card : Service DUC */}
        <div className="col">
          <div className="card h-100 shadow-sm border-success">
            <div className="card-body">
              <h5 className="card-title">DUC (Desserte Urbaine Cantilienne)</h5>
              <p className="card-text">
                Mis en place en 2000, ce service gratuit relie les quartiers nord et sud de Chantilly à la gare, facilitant les déplacements quotidiens.
              </p>
            </div>
          </div>
        </div>

        {/* Card : La Navette */}
        <div className="col">
          <div className="card h-100 shadow-sm border-info">
            <div className="card-body">
              <h5 className="card-title">La Navette</h5>
              <p className="card-text">
                Lancé en août 2019, ce service gratuit propose plusieurs circuits (N1, N2 et, depuis septembre 2024, N3) ainsi que des liaisons initialement gratuites pour desservir Chantilly et ses environs. Certaines lignes de correspondance, comme celles reliant aux gares, ont évolué avec le temps.
              </p>
            </div>
          </div>
        </div>

        {/* Card : Flexobus */}
        <div className="col">
          <div className="card h-100 shadow-sm border-warning">
            <div className="card-body">
              <h5 className="card-title">Flexobus</h5>
              <p className="card-text">
                Créé en mai 2022 et étendu en septembre 2023, ce service de transport à la demande gratuit permet aux habitants de certaines communes d’accéder facilement aux principaux pôles d’échanges.
              </p>
            </div>
          </div>
        </div>

        {/* Card : Aire'bus */}
        <div className="col">
          <div className="card h-100 shadow-sm border-primary">
            <div className="card-body">
              <h5 className="card-title">Aire'bus</h5>
              <p className="card-text">
                Lancé en novembre 2024, ce service d’autocar payant relie Gouvieux à l’aéroport, en desservant notamment Chantilly, Lamorlaye, Coye-la-Forêt et Orry-la-Ville, avec une large amplitude horaire.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION FLOTTES & ACCESSIBILITÉ */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Flotte et accessibilité</h2>
          <p className="text-center">
            La modernisation du matériel roulant a permis d’introduire des bus à plancher bas pour améliorer l’embarquement, le confort et l’accessibilité, tout en respectant des normes environnementales strictes.
          </p>
        </div>
      </div>

      {/* CARDS FLOTTES */}
      <div className="row row-cols-1 row-cols-md-2 g-4 mb-5">
        {/* Card : Bus standards */}
        <div className="col">
          <div className="card h-100 shadow-sm border-success">
            <div className="card-body">
              <h5 className="card-title">Bus standards</h5>
              <p className="card-text">
                La majorité des véhicules du réseau sont des bus urbains conçus pour faciliter l’embarquement rapide et sécuriser les déplacements de tous les usagers, avec des motorisations conformes aux normes récentes.
              </p>
            </div>
          </div>
        </div>

        {/* Card : Midibus et minibus */}
        <div className="col">
          <div className="card h-100 shadow-sm border-info">
            <div className="card-body">
              <h5 className="card-title">Midibus et minibus</h5>
              <p className="card-text">
                Pour les secteurs à faible densité et les trajets courts, le réseau déploie des midibus et minibus, offrant une solution flexible et adaptée aux contraintes locales.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* SECTION TARIFICATION */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Tarification</h2>
          <p className="text-center">
            Le réseau propose une offre tarifaire diversifiée, avec des titres gratuits pour certains services (comme le DUC et La Navette) et des tarifs unitaires, carnets ou abonnements pour les services payants (notamment Aire'bus). Les titres sont rechargeables via une carte dédiée.
          </p>
        </div>
      </div>

      {/* SECTION PERSPECTIVES */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-center">Perspectives d’avenir</h2>
          <p className="text-center">
            Afin de répondre aux enjeux d’une mobilité en constante évolution, le réseau envisage de renforcer la fréquence, d’élargir son maillage territorial et d’intégrer de nouvelles technologies pour améliorer l’information voyageurs et réduire l’empreinte environnementale.
          </p>
        </div>
      </div>

      {/* SECTION CONCLUSION */}
      <div className="row">
        <div className="col text-center">
          <h2>Conclusion</h2>
          <p>
            Les transports en commun de l’Aire Cantilienne, opérés sous le nom d’Airemob, constituent un maillon essentiel de la mobilité locale. Fort d’une histoire s’étendant sur plusieurs décennies et d’une offre de services diversifiée – allant des lignes régulières gratuites aux services à la demande et aux liaisons interurbaines – le réseau continue de s’adapter pour offrir un service moderne, accessible et respectueux de l’environnement.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Airemob;
