// AlertDetails.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { RiSnowyFill } from "react-icons/ri";
import { IoIosWarning } from "react-icons/io";
import { FaBurst } from "react-icons/fa6";

function AlertDetails() {
  const { networkId, alertId } = useParams();
  const navigate = useNavigate();

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/getAlerts.php?network_id=${networkId}`)
      .then((response) => {
        // Compare en "==" pour ignorer la différence string / number
        if (response.data && response.data.id && response.data.id == alertId) {
          setAlert(response.data);
        } else {
          setAlert(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setAlert(null);
      })
      .finally(() => setLoading(false));
  }, [networkId, alertId]);

  if (loading) {
    return <div style={{ padding: '20px' }}></div>;
  }

  // Choix de la couleur de l’icône, par ex. #0a78a4
  const iconColor = '#0a78a4';

  // Fonction pour l’icône selon alert.icone
  const renderIcon = () => {
    if (alert.icone == '1') {
      return <RiSnowyFill style={{ fontSize: '48px', color: iconColor }} />;
    }
    if (alert.icone == '2') {
      return <IoIosWarning style={{ fontSize: '48px', color: iconColor }} />;
    }
    if (alert.icone == '3') {
      return <FaBurst style={{ fontSize: '48px', color: iconColor }} />;
    }
    return null;
  };

  return (
    <div
      style={{
        /* Fond de la page tout blanc */
        backgroundColor: '#fff',
        minHeight: '100vh',
        /* On place le contenu en haut, centrée horizontalement */
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px',  // Espace en haut
      }}
    >
      {/* Bouton Retour en haut, prenant toute la largeur */}
      <div style={{ width: '90%', maxWidth: '600px', marginBottom: '20px' }}>
        <button
          onClick={() => navigate(-1)}
          style={{
            padding: '10px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            backgroundColor: iconColor,
            color: '#fff',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            width: '100%',
          }}
        >
          Retour
        </button>
      </div>

      {/* La card */}
      {alert ? (
        <div
          style={{
            width: '90%',
            maxWidth: '600px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            /* Contour noir + effet shadow */
            border: '2px solid black',
            boxShadow: '0 0 15px rgba(0,0,0,0.1)',
            position: 'relative',
            padding: '40px 20px 40px', // On laisse de la place en bas
            margin: '0 auto', // centre horizontalement
          }}
        >
          {/* Icône en haut à droite */}
          <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
            {renderIcon()}
          </div>

          {/* Titre centré */}
          <h2 style={{ textAlign: 'center', margin: '40px 0 10px' }}>
            {alert.title}
          </h2>

          {/* Message justifié et légèrement plus petit */}
          <div
            style={{ 
              textAlign: 'justify', 
              marginTop: '30px', 
              fontSize: '1rem' // Taille de police réduite
            }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(alert.message) }}
          />
        </div>
      ) : (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h4>Aucune alerte trouvée</h4>
          <p>ID : {alertId} - Réseau : {networkId}</p>
        </div>
      )}
    </div>
  );
}

export default AlertDetails;
