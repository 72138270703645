// PrivacyPolicy.js
import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      {/* Helmet pour définir le titre de l'onglet */}
      <Helmet>
        <title>Bus Connect - Politique de confidentialité</title>
      </Helmet>

      <div style={{ fontFamily: 'Arial, sans-serif', padding: '40px', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto' }}>
        <h1 style={{ fontSize: '2em', textAlign: 'center', marginBottom: '40px' }}>
          Politique de confidentialité
        </h1>
        
        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Introduction</h2>
          <p>
            La présente Politique de Confidentialité décrit les pratiques de Bus Connect en matière de collecte, d’utilisation, 
            de conservation et de protection des informations relatives aux utilisateurs. Notre objectif est de garantir la 
            transparence, le respect de la vie privée et la confidentialité des données personnelles.
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Données Collectées</h2>
          <p>
            Bus Connect ne collecte aucune donnée personnelle d’identification directe. Nous utilisons uniquement des cookies 
            afin de mémoriser les réseaux de bus sélectionnés et les favoris par ligne. Par ailleurs, des outils externes 
            comme Google Analytics ou des sondages sous forme de formulaires peuvent être intégrés. Ces mécanismes ne stockent 
            que des informations techniques ou anonymisées.
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Objectifs de l’Utilisation</h2>
          <p>
            Les données collectées servent principalement à des fins d’analyse et de sondage. Ceci nous aide à améliorer la 
            qualité de notre application, à mieux comprendre l’expérience utilisateur et à proposer des fonctionnalités adaptées.
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>
            Durée de Conservation & Méthodes Anonymes
          </h2>
          <p>
            Les informations recueillies sont automatiquement supprimées au bout de 31 jours. Une méthode d’anonymisation est 
            également mise en place afin de conserver uniquement des données agrégées ne permettant pas de retracer l’identité 
            d’un individu. Ainsi, passé ce délai, seules des statistiques globales, inexploitables individuellement, demeurent.
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Partage des Données</h2>
          <p>
            Bus Connect ne divulgue ni ne vend, de quelque manière que ce soit, les données collectées à des tiers. Aucune 
            transmission externe n’est effectuée, garantissant une confidentialité maximale.
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Mesures de Sécurité</h2>
          <p>
            Des mesures de sécurité techniques et organisationnelles sont mises en place, telles que le chiffrement des 
            connexions et la restriction des accès aux seules personnes autorisées. Cette approche contribue à prévenir 
            tout accès non autorisé, perte, altération ou divulgation inappropriée.
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Droits des Utilisateurs</h2>
          <p>
            Chaque utilisateur peut demander la suppression de ses données ou exercer ses droits d’accès, de rectification 
            et d’opposition en adressant une requête par courrier électronique. Nous nous engageons à répondre dans des 
            délais raisonnables et à honorer ces demandes dans la mesure où elles sont légitimes et conformes aux lois 
            applicables.
          </p>
        </section>

        <section style={{ marginBottom: '30px' }}>
          <h2 style={{ fontSize: '1.5em', marginBottom: '10px' }}>Contact</h2>
          <p>
            Pour toute question, demande de renseignements ou suppression de données, veuillez contacter Bus Connect 
            à l’adresse suivante :{' '}
            <a
              href="mailto:contact@busconnect.fr"
              style={{ color: '#007bff', textDecoration: 'none' }}
            >
              contact@busconnect.fr
            </a>.
          </p>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
