import React from "react";

function Tohm() {
  return (
    <div className="container my-5">
      {/* --- TITRE PRINCIPAL --- */}
      <div className="text-center mb-5">
        <h1 className="display-4">TOHM (Transport Oise Halatte Mobilité)</h1>
        <p className="lead">
          Le réseau de Pont-Sainte-Maxence et environs, héritier du TUM
        </p>
      </div>

      {/* --- SECTION HISTORIQUE --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>1. Historique</h2>
          <p>
            Le réseau de transports urbains de Pont-Sainte-Maxence trouve son
            origine dans le <strong>TUM</strong> (Transports Urbains
            Maxipontains). Pendant plusieurs décennies, il reliait principalement
            le centre-ville, la gare SNCF et quelques quartiers résidentiels.  
            Au fil du temps, la municipalité et la communauté de communes ont
            entrepris une modernisation progressive du réseau, ajoutant de
            nouvelles lignes et renouvelant la flotte de bus.
          </p>
          <p>
            Le <strong>28 février 2022</strong>, le TUM devient officiellement
            <strong> TOHM</strong> (<em>Transport Oise Halatte Mobilité</em>).
            Cette nouvelle identité reflète la volonté d’améliorer la desserte de
            Pont-Sainte-Maxence et d’inclure également les communes voisines,
            avec une attention particulière portée à l’accessibilité (PMR) et au
            respect de l’environnement.
          </p>
        </div>
      </div>

      {/* --- SECTION ORGANISATION --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>2. Organisation et Autorité Organisatrice</h2>
          <p>
            Le réseau TOHM est organisé par la <strong>communauté de communes
            des pays d’Oise et d’Halatte</strong>. L’exploitation est confiée à
            une filiale du groupe <strong>Keolis</strong> (Keolis Oise). Le dépôt
            principal se trouve sur le territoire, où sont entretenus et stationnés
            les bus et navettes.  
            Par le passé, l’organisation relevait directement de la municipalité
            (TUM). Depuis la transformation en TOHM, la compétence transport
            s’exerce à l’échelle intercommunale, permettant une extension
            progressive du service.
          </p>
          <p>
            Le réseau est entièrement <strong>gratuit</strong>. Au 1er juillet
            2021, la communauté de communes a acquis la compétence des transports
            en commun sur son territoire, facilitant la mise en place d’un plan de
            développement et de modernisation (bus plus récents, nouveaux arrêts,
            correspondances plus fluides, etc.).
          </p>
        </div>
      </div>

      {/* --- SECTION ETAT ACTUEL --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>3. État actuel du réseau (jusqu’à fin 2025)</h2>
          <p>
            Actuellement, TOHM propose :  
          </p>
          <ul>
            <li>
              <strong>2 lignes principales</strong> numérotées 1 et 2, reliant
              principalement la gare de Pont-Sainte-Maxence aux quartiers de
              Sarron, Fond Robin, la zone industrielle Pont-Brenouille et le Val
              d’Halatte (centre commercial).
            </li>
            <li>
              <strong>Une troisième ligne</strong> (ou TAD) fonctionnant selon les
              heures de pointe ou sur réservation, desservant des communes comme
              Brenouille, Les Ageux, Saint-Martin-Longueau et Camfil.
            </li>
            <li>
              <strong>Une navette dominicale</strong> ou événementielle (mise en
              place certains jours), reliant plusieurs points clés (surtout la
              gare et des points d’intérêt de la commune).
            </li>
          </ul>
          <p>
            Le réseau est complété par un <strong>service de transport à la
            demande (TAD)</strong> pour les secteurs plus ruraux ou les horaires
            creux, exploité par <em>Keolis Oise</em>. D’après les enquêtes de
            fréquentation, le réseau TUM-TOHM transportait déjà plusieurs
            centaines de milliers de passagers par an avant 2022, et espère
            augmenter ce chiffre grâce aux modernisations en cours.
          </p>
        </div>
      </div>

      {/* --- SECTION EXTENSION 2026 --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>4. Extension du réseau à partir de janvier 2026</h2>
          <p>
            Dès <strong>janvier 2026</strong>, une refonte complète est
            validée, faisant passer le réseau TOHM à <strong>5 lignes
            principales</strong> (A1, A2, B, C et D) et renforçant l’offre de
            navettes. Les nouveautés annoncées :
          </p>
          <ul>
            <li>
              <strong>Ligne A1</strong> : reliera le collège de Pont-Sainte-Maxence
              à la gare, en passant par l’hôpital, Fond Robin et Sarron.
            </li>
            <li>
              <strong>Ligne A2</strong> : itinéraire proche de la A1, reliant
              également le collège et la gare, mais sans desservir l’hôpital.
            </li>
            <li>
              <strong>Ligne B</strong> : couvrira Brenouille (place Lamartine),
              gare de Pont-Sainte-Maxence, Les Ageux, Val d’Halatte, etc.
            </li>
            <li>
              <strong>Ligne C</strong> : desservira le collège de Pont-Sainte-Maxence
              et la zone industrielle Pontpoint-Moru, via la Mairie et La Manekine.
            </li>
            <li>
              <strong>Ligne D</strong> : connectera Saint-Martin-Longueau à la gare,
              principalement via la RD1017, incluant le centre commercial du Val
              d’Halatte et un prolongement vers Camfil.
            </li>
          </ul>
          <p>
            En parallèle, une <strong>navette</strong> supplémentaire sera
            instaurée aux heures de pointe pour relier les communes d’Angicourt,
            Cinqueux, Monceaux, Rieux à la gare de Rieux-Angicourt, offrant
            davantage de correspondances vers Paris.  
            Des <strong>nouveaux véhicules</strong> (bus électriques, navettes
            silencieuses) seront introduits, afin de moderniser la flotte et
            réduire l’impact environnemental.
          </p>
        </div>
      </div>

      {/* --- SECTION MATERIEL ROULANT --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>5. Matériel roulant et évolution</h2>
          <p>
            Au fil des années, la flotte de TOHM (ex-TUM) a évolué :  
          </p>
          <ul>
            <li>
              Anciennes séries diesel (Heuliez, Irisbus Agora) progressivement
              remplacées par des modèles plus propres (Euro 5/6).
            </li>
            <li>
              Arrivée de <strong>Mercedes Citaro</strong>, <strong>Heuliez GX
              327</strong> ou <strong>Iveco Crossway</strong> pour la période
              2015-2022, avec un plancher bas et une meilleure accessibilité PMR.
            </li>
            <li>
              À partir de <strong>2024</strong>, introduction de bus
              <em>électriques</em> ou <em>GNV</em>, suivant les projets de la
              communauté de communes.
            </li>
            <li>
              Dès <strong>2026</strong>, acquisition de navettes silencieuses
              (électriques ou hydrogène) pour la desserte de communes éloignées.
            </li>
          </ul>
        </div>
      </div>

      {/* --- SECTION GRATUITE / FINANCEMENT --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>6. Gratuité et financement</h2>
          <p>
            Le réseau est <strong>gratuit</strong>, suivant la volonté des élus
            locaux de favoriser la mobilité pour tous. Le financement repose sur
            plusieurs ressources :
          </p>
          <ul>
            <li>Versement mobilité (taux fixé par la communauté de communes)</li>
            <li>Participation des communes et subventions départementales /
            régionales</li>
            <li>Budget transport dédié pour l’entretien et le renouvellement de
            la flotte</li>
          </ul>
          <p>
            Cette gratuité rend l’usage du TOHM particulièrement attractif, en
            espérant réduire l’usage de la voiture individuelle sur le territoire.
          </p>
        </div>
      </div>

      {/* --- SECTION CONCLUSION --- */}
      <div className="row">
        <div className="col text-center">
          <h2>Conclusion</h2>
          <p>
            Depuis l’ancien <strong>TUM</strong> jusqu’à la transition en{" "}
            <strong>TOHM</strong> (<em>Transport Oise Halatte Mobilité</em>) le
            28 février 2022, le réseau de Pont-Sainte-Maxence s’est modernisé et
            étendu. Avec les cinq lignes prévues en <strong>janvier 2026</strong>
            (A1, A2, B, C, D), la mise en place d’une navette supplémentaire et
            l’arrivée de nouveaux bus plus écologiques, TOHM poursuit sa mission
            de faciliter les déplacements des habitants. Entièrement gratuit, il
            offre un service de plus en plus performant, visant à améliorer
            l’accessibilité et la mobilité durable dans l’ensemble du territoire.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Tohm;
