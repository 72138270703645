// Schedule.js

import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import AnimatedPolyline from './AnimatedPolyline';
import { useParams, useNavigate } from 'react-router-dom';
import { TbSquareRoundedArrowRightFilled } from "react-icons/tb";
import {
  Container,
  Form,
  FormControl,
  Spinner,
  Row,
  Col,
  Placeholder,
  Toast,
  ToastContainer,
  Modal,
  Button,
  Card,
  ProgressBar
} from 'react-bootstrap';

import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  useMap,
  Popup,
  Tooltip
} from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Helmet } from 'react-helmet';
import protobuf from 'protobufjs';

// ICONES
import { TbArrowBackUp, TbGpsFilled } from "react-icons/tb";
import {
  FaCaretRight,
  FaCaretLeft,
  FaBusSimple,
  FaRegStar,
  FaStar,
  FaCircleCheck
} from "react-icons/fa6";
import { TiDelete } from "react-icons/ti";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdChangeCircle, MdTram } from "react-icons/md";
import { BsFillMoonStarsFill } from "react-icons/bs";
import { IoMdSubway, IoIosBoat } from "react-icons/io";
import { PiWarningCircleFill } from "react-icons/pi";
import { ImCross } from "react-icons/im";

import './App.css';

// Import pour la jauge d'occupation
import 'react-circular-progressbar/dist/styles.css';

// Configuration de l’icône par défaut de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const REALTIME_REFRESH_INTERVAL = 8000;

const isColorDark = (color) => {
  if (!color) return false;
  const rgb = parseInt(color.slice(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma < 128;
};


function darkenColor(hex, factor = 0.90) {
  let c = hex.replace('#', '');
  if (c.length === 3) {
    c = c.split('').map(ch => ch + ch).join('');
  }
  let r = parseInt(c.substring(0, 2), 16);
  let g = parseInt(c.substring(2, 4), 16);
  let b = parseInt(c.substring(4, 6), 16);
  r = Math.floor(r * factor);
  g = Math.floor(g * factor);
  b = Math.floor(b * factor);
  const toHex = (n) => n.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}


// Fonction pour éclaircir une couleur
function lightenColor(hex, percent) {
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex.split('').map(x => x + x).join('');
  }
  const num = parseInt(hex, 16);
  let r = (num >> 16) + Math.round(2.55 * percent);
  let g = ((num >> 8) & 0x00FF) + Math.round(2.55 * percent);
  let b = (num & 0x0000FF) + Math.round(2.55 * percent);
  r = r > 255 ? 255 : r;
  g = g > 255 ? 255 : g;
  b = b > 255 ? 255 : b;
  return `#${(0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

// Définir une fonction pour choisir l'URL en fonction de l'heure
const getMapTileUrl = () => {
  const hour = new Date().getHours();
  if (hour >= 7 && hour < 19) {
    // Heure de jour
    return "/get_tile.php?tile_url=https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2VpYmVsY2xlbWVudDYwIiwiYSI6ImNtMm9yZ3JpaDA4OGQybHIxcTBibHk4NXQifQ.iUZ4I9uI1lIWgamjWnDIYg";
  } else {
    // Heure de nuit
    return "/get_tile.php?tile_url=https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2VpYmVsY2xlbWVudDYwIiwiYSI6ImNtMm9yZ3JpaDA4OGQybHIxcTBibHk4NXQifQ.iUZ4I9uI1lIWgamjWnDIYg";
  }
};


const getCurrentLocalDate = () => {
  const now = new Date();
  const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  const localTimeMs = now.getTime() - offsetMs;
  const localDate = new Date(localTimeMs);
  return localDate.toISOString().split('T')[0];
};

const isToday = (date) => date === new Date().toISOString().split('T')[0];

const convertTimestampToTime = (timestamp) => {
  const d = new Date(timestamp * 1000);
  const hh = String(d.getHours()).padStart(2, '0');
  const mm = String(d.getMinutes()).padStart(2, '0');
  return `${hh}:${mm}`;
};

const getSecondsRemaining = (epoch) => {
  const nowMs = Date.now();
  const arrMs = epoch * 1000;
  let diffSec = Math.round((arrMs - nowMs) / 1000);
  return diffSec > 0 ? diffSec : 0;
};

const getDynamicLabelPosition = (stop, map) => {
  if (!map) return 'above';
  const point = map.latLngToContainerPoint([parseFloat(stop.stop_lat), parseFloat(stop.stop_lon)]);
  const mapHeight = map.getSize().y;
  const threshold = 50;
  if (point.y < threshold) return 'below';
  if (point.y > mapHeight - threshold) return 'above';
  return 'above';
};

const createVehicleIcon = (
  color,
  _textColor,
  bearing,
  opacity = 1,
  vehicleType = 1,
  counter = null
) => {
  // On calcule une version assombrie de la couleur passée en paramètre.
  const darkColor = darkenColor(color, 0.90);
  // On définit la couleur du texte d'en-tête selon la luminosité de darkColor.
  const headerTextColor = isColorDark(darkColor) ? 'white' : 'black';
  // On définit également la couleur de base du texte pour l'icône.
  const textColor = isColorDark(color) ? 'white' : 'black';

  let vehicleIcon = null;

  switch (vehicleType) {
    case 2:
      vehicleIcon = <IoMdSubway style={{ color: headerTextColor , fontSize: '20px' }} />;
      break;
    case 3:
      vehicleIcon = <MdTram style={{ color: headerTextColor , fontSize: '20px' }} />;
      break;
    case 4:
      vehicleIcon = <IoIosBoat style={{ color: headerTextColor , fontSize: '20px' }} />;
      break;
    case 1:
    default:
      vehicleIcon = <FaBusSimple style={{ color: headerTextColor , fontSize: '20px' }} />;
      break;
  }

  const counterElement = counter !== null ? (
    <div
      style={{
        position: 'absolute',
        top: '-30px',
        right: '-25px',
        backgroundColor: darkColor,
        color: headerTextColor,
        padding: '2px 8px',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
       
        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        zIndex: 10
      }}
    >
      {counter === 0 ? '...' : `${counter}s`}
    </div>
  ) : null;

  const html = renderToStaticMarkup(
    <div style={{ position: 'relative', zIndex: 1000 }}>
      <div
        className="vehicle-icon"
        style={{
          backgroundColor: darkColor,
          transform: `rotate(${bearing}deg)`,
          opacity,
          transition: 'opacity 3s ease'
        }}
      >
        <div
          className="circle-background"
          style={{
            backgroundColor: darkColor,
            opacity: 0.3,
            position: 'absolute',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
        {vehicleIcon}
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: '15px solid transparent',
            borderRight: '15px solid transparent',
            borderBottom: `30px solid ${darkColor}`,
            position: 'absolute',
            top: '-15px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        />
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: `12px solid ${headerTextColor}`,
            position: 'absolute',
            top: '-8px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        />
      </div>
      {counterElement}
    </div>
  );

  return L.divIcon({
    className: '',
    html,
    iconSize: [35, 35]
  });
};


/* ------------------------------------------------------------------
   COMPOSANT : AnimatedVehicleMarker
------------------------------------------------------------------ */
function AnimatedVehicleMarker({
  veh,
  routeInfo,
  routeColor,
  selectedVehicleId,
  setSelectedVehicleId,
  vehicleMarkerRefs,
  vehiclesData,
  realTimeSchedules,
  organizedSchedules,
  createVehicleIcon,
  haversineDistanceMeters,
  formatDistance,
  getTimeRemainingText
}) {
  const { networkId } = useParams();
  const [currentPos, setCurrentPos] = useState({
    lat: veh.position.latitude,
    lng: veh.position.longitude
  });

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const start = Date.now();
    const duration = 1000;
    const oldLat = currentPos.lat;
    const oldLng = currentPos.lng;
    const newLat = veh.position.latitude;
    const newLng = veh.position.longitude;

    function animate() {
      const now = Date.now();
      const elapsed = now - start;
      if (elapsed < duration) {
        const fraction = elapsed / duration;
        const lat = oldLat + (newLat - oldLat) * fraction;
        const lng = oldLng + (newLng - oldLng) * fraction;
        setCurrentPos({ lat, lng });
        requestAnimationFrame(animate);
      } else {
        setCurrentPos({ lat: newLat, lng: newLng });
      }
    }
    requestAnimationFrame(animate);
  }, [veh.position.latitude, veh.position.longitude]);

  useEffect(() => {
    setCounter(0);
    const interval = setInterval(() => {
      setCounter(Math.floor((Date.now() - veh.timestamp * 1000) / 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, [veh.timestamp]);

  const nxtStop = organizedSchedules[veh.tripId]?.find(s => s.stop_id === veh.stop_id);
  const nxtName = nxtStop ? nxtStop.stop_name : '';
  const dist = nxtStop
    ? haversineDistanceMeters(
        [veh.position.latitude, veh.position.longitude],
        [parseFloat(nxtStop.stop_lat), parseFloat(nxtStop.stop_lon)]
      )
    : 0;
  const trip = organizedSchedules[veh.tripId];
  let termName = '';
  let termRT = null;

  if (trip && trip.length > 0) {
    const lastStopObj = trip[trip.length - 1];
    termName = lastStopObj.stop_name;
    const lastStopKey = ["TOHM", "CAP", "CLT_LB", "AIREMOB"].includes(networkId)
      ? lastStopObj.stop_sequence
      : lastStopObj.stop_id;
    const realTrip = realTimeSchedules[veh.tripId] || {};
    termRT = lastStopKey ? realTrip[lastStopKey] : null;
  }

  let terminusTimeRemaining = '';
  if (termRT?.epochTime) {
    terminusTimeRemaining = getTimeRemainingText(termRT.epochTime);
  }
  const vInfo = vehiclesData[veh.id];
  const vehicleType = vInfo?.type ?? 1;

   // À l'intérieur de ton composant Schedule, juste avant le return
   const darkRouteColor = darkenColor(routeColor, 0.90);



  useEffect(() => {
    if (vehicleMarkerRefs.current[veh.id]) {
      vehicleMarkerRefs.current[veh.id].setIcon(
        createVehicleIcon(
          routeColor,
          'white',
          veh.bearing,
          veh.opacity,
          vehicleType,
          counter
        )
      );
    }
  }, [counter, veh.bearing, veh.opacity, routeColor, vehicleType, veh.id, createVehicleIcon]);

  return (
    <Marker
      position={[currentPos.lat, currentPos.lng]}
      icon={createVehicleIcon(
        routeColor,
        'white',
        veh.bearing,
        veh.opacity,
        vehicleType,
        counter
      )}
      zIndexOffset={1000}
      ref={mk => {
        if (mk) vehicleMarkerRefs.current[veh.id] = mk;
      }}
      eventHandlers={{
        click: () => setSelectedVehicleId(veh.id),
        popupclose: () => {
          if (veh.id === selectedVehicleId) {
            setSelectedVehicleId(null);
          }
        }
      }}
    >
      <Popup maxWidth={200} minWidth={200} autoPan={false} interactive>
        <div style={{ position: 'relative', width: '100%', textAlign: 'center', padding: '10px' }}>
          <div
            style={{
              position: 'absolute',
              top: '-40px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontWeight: 'bold',
              color: 'black',
              backgroundColor: '#fff',
              padding: '5px 15px',
              borderRadius: '10px',
              boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
              fontSize: '16px',
              border: `3px solid ${darkRouteColor}`
            }}
          >
            {routeInfo && routeInfo.route_short_name}
            <div
              style={{
                content: '""',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '-10px',
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: `10px solid ${darkRouteColor}`
              }}
            />
          </div>

          {nxtName ? (
            <>
              <div
                style={{
                  marginBottom: '5px',
                  fontSize: '16px',
                  color: '#333',
                  lineHeight: '1.5',
                  marginTop: '0px'
                }}
              >
                <strong>Prochain arrêt :</strong> <br />
                <span style={{ color: '#444' }}>{nxtName.toUpperCase()}</span>
                <span
                  style={{
                    display: 'block',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#222',
                    marginTop: '2px'
                  }}
                >
                  {dist > 0 ? `à ${formatDistance(dist)}` : ''}
                </span>
              </div>
              <hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '10px 0' }} />
            </>
          ) : (
            <div style={{ color: '#666', textAlign: 'center', marginBottom: '10px', fontSize: '14px' }}>
              Ce véhicule ne dispose pas de donnée en temps réel pour le prochain arrêt
            </div>
          )}

          {termName && (
            <div style={{ fontSize: '14px', color: '#555', lineHeight: '1.5', marginBottom: '10px' }}>
              {(() => {
                if (!termRT?.epochTime) {
                  return <>Terminus : <b>{termName}</b></>;
                }
                const termSecRemaining = getSecondsRemaining(termRT.epochTime);
                if (termSecRemaining < 60) {
                  return (
                    <>
                      Arrivée à <br />
                      <b style={{ color: '#222', textTransform: 'uppercase' }}>{termName}</b>
                      <br />
                      <div className="imminent-text">Imminent</div>
                    </>
                  );
                } else {
                  return (
                    <>
                      Arrivée à <br />
                      <b style={{ color: '#222', textTransform: 'uppercase' }}>{termName}</b>
                      <br />
                      dans <b style={{ color: '#222', fontSize: '25px' }} >{terminusTimeRemaining.replace(/[()]/g, '')}</b>
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 20 20"
                        style={{ transform: 'rotate(130deg)', marginLeft: '5px', marginTop: '-5px' }}
                      >
                        <g fill="#FBAC2A">
                          <path
                            d="M15.9840916,8.88301685 C17.0973425,8.88301685 18,9.78539134 18,10.8988915 C18,12.0123916 17.0973425,12.9147661 15.9840916,12.9147661 C14.2915753,12.9147661 12.9149488,14.2916374 12.9149488,15.9838575 C12.9149488,17.0970897 12.0122913,18 10.8987725,18 C9.78552171,18 8.88286418,17.0970897 8.88286418,15.9838575 C8.88286418,12.0683881 12.0685567,8.88301685 15.9840916,8.88301685"
                            style={{ animation: '5000ms ease-in-out 3300ms infinite normal none running animation_197bdt9' }}
                          />
                          <path
                            d="M10.5402817,3.0998359 C12.2654855,2.37000569 14.0970578,2 15.9840916,2 C17.0973425,2.90264242 18,2.90264242 18,4.01614254 C18,5.12937473 17.0973425,6.03201715 15.9840916,6.03201715 C13.3256862,6.03201715 10.8264313,7.0672829 8.94689954,8.94678321 C7.06709982,10.8265515 6.03181674,13.3254965 6.03181674,15.9838575 C6.03181674,17.0970897 5.12942713,18 4.01590837,18 C2.90265753,18 2,17.0970897 2,15.9838575 C2,14.0971231 2.37001189,12.2653136 3.09985431,10.5401387 C3.80424335,8.87471114 4.81219753,7.37941659 6.0958521,6.09578352 C7.37950667,4.81215044 8.87482626,3.80421314 10.5402817,3.0998359 Z"
                            style={{ animation: '5000ms ease-in-out 3400ms infinite normal none running animation_197bdt9' }}
                          />
                        </g>
                      </svg>
                    </>
                  );
                }
              })()}
            </div>
          )}
          <hr style={{ border: 'none', borderTop: '1px solid #ccc', margin: '10px 0' }} />

          {vInfo && vInfo.vehicle_id ? (
            <div style={{ marginBottom: '-10px', color: '#333', lineHeight: '1.5' }}>
              <div style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '-5px', textTransform:'uppercase' }}>
                {vInfo.brand}
              </div>
              <div style={{ fontSize: '12px', color: '#555' }}>
                {vInfo.model}   
              </div>
              <div style={{ textAlign: 'right', fontSize: '8px', color: '#555' }}> ID : {veh.id} </div>
            </div>
          ) : (
            <div style={{ marginBottom: '-10px', fontStyle: 'italic', fontSize: '8px', color: '#474747'}}>
   Ce véhicule n'est pas dans la base de données. Vous souhaitez connaître sa marque et son modèle ? N'hésitez pas à contacter notre support afin que nous puissions l'ajouter.
            </div>
          )}
        </div>
      </Popup>
    </Marker>
  );
}

/* ------------------------------------------------------------------
   COMPOSANT PRINCIPAL : Schedule
------------------------------------------------------------------ */
function Schedule() {
  const { networkId, routeId, directionId } = useParams();
  const navigate = useNavigate();
  const mapRef = useRef(null);

  // STATES
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const vehicleMarkerRefs = useRef({});

  const [schedules, setSchedules] = useState([]);
  const [organizedSchedules, setOrganizedSchedules] = useState({});
  // On conserve tripIds dans un state si besoin dans d'autres effets
  const [tripIds, setTripIds] = useState([]);

  const [routeInfo, setRouteInfo] = useState(null);

  const [selectedDate, setSelectedDate] = useState(getCurrentLocalDate());
  const [selectedTime, setSelectedTime] = useState(new Date().toTimeString().split(' ')[0].substring(0, 5));
  const [currentTripIndex, setCurrentTripIndex] = useState(0);
  const [showMap, setShowMap] = useState(false);

  const [stops, setStops] = useState([]);
  const [shapePoints, setShapePoints] = useState([]);

  const [routeColor, setRouteColor] = useState('#000000');

  const [directions, setDirections] = useState([]);

  const [realTimeVehicles, setRealTimeVehicles] = useState([]);
  const [realTimeSchedules, setRealTimeSchedules] = useState({});

  const [shouldCenterMap, setShouldCenterMap] = useState(true);
  const [displayText, setDisplayText] = useState("géolocalisation");
  const [loadingRouteInfo, setLoadingRouteInfo] = useState(true);
  const [loadingShape, setLoadingShape] = useState(true);
  const [loadingSchedules, setLoadingSchedules] = useState(true);

  // Gestion favoris
  const [favorites, setFavorites] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);

  // Toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const [vehiclesData, setVehiclesData] = useState({});

  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);

  const [autoUpdateTripIndex, setAutoUpdateTripIndex] = useState(true);

  // Nouvel état pour la jauge d'occupation
  const [occupancy, setOccupancy] = useState(0);

  // États pour la modale de vote d'affluence
  const [showOccupancyModal, setShowOccupancyModal] = useState(false);
  const [selectedVote, setSelectedVote] = useState(null);

  const intervalRef = useRef(null);
  const abortControllerVehiclesRef = useRef(null);
  const abortControllerSchedulesRef = useRef(null);

  // ======= AJOUT POUR ALTERNANCE DU TEXTE DU VEHICULE =======
  const [showVehicleInfo, setShowVehicleInfo] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowVehicleInfo(prev => !prev);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // --- Ajout de l'optimisation ---
  // Calcul des tripIds triés à partir de organizedSchedules
  const sortedTripIds = useMemo(() => {
    const org = { ...organizedSchedules };
    Object.keys(org).forEach(tid => {
      org[tid].sort((a, b) => a.arrival_time.localeCompare(b.arrival_time));
    });
    return Object.keys(org).sort((a, b) => {
      const firstA = org[a][0]?.arrival_time || '';
      const firstB = org[b][0]?.arrival_time || '';
      return firstA.localeCompare(firstB);
    });
  }, [organizedSchedules]);

  // Fonctions de navigation optimisées
  const handleNextTrip = useCallback(() => {
    setAutoUpdateTripIndex(false);
    setCurrentTripIndex(prev => Math.min(prev + 1, sortedTripIds.length - 1));
  }, [sortedTripIds.length]);

  const handlePrevTrip = useCallback(() => {
    setAutoUpdateTripIndex(false);
    setCurrentTripIndex(prev => Math.max(prev - 1, 0));
  }, []);

  // --- Fin de l'ajout ---

  // Génération du token utilisateur
  const generateToken = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  };
  const getUserToken = () => {
    let token = localStorage.getItem('userToken');
    if (!token) {
      token = generateToken();
      localStorage.setItem('userToken', token);
    }
    return token;
  };
  const userToken = getUserToken();

  // Récupération des favoris
  const fetchFavorites = async () => {
    try {
      const resp = await axios.get(`/getFavorites.php?token=${encodeURIComponent(userToken)}`, { timeout: 10000 });
      if (Array.isArray(resp.data)) {
        setFavorites(resp.data);
      } else {
        setFavorites([]);
      }
    } catch (err) {
      console.error("Erreur lors de la récupération des favoris:", err);
      setFavorites([]);
    }
  };

  // Ajout d'un favori
  const addFavoriteDB = async () => {
    if (!routeInfo) return;
    const newFav = {
      network_id: networkId,
      route_id: routeId,
      route_short_name: routeInfo.route_short_name,
      route_long_name: routeInfo.route_long_name,
      route_color: routeInfo.route_color
    };
    try {
      await axios.post('/addFavorite.php', { token: userToken, favorite: newFav }, { timeout: 10000 });
      setFavorites(prev => [...prev, newFav]);
      setIsFavorite(true);
      setToastMessage(
        <>La ligne <strong>{routeInfo.route_short_name}</strong> a bien été ajoutée à vos favoris</>
      );
      setShowToast(true);
    } catch (err) {
      console.error("Erreur lors de l'ajout du favori:", err);
    }
  };

  // Suppression d'un favori
  const removeFavoriteDB = async () => {
    try {
      await axios.post('/removeFavorite.php', { token: userToken, network_id: networkId, route_id: routeId }, { timeout: 10000 });
      setFavorites(prev => prev.filter(f => !(f.network_id === networkId && f.route_id === routeId)));
      setIsFavorite(false);
      setToastMessage(
        <>La ligne <strong>{routeInfo?.route_short_name}</strong> a bien été retirée de vos favoris</>
      );
      setShowToast(true);
    } catch (err) {
      console.error("Erreur lors de la suppression du favori:", err);
    }
  };

  const toggleFavorite = () => {
    if (isFavorite) removeFavoriteDB();
    else addFavoriteDB();
  };

  useEffect(() => {
    fetchFavorites();
  }, [networkId, routeId]);

  useEffect(() => {
    if (favorites.length > 0) {
      const isFav = favorites.some(
        (f) => f.network_id === networkId && f.route_id === routeId
      );
      setIsFavorite(isFav);
    } else {
      setIsFavorite(false);
    }
  }, [favorites, networkId, routeId]);

  useEffect(() => {
    const texts = ["géolocalisation", "plan intéractif"];
    let idx = 0;
    const itv = setInterval(() => {
      idx = (idx + 1) % texts.length;
      setDisplayText(texts[idx]);
    }, 5000);
    return () => clearInterval(itv);
  }, []);

  useEffect(() => {
    fetchDirections();
    fetchSchedules(selectedDate, selectedTime);
    fetchRouteInfo();
    fetchStops();
    return () => {
      if (abortControllerVehiclesRef.current) {
        abortControllerVehiclesRef.current.abort();
      }
      if (abortControllerSchedulesRef.current) {
        abortControllerSchedulesRef.current.abort();
      }
    };
  }, [networkId, routeId, directionId, selectedDate, selectedTime]);

  useEffect(() => {
    if (tripIds.length > 0) {
      fetchShapePoints(tripIds[currentTripIndex]);
    }
  }, [currentTripIndex, tripIds]);

  const fetchManualCancellations = async () => {
    try {
      const resp = await axios.get(
        `/manageCancellations.php?date=${selectedDate}&route_id=${routeId}&direction_id=${directionId}`
      );
      if (resp.data && Array.isArray(resp.data)) {
        const updated = { ...organizedSchedules };
        resp.data.forEach(item => {
          const tid = item.trip_id;
          const canceled = !!item.canceled;
          const reason = item.canceled_reason || '';
          if (updated[tid]) {
            updated[tid].forEach(stop => {
              stop.canceled = canceled;
              stop.canceled_reason = canceled ? reason : '';
            });
          }
        });
        setOrganizedSchedules(updated);
      }
    } catch (err) {
      console.error("Erreur fetchManualCancellations:", err);
    }
  };

  // Mise à jour du temps réel
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (isToday(selectedDate)) {
      fetchRealTimeVehicles();
      fetchRealTimeSchedules();
      fetchManualCancellations();
      intervalRef.current = setInterval(() => {
        fetchRealTimeVehicles();
        fetchRealTimeSchedules();
        fetchManualCancellations();
      }, REALTIME_REFRESH_INTERVAL);
    } else {
      setRealTimeVehicles([]);
      setRealTimeSchedules({});
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [routeInfo, directionId, selectedDate]);

  useEffect(() => {
    if (stops.length > 0) {
      fetchCities();
    }
  }, [stops]);

  useEffect(() => {
    const fetchDataForVehicles = async () => {
      const newData = { ...vehiclesData };
      let changed = false;
      const MAX_VEHICLES_FETCH = 10;
      const vehiclesToFetch = realTimeVehicles.slice(0, MAX_VEHICLES_FETCH);
      for (const v of vehiclesToFetch) {
        if (!newData[v.id]) {
          try {
            const r = await axios.get(
              `/getVehicleInfo.php?vehicle_id=${encodeURIComponent(v.id)}&agence=${encodeURIComponent(networkId)}`
            );
            newData[v.id] = r.data;
            changed = true;
          } catch (err) {
            console.error("Erreur infos véhicule:", err);
          }
        }
      }
      if (changed) {
        setVehiclesData(newData);
      }
    };
    if (realTimeVehicles.length > 0) {
      fetchDataForVehicles();
    }
  }, [realTimeVehicles, networkId]);

  useEffect(() => {
    if (selectedVehicleId && vehicleMarkerRefs.current[selectedVehicleId]) {
      vehicleMarkerRefs.current[selectedVehicleId].openPopup();
    }
  }, [selectedVehicleId]);

  const fetchRouteInfo = () => {
    const controller = new AbortController();
    abortControllerVehiclesRef.current = controller;
    axios
      .get(`/getBusLines.php?route_info=1&route_id=${routeId}&network_id=${networkId}`, {
        signal: controller.signal
      })
      .then((resp) => {
        if (resp.data) {
          setRouteInfo(resp.data);
          setRouteColor(`#${resp.data.route_color}`);
        }
        setLoadingRouteInfo(false);
      })
      .catch(() => {
        setLoadingRouteInfo(false);
      });
  };

  const fetchDirections = () => {
    axios
      .get(`/getDirections.php?route_id=${routeId}&network_id=${networkId}`)
      .then(resp => {
        setDirections(resp.data);
      })
      .catch(() => {});
  };

  const fetchSchedules = (date, time) => {
    setLoadingSchedules(true);
    const controller = new AbortController();
    abortControllerSchedulesRef.current = controller;
    axios
      .get(`/getSchedules.php?route_id=${routeId}&direction_id=${directionId}&date=${date}&network_id=${networkId}`, {
        signal: controller.signal
      })
      .then(resp => {
        const org = {};
        resp.data.forEach(sch => {
          if (!org[sch.trip_id]) {
            org[sch.trip_id] = [];
          }
          sch.stop_sequence = parseInt(sch.stop_sequence, 10);
          org[sch.trip_id].push(sch);
        });
        if (resp.data.length > 0) {
          Object.keys(org).forEach(tid => {
            if (["TOHM", "CAP", "CLT_LB", "AIREMOB"].includes(networkId))
              org[tid].sort((a, b) => a.stop_sequence - b.stop_sequence);
            else
              org[tid].sort((a, b) => a.arrival_time.localeCompare(b.arrival_time));
          });
        }
        setSchedules(resp.data);
        setOrganizedSchedules(org);
        const sortedTripIds = Object.keys(org).sort((a, b) => {
          const firstA = org[a][0]?.arrival_time || '';
          const firstB = org[b][0]?.arrival_time || '';
          return firstA.localeCompare(firstB);
        });
        setTripIds(sortedTripIds);
        const [hCur, mCur] = time.split(':').map(Number);
        const nowMin = hCur * 60 + mCur;
        let initIndex = sortedTripIds.findIndex(tid => {
          return org[tid].some(s => {
            const [hh, mm] = s.arrival_time.split(':').map(Number);
            return (hh * 60 + mm) >= nowMin;
          });
        });
        if (initIndex === -1) {
          initIndex = sortedTripIds.length - 1;
        }
        setCurrentTripIndex(initIndex);
        setLoadingSchedules(false);
      })
      .catch(() => {
        setLoadingSchedules(false);
      });
  };

  const fetchStops = () => {
    axios
      .get(`/getStops.php?route_id=${routeId}&direction_id=${directionId}&network_id=${networkId}`)
      .then(resp => {
        setStops(resp.data);
      })
      .catch(() => {});
  };

  const fetchShapePoints = (tripId) => {
    setLoadingShape(true);
    axios
      .get(`/getShapes.php?trip_id=${tripId}&network_id=${networkId}`)
      .then(resp => {
        const sorted = resp.data
          .map(p => [parseFloat(p.shape_pt_lat), parseFloat(p.shape_pt_lon), p.shape_pt_sequence])
          .sort((a, b) => a[2] - b[2])
          .map(x => [x[0], x[1]]);
        setShapePoints(sorted);
        if (resp.data.length > 0 && resp.data[0]?.route_color) {
          setRouteColor(`#${resp.data[0].route_color}`);
        }
        setLoadingShape(false);
      })
      .catch(() => {
        setLoadingShape(false);
      });
  };

  const fetchRealTimeVehicles = async () => {
    try {
      const controller = new AbortController();
      abortControllerVehiclesRef.current = controller;
      const agencyResp = await axios.get(`/getAgencyVehicule.php?network_id=${networkId}`, {
        signal: controller.signal
      });
      const apiUrl = agencyResp.data.gtfs_vehicule;
      const root = await protobuf.load('/protos/gtfs-realtime.proto');
      const FeedMessage = root.lookupType('transit_realtime.FeedMessage');
  
      const binResp = await axios.get(apiUrl, {
        responseType: 'arraybuffer',
        signal: controller.signal
      });
  
      // Vérifier si le flux est vide
      if (!binResp.data || binResp.data.byteLength === 0) {
        console.log("Pas de données GTFS realtime à cette heure.");
        setRealTimeVehicles([]);
        return;
      }
  
      // Convertir en Uint8Array et décoder
      const uint8Array = new Uint8Array(binResp.data);
      // Vérifier que le flux contient un header avant de décoder
      // On essaie de décoder et on gère l'erreur potentielle
      let feed;
      try {
        feed = FeedMessage.decode(uint8Array);
        if (!feed.header) {
          throw new Error("Flux invalide : header manquant");
        }
      } catch (err) {
        console.log("Flux GTFS realtime invalide ou vide :", err.message);
        setRealTimeVehicles([]);
        return;
      }
  
      const nowSec = Math.floor(Date.now() / 1000);
      const filtered = feed.entity
        .filter(ent =>
          ent.vehicle &&
          ent.vehicle.trip &&
          ent.vehicle.trip.routeId === routeId &&
          ent.vehicle.trip.directionId === parseInt(directionId, 10)
        )
        .map(ent => {
          const v = ent.vehicle;
          const ts = parseInt(v.timestamp, 10);
          const diffSec = nowSec - ts;
          if (diffSec > 100) {
            return null;
          }
          let opacity = 1.0;
          if (diffSec >= 50 && diffSec <= 100) {
            opacity = 0.9;
          }
          return {
            id: v.vehicle.id,
            tripId: v.trip.tripId,
            position: v.position,
            bearing: v.position.bearing,
            routeId: v.trip.routeId,
            routeColor: routeInfo ? `#${routeInfo.route_color}` : '#000',
            stop_id: v.stopId,
            opacity,
            timestamp: ts
          };
        })
        .filter(Boolean);
      setRealTimeVehicles(filtered);
    } catch (err) {
      console.error("Erreur fetchRealTimeVehicles:", err);
    }
  };
  
  const fetchRealTimeSchedules = async () => {
    try {
      const controller = new AbortController();
      abortControllerSchedulesRef.current = controller;
      const infoResp = await axios.get(`/getAgencyInfo.php?network_id=${networkId}`, {
        signal: controller.signal
      });
      if (!infoResp.data || !infoResp.data.gtfs_horaires) {
        console.log("Pas de flux RT horaires => on stop.");
        return;
      }
      const apiUrl = infoResp.data.gtfs_horaires;
      const root = await protobuf.load('/protos/gtfs-realtime.proto');
      const FeedMessage = root.lookupType('transit_realtime.FeedMessage');
      const binResp = await axios.get(apiUrl, {
        responseType: 'arraybuffer',
        signal: controller.signal
      });
      const feed = FeedMessage.decode(new Uint8Array(binResp.data));
      const newRT = {};
      feed.entity.forEach(ent => {
        if (ent.tripUpdate && ent.tripUpdate.trip) {
          const tId = ent.tripUpdate.trip.tripId;
          const rel = ent.tripUpdate.trip.scheduleRelationship;
          const isCanceled = (rel === 3 || rel === 'CANCELED');
          if (!newRT[tId]) {
            newRT[tId] = {};
          }
          if (isCanceled) {
            newRT[tId].canceled = true;
          }
          if (ent.tripUpdate.stopTimeUpdate) {
            ent.tripUpdate.stopTimeUpdate.forEach((update) => {
              if (["TOHM", "CAP", "CLT_LB", "AIREMOB"].includes(networkId)) {
                const stSeq = update.stopSequence;
                if (!newRT[tId][stSeq]) {
                  newRT[tId][stSeq] = {};
                }
                const schedRel = update.schedule_relationship || update.scheduleRelationship || '';
                const isSkipped = (schedRel === 'SKIPPED' || schedRel === 1);
                if (isSkipped) {
                  newRT[tId][stSeq].skipped = true;
                }
                const time = update.departure?.time || update.arrival?.time;
                if (time) {
                  const epochTime = parseInt(time, 10);
                  const formTime = convertTimestampToTime(epochTime);
                  const delay = update.arrival?.delay ?? update.departure?.delay;
                  let delayInfo = "";
                  if (typeof delay === 'number') {
                    const delayMin = Math.round(delay / 60);
                    if (delayMin > 0) {
                      delayInfo = `Retardé de ${delayMin} min`;
                    } else if (delayMin < 0) {
                      delayInfo = `En avance de ${Math.abs(delayMin)} min`;
                    } else {
                      delayInfo = `À l'heure`;
                    }
                  }
                  newRT[tId][stSeq] = {
                    ...newRT[tId][stSeq],
                    epochTime,
                    time: formTime,
                    delayInfo
                  };
                }
              } else {
                const stopId = update.stopId;
                if (!newRT[tId][stopId]) {
                  newRT[tId][stopId] = {};
                }
                const schedRel = update.schedule_relationship || update.scheduleRelationship || '';
                const isSkipped = (schedRel === 'SKIPPED' || schedRel === 1);
                if (isSkipped) {
                  newRT[tId][stopId].skipped = true;
                }
                const time = update.departure?.time || update.arrival?.time;
                if (time) {
                  const epochTime = parseInt(time, 10);
                  const formTime = convertTimestampToTime(epochTime);
                  const delay = update.arrival?.delay ?? update.departure?.delay;
                  let delayInfo = "";
                  if (typeof delay === 'number') {
                    const delayMin = Math.round(delay / 60);
                    if (delayMin > 0) {
                      delayInfo = `Retardé de ${delayMin} min`;
                    } else if (delayMin < 0) {
                      delayInfo = `En avance de ${Math.abs(delayMin)} min`;
                    } else {
                      delayInfo = `À l'heure`;
                    }
                  }
                  newRT[tId][stopId] = {
                    ...newRT[tId][stopId],
                    epochTime,
                    time: formTime,
                    delayInfo
                  };
                }
              }
            });
          }
        }
      });
      setRealTimeSchedules(newRT);
      if (tripIds.length > 0 && Object.keys(organizedSchedules).length > 0 && autoUpdateTripIndex) {
        const nowMs = Date.now();
        let newIndex = null;
        for (let i = 0; i < tripIds.length; i++) {
          const tid = tripIds[i];
          const trip = organizedSchedules[tid];
          const tripRT = newRT[tid] || {};
          const isOngoing = trip.some(stop => {
            const key = (["TOHM", "CAP", "CLT_LB", "AIREMOB"].includes(networkId))
              ? stop.stop_sequence
              : stop.stop_id;
            const rtInfo = tripRT[key];
            if (rtInfo && !rtInfo.skipped) {
              if (rtInfo.epochTime && (rtInfo.epochTime * 1000) >= nowMs) return true;
              const [hh, mm] = stop.arrival_time.split(':').map(Number);
              const tMs = new Date().setHours(hh, mm, 0, 0);
              return tMs >= nowMs;
            } else {
              const [hh, mm] = stop.arrival_time.split(':').map(Number);
              const tMs = new Date().setHours(hh, mm, 0, 0);
              return tMs >= nowMs;
            }
          });
          if (isOngoing) {
            newIndex = i;
            break;
          }
        }
        if (newIndex !== null && newIndex !== currentTripIndex) {
          setCurrentTripIndex(newIndex);
        }
      }
    } catch (err) {
      console.error("Erreur fetchRealTimeSchedules:", err);
    }
  };

  const MAPBOX_TOKEN = 'pk.eyJ1Ijoid2NsZW1lbnQ2MCIsImEiOiJjbDEyOXRlaHEyZzkyM2JrYjJnN3ZrY2JrIn0.JrVxUKnkgR1vx8FWsNp9tQ';

  const fetchCities = async () => {
    setLoadingCities(true);
    const coords = stops.map(st => ({ lat: st.stop_lat, lon: st.stop_lon }));
    const tokens = coords.map(c => `${c.lat}_${c.lon}`);
    let dbCities = {};
    try {
      const { data } = await axios.get(`/getCity.php?tokens=${encodeURIComponent(tokens.join(','))}`);
      dbCities = data;
    } catch (err) {
      console.error("Erreur lors du bulk getCity:", err);
    }
    const cityList = [];
    for (const c of coords) {
      const token = `${c.lat}_${c.lon}`;
      let cityData = dbCities[token];
      if (cityData && cityData.city_name) {
        cityList.push(cityData);
      } else {
        try {
          const mapResp = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${c.lon},${c.lat}.json`,
            { params: { access_token: MAPBOX_TOKEN, language: 'fr' } }
          );
          let cityName = '';
          if (mapResp.data && mapResp.data.features && mapResp.data.features.length > 0) {
            const feat = mapResp.data.features[0];
            const ctx = feat.context || [];
            const cPlace = ctx.find(x => x.id.includes('place') || x.id.includes('locality'));
            if (cPlace && cPlace.text) {
              cityName = cPlace.text;
            } else if (feat.text) {
              cityName = feat.text;
            }
          }
          cityData = { latitude: c.lat, longitude: c.lon, city_name: cityName, token: token };
          await axios.post('/storeCity.php', cityData, {
            headers: { 'Content-Type': 'application/json' }
          });
          cityList.push(cityData);
        } catch (err) {
          console.error("Erreur lors de l'appel Mapbox pour token", token, err);
          cityList.push({ latitude: c.lat, longitude: c.lon, city_name: '' });
        }
      }
    }
    setCities(cityList);
    setLoadingCities(false);
  };

  const toRad = x => (x * Math.PI) / 180;
  const haversineDistanceMeters = (c1, c2) => {
    const [lat1, lon1] = c1;
    const [lat2, lon2] = c2;
    const R = 6371000;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) ** 2;
    return Math.round(R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
  };

  const findClosestCity = (lat, lon) => {
    if (loadingCities) {
      return (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={5} />
        </Placeholder>
      );
    }
    if (!Array.isArray(cities) || cities.length === 0) return '';
    let minDist = Infinity;
    let cName = '';
    cities.forEach(ct => {
      const d = haversineDistanceMeters(
        [lat, lon],
        [parseFloat(ct.latitude), parseFloat(ct.longitude)]
      );
      if (d < minDist) {
        minDist = d;
        cName = ct.city_name;
      }
    });
    return cName;
  };

  const formatArrivalTime = (time) => {
    let [hh, mm] = time.split(':');
    hh = parseInt(hh, 10) % 24;
    return `${String(hh).padStart(2, '0')}:${mm}`;
  };

  const formatDistance = (m) => {
    if (m < 1000) {
      return `${m} mètres`;
    }
    const km = m / 1000;
    const rkm = Math.floor(km * 10) / 10;
    let str = String(rkm).replace('.', ',');
    if (str.endsWith(',0')) {
      str = str.slice(0, -2);
    }
    return `${str} km`;
  };

  const getTimeRemainingText = (epoch) => {
    const nowMs = Date.now();
    const arrMs = epoch * 1000;
    let diffMin = Math.round((arrMs - nowMs) / 60000);
    if (diffMin < 0) diffMin = 0;
    return `(${diffMin} min)`;
  };

  useEffect(() => {
    if (showMap && mapRef.current && shouldCenterMap) {
      const map = mapRef.current;
      if (shapePoints.length > 0) {
        map.fitBounds(shapePoints);
      } else if (stops.length > 0) {
        const stB = stops.map(s => [s.stop_lat, s.stop_lon]);
        map.fitBounds(stB);
      }
      setShouldCenterMap(false);
    }
  }, [showMap, shapePoints, stops, shouldCenterMap]);

  const MapBoundsSetter = () => {
    const map = useMap();
    useEffect(() => {
      if (shapePoints.length > 0 && shouldCenterMap) {
        map.fitBounds(shapePoints);
        setShouldCenterMap(false);
      } else if (stops.length > 0 && shouldCenterMap) {
        const sb = stops.map(st => [st.stop_lat, st.stop_lon]);
        map.fitBounds(sb);
        setShouldCenterMap(false);
      }
    }, [map]);
    return null;
  };

  // CALCUL DE currentTripId et currentTrip
  const currentTripId = tripIds[currentTripIndex];
  const currentTrip = organizedSchedules[currentTripId] || [];

  // Ici, on récupère le véhicule correspondant au trip courant
  const tripVehicle = realTimeVehicles.find(v => v.tripId === currentTripId);
  const tripVehicleInfo = tripVehicle && vehiclesData[tripVehicle.id] ? vehiclesData[tripVehicle.id] : null;

  // Définition de tripNotStarted
  const tripNotStarted =
    currentTrip.length > 0 &&
    (selectedDate !== getCurrentLocalDate() ||
      (() => {
        const now = new Date();
        const [hh, mm] = currentTrip[0].arrival_time.split(':');
        const tripStartTime = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          parseInt(hh, 10),
          parseInt(mm, 10)
        );
        return now < tripStartTime;
      })());

  useEffect(() => {
    if (currentTripId) {
      const intervalId = setInterval(() => {
        axios.get(`/getOccupency.php?network_id=${networkId}&trip_number=${currentTripId}`)
          .then(resp => {
            if (resp.data && typeof resp.data.occupancy === 'number') {
              setOccupancy(resp.data.occupancy);
            }
          })
          .catch(err => console.error("Erreur lors du rafraîchissement de l'occupation:", err));
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [currentTripId, networkId]);

  const now = new Date();
  const graceMs = 60 * 1000;
  const lastStop = currentTrip.length > 0 ? currentTrip[currentTrip.length - 1] : null;
  const lastStopTime = lastStop
    ? new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        ...lastStop.arrival_time.split(':').map(Number)
      )
    : null;
  const tripEnded = lastStopTime && isToday(selectedDate) && now.getTime() > (lastStopTime.getTime() + graceMs);

  const tripRealTimeInfo = realTimeSchedules[currentTripId] || {};
  const realTimeTripCanceled = tripRealTimeInfo.canceled === true;
  const anyManualCanceled = currentTrip.some(st => st.canceled === true);
  let canceledReason = '';
  const canceledStop = currentTrip.find(st => st.canceled_reason && st.canceled_reason.trim() !== '');
  if (canceledStop) {
    canceledReason = canceledStop.canceled_reason;
  }
  const isTripCanceled = realTimeTripCanceled || anyManualCanceled;
  const hasRealTimeForTrip = isToday(selectedDate) && (realTimeSchedules[currentTripId] !== undefined);
  const nowSec = Math.floor(Date.now() / 1000);
  const isTripConnected = isToday(selectedDate) && realTimeVehicles.some(v => v.tripId === currentTripId);
  const isGpsNotConnected = hasRealTimeForTrip && !isTripConnected;
  const activeVehiclesCount = realTimeVehicles.filter(v => nowSec - v.timestamp < 120).length;

  useEffect(() => {
    if (currentTripId) {
      axios.get(`/getOccupency.php?network_id=${networkId}&trip_number=${currentTripId}`)
        .then(resp => {
          if (resp.data && typeof resp.data.occupancy === 'number') {
            setOccupancy(resp.data.occupancy);
          }
        })
        .catch(err => console.error("Erreur lors de la récupération de l'occupation:", err));
    }
  }, [currentTripId, networkId]);

  const handleVoteSubmit = () => {
    if (!selectedVote) return;
    axios.post('/submitOccupency.php', {
      network_id: networkId,
      trip_number: currentTripId,
      vote: selectedVote
    })
      .then(resp => {
        if (resp.data.success) {
          setOccupancy(resp.data.occupancy);
          setShowOccupancyModal(false);
          setSelectedVote(null);
        }
      })
      .catch(err => console.error("Erreur lors de la soumission du vote :", err));
  };

  const handleShowOccupancyModal = () => setShowOccupancyModal(true);
  const handleCloseOccupancyModal = () => {
    setShowOccupancyModal(false);
    setSelectedVote(null);
  };

  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleTimeChange = (e) => setSelectedTime(e.target.value);
  // On utilise désormais nos fonctions optimisées pour la navigation entre trips :
  // const handleNextTrip et handlePrevTrip sont définies via useCallback plus haut.
  const handleShowMap = () => {
    setShowMap(true);
    setShouldCenterMap(true);
  };
  const handleCloseMap = () => setShowMap(false);
  const handleDirectionChange = (e) => {
    setAutoUpdateTripIndex(false);
    const newDirId = e.target.value;
    navigate(`/schedule/${networkId}/${routeId}/${newDirId}`);
  };

  // À l'intérieur de ton composant Schedule, juste avant le return
const darkRouteColor = darkenColor(routeColor, 0.90);
const headerTextColor = isColorDark(darkRouteColor) ? 'white' : 'black';
const isSingleDirection = directions.length === 1;

  return (
    <>
      <Helmet>
        <title>{`Bus Connect – Réseau : ${networkId} – Ligne ${routeInfo?.route_short_name}`}</title>
      </Helmet>

      <Modal show={showOccupancyModal} onHide={handleCloseOccupancyModal} centered>
        <Modal.Header 
          closeButton
          style={{ 
            background: 'linear-gradient(90deg, #00b5ff, #00bfff, #01afc9)', 
            color: 'white', 
            borderBottom: 'none',
            padding: '8px 16px'
          }}
        >
          <Modal.Title style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '14px' }}>
            Donner l'affluence du véhicule
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center', padding: '12px' }}>
          <p style={{ fontSize: '13px', marginBottom: '10px' }}>
            Vous êtes dans le véhicule ? <br /> Quel est le niveau d'affluence ?
          </p>
          <div className="d-flex justify-content-around">
            <Button 
              variant={selectedVote === 1 ? "success" : "outline-secondary"} 
              onClick={() => setSelectedVote(1)}
              style={{ width: '70px', fontSize: '12px', fontWeight: 'bold' }}
            >
              Faible
            </Button>
            <Button 
              variant={selectedVote === 2 ? "warning" : "outline-secondary"} 
              onClick={() => setSelectedVote(2)}
              style={{ width: '70px', fontSize: '12px', fontWeight: 'bold' }}
            >
              Modéré
            </Button>
            <Button 
              variant={selectedVote === 3 ? "danger" : "outline-secondary"} 
              onClick={() => setSelectedVote(3)}
              style={{ width: '70px', fontSize: '12px', fontWeight: 'bold' }}
            >
              Forte
            </Button>
          </div>
          <p style={{ fontSize: '11px', color: '#777', marginTop: '10px' }}>
            Note : Votez sérieusement pour aider tous les passagers.
          </p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center', borderTop: 'none', padding: '8px 16px' }}>
          <Button 
            variant="secondary" 
            onClick={handleCloseOccupancyModal}
            style={{ fontSize: '12px', padding: '4px 10px', textTransform: 'uppercase', fontWeight: 'bold' }}
          >
            Annuler
          </Button>
          <Button 
            variant="primary" 
            onClick={handleVoteSubmit} 
            disabled={!selectedVote}
            style={{ fontSize: '12px', padding: '4px 10px', textTransform: 'uppercase', fontWeight: 'bold' }}
          >
            Envoyer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* BARRE DU HAUT */}
      <div
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 998,
          backgroundColor: 'white',
          marginBottom: '20px',
          paddingBottom: '2px',
          paddingTop: '20px',
          paddingLeft: '5px',
          paddingRight: '5px',
          boxShadow: `${darkRouteColor}33 0px 10px 10px`
        }}
      >
        {loadingRouteInfo ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '100%',
              fontSize: '3.0em',
              height: '60px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-25px',
                left: '10px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            >
              <TbArrowBackUp />
            </div>
            <div
              style={{
                backgroundColor: '#ddd',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px',
                textAlign: 'center',
                minWidth: '85px',
                overflow: 'hidden',
                position: 'relative',
                padding: '5px 10px',
                textTransform: 'uppercase',
                borderRadius: '7px',
                fontWeight: 'bold',
                fontSize: '14px',
                marginRight: '5px',
                lineHeight: '1.2',
              }}
            >
              <Placeholder animation="glow" style={{
                width: '80px',
                height: '30px',
                minWidth: '85px',
                display: 'block',
                borderRadius: '5px',
              }} />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '100%',
              fontSize: '3.0em',
              height: '60px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-25px',
                left: '10px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            >
              <TbArrowBackUp />
            </div>
            <div
              style={{
                backgroundColor: darkRouteColor,
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '20px',
                textAlign: 'center',
                minWidth: '85px',
                overflow: 'hidden',
                position: 'relative',
                padding: '5px 10px',
                textTransform: 'uppercase',
                borderRadius: '7px',
                fontWeight: 'bold',
                fontSize: '14px',
                marginRight: '5px',
                lineHeight: '1.2',
              }}
            >
               <b style={{ fontSize: '1.7em', color: headerTextColor }} className="line-text">
                {routeInfo.route_short_name}
              </b>
            </div>
          </div>
        )}
        
        <Container>
        <Row className="mb-4">
  <Col xs={12} sm={8} md={6} lg={4}>
    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
      <span
        style={{
          position: 'absolute',
          left: '5px',
          top: '44%',
          transform: 'translateY(-50%)',
          pointerEvents: 'none',
          fontWeight: 'bold',
          color: darkRouteColor,
          fontSize: '30px'
        }}
      >
        <TbSquareRoundedArrowRightFilled className="arrow-loop-animations" />
      </span>
      <select
        value={directionId}
        onChange={handleDirectionChange}
        disabled={isSingleDirection}
        style={{
          width: '100%',
          paddingLeft: '50px',
          paddingRight: '50px',
          height: '50px',
          fontWeight: 'bold',
          border: '3px solid transparent',
          borderRadius: '20px',
          background: `
            linear-gradient(#fff, #fff) padding-box,
            linear-gradient(90deg, #00b5ff, #00bfff, #01afc9) border-box
          `,
          backgroundSize: '100% 100%, 200% 200%',
          backgroundPosition: '0% 0%, 0% 0%',
          animation: 'scrollGradient 3s linear infinite',
          appearance: 'none',
          color: '#333',
          fontSize: '12px',
          outline: 'none',
          cursor: 'pointer'
        }}
        className="text-truncate"
      >
        {directions.map((dir, i) => (
          <option key={i} value={dir.direction_id} className="text-truncate">
            {dir.headsign}
          </option>
        ))}
      </select>
      {!isSingleDirection && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '5px',
            transform: 'translateY(-50%)',
            border: '2px solid white',
            borderRadius: '50%',
            padding: '5px',
            backgroundColor: 'transparent'
          }}
        >
          <MdChangeCircle className="rotating-icon" style={{ color: 'black', fontSize: '25px' }} />
        </div>
      )}
    </div>
  </Col>
</Row>
          <Form className="mb-4">
            <Row>
              <Col>
                <FormControl
                  type="date"
                  value={selectedDate}
                  min={getCurrentLocalDate()}
                  onChange={handleDateChange}
                />
              </Col>
              <Col>
                <FormControl
                  type="time"
                  value={selectedTime}
                  onChange={handleTimeChange}
                />
              </Col>
            </Row>
          </Form>
          {loadingSchedules ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <Spinner animation="border" style={{ width: '2rem', height: '2rem', color: '#0A78A4' }} />
            </div>
          ) : (
            <>
              {tripIds.length === 0 ? (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <BsFillMoonStarsFill style={{ fontSize: '80px', color: '#0A78A4' }} />
                  <p style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '20px' }}>
                    Aucun service ce jour
                  </p>
                </div>
              ) : (
                <div className="center-button mb-4">
                  <button
                    onClick={handlePrevTrip}
                    disabled={currentTripIndex === 0}
                    className={`custom-button ${currentTripIndex === 0 ? 'disabled-button' : ''}`}
                    style={{ backgroundColor: darkRouteColor, borderRadius: '50%', padding: '12px' }}
                  >
                    <FaCaretLeft
                      className={`custom-icon2 ${currentTripIndex === 0 ? 'disabled-button' : ''} ${isColorDark(darkRouteColor) ? 'text-light' : 'text-dark'}`}
                      style={{ fontSize: '26px' }}
                      aria-label="Précédent"
                    />
                  </button>

                  <div
                    onClick={handleShowMap}
                    style={{
                      flexGrow: 1,
                      margin: '0 10px',
                      fontSize: '12px',
                      textTransform: 'uppercase',
                      background: `linear-gradient(90deg, ${darkRouteColor},rgb(0, 167, 223), ${darkRouteColor})`,
                      backgroundSize: '500% 500%',
                      animation: 'scrollGradient 20s linear infinite',
                      border: 'none',
                      padding: '10px',
                      color: 'white',
                      cursor: 'pointer',
                      outline: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      paddingLeft: '20px',
                      borderRadius: '100px',
                      height: '50px',
                      fontWeight: 'bold'
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        left: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <TbGpsFilled className="blinking-icon-gps" style={{ fontSize: '26px' }} />
                    </div>

                    
                    {displayText}
                    {activeVehiclesCount > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-6px',
                          right: '-6px',
                          backgroundColor: darkRouteColor,
                          borderRadius: '20%',
                          width: '40px',
                          height: '25px',
                          display: 'flex',
                          padding: '1px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: headerTextColor,
                          border: '2px solid white'
                        }}
                      >
                        {activeVehiclesCount}
                        <FaBusSimple style={{ marginLeft: '4px', color: headerTextColor }} />
                      </div>
                    )}
                    
                  </div>

                  <button
                    onClick={handleNextTrip}
                    disabled={currentTripIndex === tripIds.length - 1}
                    className={`custom-button ${currentTripIndex === tripIds.length - 1 ? 'disabled-button' : ''}`}
                    style={{ backgroundColor: darkRouteColor, borderRadius: '50%', padding: '12px' }}
                  >
                    <FaCaretRight
                      className={`custom-icon2 ${currentTripIndex === tripIds.length - 1 ? 'disabled-button' : ''} ${isColorDark(darkRouteColor) ? 'text-light' : 'text-dark'}`}
                      style={{ fontSize: '26px' }}
                    />
                  </button>
                </div>
              )}
            </>
          )}
          
        </Container>
      </div>

      {/* CONTENU PRINCIPAL : arrêts */}
      <Container>

      {/* {currentTripId} */}

        {/* Jauge d'occupation compacte */}
        {(!loadingSchedules && tripIds.length > 0) && (
          <>
            <div style={{ margin: '10px auto', width: '100%' }}>
              <Card className="shadow-sm" style={{ fontSize: '12px' }}>
                <Card.Header className="text-center py-1" style={{ fontWeight: 'bold', padding: '4px 8px', textTransform: 'uppercase'}}>
                  AFFLUENCE DU Véhicule
                </Card.Header>
                <Card.Body className="text-center p-2">
                  <ProgressBar 
                    now={occupancy} 
                    label={
                      <span style={{ 
                        fontWeight: 'bold',
                        color: isColorDark(
                          occupancy >= 80 ? '#dc3545' : occupancy >= 50 ? '#ffc107' : '#198754'
                        ) ? 'white' : 'black'
                      }}>
                      </span>
                    }
                    variant={occupancy >= 80 ? "danger" : occupancy >= 50 ? "warning" : "success"}
                    className="progress-bar-shimmer"
                    style={{ height: '15px' }}
                  />

                  <Button 
                    size="sm"
                    onClick={handleShowOccupancyModal}
                    className="w-100 mt-2"
                    disabled={tripNotStarted || tripEnded}
                    style={{ 
                      borderRadius: '10px', 
                      textTransform: 'uppercase',
                      background: (tripNotStarted || tripEnded)
                        ? '#ddd'
                        : `linear-gradient(90deg, ${routeColor}, ${lightenColor(routeColor, 2)}, #52b7e6)`,
                      border: 'none',
                      padding: '4px 0',
                      display: 'block', 
                      fontSize: '10px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: (tripNotStarted || tripEnded)
                        ? 'black'
                        : (isColorDark(routeColor) ? 'white' : 'black')
                    }}
                  >
                    {tripNotStarted 
                      ? "LE TRAJET N'A PAS ENCORE COMMENCÉ" 
                      : (tripEnded ? "LE TRAJET EST TERMINÉ" : "VOTER POUR L'AFFLUENCE")}
                  </Button>

                  <Card.Text className="mt-1" style={{ fontSize: '10px', color: '#777' }}>
                    Votez sérieusement pour aider les passagers.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            {isTripCanceled && (
              <div
                style={{
                  backgroundColor: '#bf1400',
                  borderRadius: '20px',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '5px',
                  marginBottom: '20px',
                  fontSize: '18px',
                  position: 'relative'
                }}
              >
                <TiDelete
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    fontSize: '24px'
                  }}
                />
                <span style={{ textTransform: 'uppercase', fontSize: '14px' }}>
                  Ce trajet est supprimé
                </span>
                {canceledReason && (
                  <div style={{ fontSize: '10px', marginTop: '-2px' }}>
                    Raison : {canceledReason}
                  </div>
                )}
              </div>
            )}

            {!isTripCanceled && isGpsNotConnected && (
              <div
                style={{
                  backgroundColor: 'grey',
                  borderRadius: '20px',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                  gap: '5px',
                  fontSize: '12px'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 17"
                  className="offline"
                  width="1.9em"
                  height="1.9em"
                  style={{ transform: "rotate(0deg)", marginLeft: "0em", opacity: 0.3 }}
                >
                  <path
                    fill="#ffffff"
                    fillRule="evenodd"
                    d="M5.31 8.5c0-1.84.87-3.5 2.26-4.68L6.63 3A7.23 7.23 0 0 0 4 8.5 7.23 7.23 0 0 0 6.64 14l.93-.82A6.14 6.14 0 0 1 5.3 8.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 17"
                  className="offline"
                  width="1.5em"
                  height="1.5em"
                  style={{ transform: "rotate(0deg)", marginLeft: "0.1em", opacity: 0.3 }}
                >
                  <path
                    d="M8.5 8.5c0 1.67.79 3.18 2.06 4.25l1-.89A4.38 4.38 0 0 1 9.9 8.5c0-1.33.64-2.52 1.65-3.36l-1-.89A5.56 5.56 0 0 0 8.5 8.5"
                    style={{ fill: "#ffffff" }}
                  />
                </svg>
                <span style={{ margin: '0 auto', textTransform: 'uppercase', fontSize: '12px' }}>
                  Véhicule non connecté
                </span>
              </div>
            )}

            {!isTripCanceled && !isGpsNotConnected && isToday(selectedDate) && isTripConnected && (
              <div
                style={{
                  backgroundColor: '#008004',
                  borderRadius: '20px',
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                  gap: '5px',
                  fontSize: '12px'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 17"
                  className="fFjEnj"
                  width="1.9em"
                  height="1.9em"
                  style={{ transform: "rotate(0deg)", marginLeft: "0em" }}
                >
                  <path
                    fill="#ffffff"
                    fillRule="evenodd"
                    d="M5.31 8.5c0-1.84.87-3.5 2.26-4.68L6.63 3A7.23 7.23 0 0 0 4 8.5 7.23 7.23 0 0 0 6.64 14l.93-.82A6.14 6.14 0 0 1 5.3 8.5Z"
                    clipRule="evenodd"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 17"
                  className="dPFQzF"
                  width="1.5em"
                  height="1.5em"
                  style={{ transform: "rotate(0deg)", marginLeft: "0.1em" }}
                >
                  <path
                    d="M8.5 8.5c0 1.67.79 3.18 2.06 4.25l1-.89A4.38 4.38 0 0 1 9.9 8.5c0-1.33.64-2.52 1.65-3.36l-1-.89A5.56 5.56 0 0 0 8.5 8.5"
                    style={{ fill: "#ffffff" }}
                  />
                </svg>
                <span style={{ margin: '0 auto', textTransform: 'uppercase', fontSize: '12px' }}>
                  {showVehicleInfo && tripVehicleInfo
                    ? <><strong>{tripVehicleInfo.brand}</strong> <span style={{ fontWeight: 'normal' }}>{tripVehicleInfo.model}</span></>
                    : "Véhicule connecté"}
                </span>
              </div>
            )}

            <div className="schedule-container">
              {currentTrip.map((sch, index) => {
                const uniqueKey = (["TOHM", "CAP", "CLT_LB", "AIREMOB"].includes(networkId))
                  ? sch.stop_sequence
                  : sch.stop_id;
                const city = findClosestCity(parseFloat(sch.stop_lat), parseFloat(sch.stop_lon));
                const isLastItem = (index === currentTrip.length - 1);
                const realTimeArr = tripRealTimeInfo[uniqueKey];
                const isSkipped = realTimeArr?.skipped === true;
                const theoreticalArrival = formatArrivalTime(sch.arrival_time);
                const now = new Date();
                const [thH, thM] = theoreticalArrival.split(':').map(Number);
                const schedTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), thH, thM);
                const graceMs = 60 * 1000;
                const isCurrent = isToday(selectedDate) &&
                  now.getHours() === thH &&
                  now.getMinutes() === thM;
                const isPast = isToday(selectedDate) &&
                  now.getTime() > (schedTime.getTime() + graceMs) &&
                  !isCurrent;
                const hrefLink = `/network/${networkId}/stop/${sch.stop_id}`;

                if (isTripCanceled) {
                  return (
                    <div
                      key={`${sch.trip_id}-${index}`}
                      className={`schedule-item ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`}
                    >
                   <div className={`dot-container ${isPast ? 'past' : ''}`} style={{ '--route-color': darkRouteColor }}>
                        <div
                          className={`dot ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''} ${isPast ? 'past' : ''}`}
                          style={{
                            backgroundColor: (index === 0 || isLastItem)
                              ? (isPast ? '#d1d1d1' : 'white')
                              : (isPast ? '#d1d1d1' : 'white'),
                            border: (index === 0 || isLastItem)
                              ? `6px solid ${isPast ? '#d1d1d1' : darkRouteColor}`
                              : 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <ImCross style={{ color: "#cc0000", fontSize: "44px" }} />
                        </div>
                      </div>
                      <div
                        className={`info-container ${isPast ? 'past' : ''}`}
                        style={{ position: 'relative', cursor: 'pointer' }}
                        onClick={() => navigate(hrefLink)}
                      >
                        <div className="stop">{sch.stop_name}</div>
                        {city && <div className="stop-city-sc">{city}</div>}
                        <div className="time" style={{ color: "#cc0000", textDecoration: 'line-through', fontWeight: 'bold' }}>
                          {theoreticalArrival}
                        </div>
                      </div>
                    </div>
                  );
                }

                if (isSkipped) {
                  return (
                    <div
                      key={`${sch.trip_id}-${index}`}
                      className={`schedule-item ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`}
                    >
                      <div className={`dot-container ${isPast ? 'past' : ''}`} style={{ '--route-color': darkRouteColor }}>
                        <div
                          className={`dot ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''} ${isPast ? 'past' : ''}`}
                          style={{
                            backgroundColor: (index === 0 || isLastItem)
                              ? (isPast ? '#d1d1d1' : 'white')
                              : (isPast ? '#d1d1d1' : 'white'),
                            border: (index === 0 || isLastItem)
                              ? `6px solid ${isPast ? '#d1d1d1' : routeColor}`
                              : 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <ImCross style={{ color: "#cc0000", fontSize: "44px" }} />
                        </div>
                      </div>
                      <div
                        className={`info-container ${isPast ? 'past' : ''}`}
                        style={{ position: 'relative', cursor: 'pointer' }}
                        onClick={() => navigate(hrefLink)}
                      >
                        <div className="stop">{sch.stop_name}</div>
                        {city && <div className="stop-city-sc">{city}</div>}
                        <div className="time" style={{ color: "#cc0000", textDecoration: 'line-through', fontWeight: 'bold' }}>
                          {theoreticalArrival}
                        </div>
                        <div style={{ color: "#cc0000", fontSize: "14px", fontWeight: "bold" }}>
                          <PiWarningCircleFill style={{ marginRight: "4px" }} /> Arrêt non desservi
                        </div>
                      </div>
                    </div>
                  );
                }

                const displayTime = realTimeArr?.time || theoreticalArrival;
                const [rtH, rrm] = displayTime.split(':').map(Number);
                const rtDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), rtH, rrm);
                const isCurrent2 = isToday(selectedDate) && now.getHours() === rtH && now.getMinutes() === rrm;
                const isPast2 = isToday(selectedDate) && now.getTime() > (rtDate.getTime() + graceMs) && !isCurrent2;
                const pastClass2 = isPast2 ? 'past' : '';
                const currentClass2 = isCurrent2 ? 'current' : '';
                let timeDiff = '';
                let adjustedClass = '';
                if (isToday(selectedDate) && realTimeArr && realTimeArr.time) {
                  const [rh, rm] = realTimeArr.time.split(':').map(Number);
                  const [tth, ttm] = theoreticalArrival.split(':').map(Number);
                  const diff = (rh * 60 + rm) - (tth * 60 + ttm);
                  if (diff > 0) {
                    timeDiff = `Retardé de ${diff} min`;
                    adjustedClass = 'time-late';
                  } else if (diff < 0) {
                    timeDiff = `En avance de ${Math.abs(diff)} min`;
                    adjustedClass = 'time-early';
                  } else {
                    timeDiff = `À l'heure`;
                    adjustedClass = 'time-on-time';
                  }
                }
                let timeColor = 'black';
                if (isToday(selectedDate) && realTimeArr && isTripConnected) {
                  const [rrh2, rrm2] = displayTime.split(':').map(Number);
                  const [tth, ttm] = theoreticalArrival.split(':').map(Number);
                  if (!Number.isNaN(rrh2) && !Number.isNaN(rrm2)) {
                    const dMin = (rrh2 * 60 + rrm2) - (tth * 60 + ttm);
                    if (dMin > 0) {
                      timeColor = '#d70000';
                    } else if (dMin < 0) {
                      timeColor = '#ea8021';
                    } else {
                      timeColor = '#008004';
                    }
                  }
                }
                return (
                  <div
                    key={`${sch.trip_id}-${index}`}
                    className={`schedule-item ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''}`}
                  >
                    <div className={`dot-container ${pastClass2}`} style={{ '--route-color': darkRouteColor }}>
                      <div
                        className={`dot ${index === 0 ? 'first' : ''} ${isLastItem ? 'last' : ''} ${pastClass2} ${currentClass2}`}
                        style={{
                          backgroundColor: (index === 0 || isLastItem)
                            ? (isPast2 ? '#d1d1d1' : 'white')
                            : (isPast2 ? '#d1d1d1' : 'white'),
                          border: (index === 0 || isLastItem)
                            ? `6px solid ${isPast2 ? '#d1d1d1' : darkRouteColor}`
                            : 'none'
                        }}
                      />
                    </div>
                    <div
                      className={`info-container ${pastClass2}`}
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onClick={() => navigate(hrefLink)}
                    >
                      {realTimeArr && !isPast2 && isTripConnected && (
                        <span style={{ position: 'absolute', right: '20px', top: '40%' }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 17"
                            className="fFjEnj"
                            width="1.8em"
                            height="1.8em"
                            style={{ transform: "rotate(175deg)", marginLeft: "-0.5em", marginTop: "-0.30em"}}
                          >
                            <path
                              fill="#ffbe00"
                              fillRule="evenodd"
                              d="M5.31 8.5c0-1.84.87-3.5 2.26-4.68L6.63 3A7.23 7.23 0 0 0 4 8.5 7.23 7.23 0 0 0 6.64 14l.93-.82A6.14 6.14 0 0 1 5.3 8.5Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 17"
                            className="dPFQzF"
                            width="1.4em"
                            height="1.4em"
                            style={{ transform: "rotate(175deg)", marginLeft: "-0.15em", marginTop: "-0.1em" }}
                          >
                           <path
                              d="M8.5 8.5c0 1.67.79 3.18 2.06 4.25l1-.89A4.38 4.38 0 0 1 9.9 8.5c0-1.33.64-2.52 1.65-3.36l-1-.89A5.56 5.56 0 0 0 8.5 8.5"
                              style={{ fill: "#ffbe00" }}
                            />
                          </svg>
                        </span>
                      )}
                      <div className="stop">{sch.stop_name}</div>
                      {city && <div className="stop-city-sc">{city}</div>}
                      <div className="time-container">
                        {realTimeArr && realTimeArr.time && realTimeArr.time !== theoreticalArrival && (
                          <div className="time-strikethrough">{theoreticalArrival}</div>
                        )}
                        {timeDiff && timeDiff !== "À l'heure" && (
                          <div className={`time-difference ${adjustedClass}`} style={{ color: 'black' }}>
                            {timeDiff.split(/(\d+\smin)/).map((part, pi) => (
                              <span key={pi} style={{ fontWeight: part.match(/\d+\smin/) ? 'bold' : 'normal' }}>
                                {part}{' '}
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className={`time ${adjustedClass}`} style={{ color: timeColor }}>
                        {displayTime}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Container>

      {/* MAP OVERLAY */}
      {showMap && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            zIndex: 9998,
            background: 'white'
          }}
        >
          <div
            onClick={handleCloseMap}
            style={{
              position: 'absolute',
              top: '15px',
              right: '25px',
              zIndex: 10000,
              cursor: 'pointer'
            }}
          >
            <AiFillCloseCircle
              style={{
                fontSize: '2.5rem',
                color: '#444',
                transition: 'transform 0.2s ease'
              }}
              onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
              onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            />
          </div>
          {loadingShape ? (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spinner animation="border" variant="primary" style={{ width: '4rem', height: '4rem' }} />
            </div>
          ) : (
            <MapContainer
            className="no-flicker"
            style={{ height: '100%', width: '100%', backgroundColor: '#14b0f84b' }}
            whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
            attributionControl={false}
            preferCanvas
            zoomAnimation={true}
            fadeAnimation={true}
            markerZoomAnimation={true}
          >
            <TileLayer
              url={getMapTileUrl()}
              attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
              tileSize={512}
              zoomOffset={-1}
              updateWhenIdle
              updateWhenZooming={false}
            />
              <MapBoundsSetter />
              {shapePoints.length > 0 && (
                <>
                  <Polyline 
                    positions={shapePoints} 
                    pathOptions={{ color: darkRouteColor, weight: 8 }} 
                  />
                  <AnimatedPolyline 
                    shapePoints={shapePoints} 
                    routeColor={darkRouteColor} 
                    drawingDuration={2000} 
                    windowTime={2100} 
                    pauseDuration={10000} 
                  />
                </>
              )}
              {(() => {
                const firstStopId = currentTrip.length > 0 ? currentTrip[0].stop_id : null;
                const lastStopId = currentTrip.length > 0 ? currentTrip[currentTrip.length - 1].stop_id : null;
                const getStopIcon = (stop) => {
                  if (stop.stop_id === firstStopId || stop.stop_id === lastStopId) {
                    const labelPosition = (mapRef.current) ? getDynamicLabelPosition(stop, mapRef.current) : 'above';
                    let labelContainerStyle = "";
                    if (labelPosition === 'above') {
                      labelContainerStyle = "position: absolute; bottom: calc(100% + 0px); left: 50%; transform: translateX(-50%);";
                    } else {
                      labelContainerStyle = "position: absolute; top: calc(100% + 0px); left: 50%; transform: translateX(-50%);";
                    }
                    let labelText = stop.stop_name;
                    if (stop.stop_id === firstStopId) {
                      labelText = "De : " + stop.stop_name;
                    } else if (stop.stop_id === lastStopId) {
                      labelText = "Vers : " + stop.stop_name;
                    }
                    return L.divIcon({
                      className: 'custom-icon special-stop',
                      html: `
                        <div style="position: relative;">
                          <div style="background-color: white; border-radius: 50%; width: 28px; height: 28px; border: 5px solid ${darkRouteColor};"></div>
                          <div style="${labelContainerStyle}">
                            <div style="background-color: white; padding: 4px 8px; border-radius: 20px; font-size: 10px; border: 2px solid black;font-weight: bold; color: black; white-space: nowrap;">
                              ${labelText}
                            </div>
                          </div>
                        </div>
                      `
                    });
                  } else {
                    return L.divIcon({
                      className: 'custom-icon',
                      html: `<div style="background-color: white; border-radius: 50%; width: 22px; height: 22px; border: 4px solid black;"></div>`
                    });
                  }
                };
                return stops.map((stop, i3) => {
                  const city = findClosestCity(parseFloat(stop.stop_lat), parseFloat(stop.stop_lon));
                  return (
                    <Marker
                      key={i3}
                      position={[stop.stop_lat, stop.stop_lon]}
                      icon={getStopIcon(stop)}
                      zIndexOffset={-1000}
                    >
                      <Popup maxWidth={200} minWidth={200} autoPan={false} interactive>
                        <div style={{ textAlign: 'center', padding: '10px' }}>
                          <div
                            style={{
                              fontSize: '18px',
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                              color: '#222',
                              marginBottom: '5px'
                            }}
                          >
                            {stop.stop_name}
                          </div>
                          {city && (
                            <div style={{ fontSize: '14px', color: '#777', fontStyle: 'italic' }}>
                              {city}
                            </div>
                          )}
                          <a
                            style={{
                              marginTop: '10px',
                              backgroundColor: '#0A78A4',
                              color: '#fff',
                              padding: '8px 12px',
                              borderRadius: '20px',
                              cursor: 'pointer',
                              fontSize: '14px',
                              display: 'block',
                              width: '100%',
                              textAlign: 'center',
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                              textDecoration: 'none',
                              transition: 'background-color 0.3s, transform 0.2s'
                            }}
                            onClick={() => navigate(`/network/${networkId}/stop/${stop.stop_id}`)}
                            onMouseOver={(e) => {
                              e.currentTarget.style.backgroundColor = '#095a7b';
                              e.currentTarget.style.transform = 'scale(1.05)';
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.backgroundColor = '#0A78A4';
                              e.currentTarget.style.transform = 'scale(1)';
                            }}
                          >
                            Voir les horaires
                          </a>
                        </div>
                      </Popup>
                    </Marker>
                  );
                });
              })()}
              {realTimeVehicles.map((veh) => (
                <AnimatedVehicleMarker
                  key={veh.id}
                  veh={veh}
                  routeInfo={routeInfo}
                  routeColor={routeColor}
                  selectedVehicleId={selectedVehicleId}
                  setSelectedVehicleId={setSelectedVehicleId}
                  vehicleMarkerRefs={vehicleMarkerRefs}
                  vehiclesData={vehiclesData}
                  realTimeSchedules={realTimeSchedules}
                  organizedSchedules={organizedSchedules}
                  createVehicleIcon={createVehicleIcon}
                  haversineDistanceMeters={haversineDistanceMeters}
                  formatDistance={formatDistance}
                  getTimeRemainingText={getTimeRemainingText}
                />
              ))}
            </MapContainer>
          )}
          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              width: '100%',
              textAlign: 'center',
              fontSize: '14px',
              padding: '5px',
              marginLeft: '5px',
              zIndex: 10000,
              color: 'black',
              opacity: 0.2
            }}
          >
            ID : {currentTripId}
          </div>
        </div>
      )}

      {/* TOAST favoris */}
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ zIndex: 9999999, position: 'fixed', top: '20px', right: '20px' }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          style={{ backgroundColor: 'white', border: '1px solid #ddd' }}
        >
          <Toast.Header>
            <FaCircleCheck style={{ color: 'green', marginRight: '8px' }} />
            <strong className="me-auto">Favoris mis à jour</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}

export default Schedule;
