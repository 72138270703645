// Dons.js
import React from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { loadStripe } from '@stripe/stripe-js';

// Charger Stripe avec ta clé publique
const stripePromise = loadStripe('pk_live_51QnezkRrVLpXh9Q0Ab8qhHd2x29QLHVAfo9sHXZjN9lAHICngP65Dh5hQD4iBKXrAWT651gONsCPnAg8G2ySflr600cQGzvFI2');

const Dons = () => {
  const handleDonate = async () => {
    console.log("Bouton cliqué, lancement de handleDonate.");
    try {
      // Récupérer l'instance Stripe
      const stripe = await stripePromise;
      console.log("Stripe instance :", stripe);
      
      // Rediriger vers Stripe Checkout avec un Price préconfiguré
      const { error } = await stripe.redirectToCheckout({
        // Remplace 'price_XXXXXXXXXXXX' par un identifiant de Price valide
        lineItems: [{ price: 'price_1QniLiRrVLpXh9Q0rH7PWUU2', quantity: 1 }],
        mode: 'payment',
        successUrl: window.location.origin + '/success',
        cancelUrl: window.location.origin + '/cancel',
      });
      
      if (error) {
        console.error("Erreur lors de la redirection :", error);
        alert(error.message);
      } else {
        console.log("Redirection effectuée sans erreur (ce qui est rare car la redirection devrait se produire).");
      }
    } catch (err) {
      console.error("Erreur dans handleDonate :", err);
      alert("Une erreur est survenue: " + err.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Bus Connect - Dons</title>
      </Helmet>
      <Container className="mt-4">
        <Card className="text-center">
          <Card.Header>
            <h2>Faire un Don</h2>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Votre soutien nous aide à améliorer continuellement notre service.
              Merci de contribuer à notre projet !
            </Card.Text>
            <Button variant="primary" onClick={handleDonate}>
              Faire un Don
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Dons;
