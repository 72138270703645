import React from "react";

function Lebus() {
  return (
    <div className="container my-5">
      {/* --- TITRE PRINCIPAL --- */}
      <div className="text-center mb-5">
        <h1 className="display-4">Lebus (Transports en commun de Clermont)</h1>
        <p className="lead">
          Réseau de transport de l’agglomération clermontoise dans l’Oise
        </p>
      </div>

      {/* --- SECTION PRESENTATION --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Présentation générale</h2>
          <p>
            <strong>Lebus</strong> (ou <em>Le Bus</em>) est le réseau de transport
            en commun organisé par la <strong>communauté de communes du
            Clermontois</strong>, située dans le département de l’Oise (en région
            Picardie, désormais Hauts-de-France). Lancé le <strong>15 décembre
            2014</strong>, le réseau dessert notamment les communes d’Agnetz,
            Breuil-le-Sec, Breuil-le-Vert, Clermont, Fitz-James et Neuilly-sous-
            Clermont. Il comprend quatre lignes régulières ainsi qu’un service de
            <em>transport à la demande (TAD)</em>.
          </p>
          <p>
            Après une décennie d’études, les communes se sont regroupées au sein
            du <strong>Syndicat intercommunal des transports collectifs de
            l'agglomération clermontoise (SITCAC)</strong> pour créer ce service
            (initialement 2 lignes régulières + 2 TAD). En <strong>janvier 2017</strong>,
            une troisième ligne voit le jour, réorganisant également le transport
            à la demande. Plus tard, au <strong>1er janvier 2019</strong>,
            la communauté de communes du Clermontois prend la compétence
            transport (intégrant le SITCAC) pour étendre le réseau. L’exploitation
            est confiée depuis le <strong>2 janvier 2020</strong> à
            <strong> Transdev Oise Cabaro</strong>. Une quatrième ligne est
            ajoutée le <strong>8 juillet 2022</strong>.
          </p>
          <p>
            Le <strong>10 juillet 2023</strong>, dans le cadre du SISMO (Système
            Intégré des Services à la Mobilité dans l’Oise), la carte Pass Oise
            Mobilité est remplacée par la <strong>carte Pass Pass</strong>, valable
            sur la plupart des réseaux de la région Hauts-de-France.
          </p>
        </div>
      </div>

      {/* --- SECTION HISTORIQUE / AOM --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Historique et Autorité Organisatrice</h2>
          <p>
            De 2014 à 2019, le réseau relevait du <em>SITCAC</em>, autorité
            organisatrice de la mobilité créée par arrêté préfectoral (27 mai
            2013). L’objectif était de l’intégrer à terme dans la
            <strong>Communauté de communes du Clermontois</strong>, ce qui est
            devenu effectif au 1er janvier 2019. Ainsi, la CCC est désormais
            l’AOM (autorité organisatrice de la mobilité) et peut envisager
            d’étendre le service à d’autres communes et d’approfondir les
            questions de mobilité (bus, train, covoiturage, vélo…).
          </p>
          <p>
            À partir du <strong>2 janvier 2020</strong>, l’exploitation du réseau
            a été confiée à <strong>Transdev Oise Cabaro</strong>.  
            En <strong>juillet 2022</strong>, la ligne 4 a été créée (Agnetz ↔
            Breuil-le-Vert). En <strong>juillet 2023</strong>, la carte Pass Pass
            remplace l’ancien Pass Oise Mobilité.
          </p>
        </div>
      </div>

      {/* --- SECTION RESEAU ACTUEL --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Le réseau actuel</h2>
          <p>
            En 2023, <strong>Lebus</strong> se compose de <strong>4 lignes
            régulières</strong> (1, 2, 3 et 4) et d’un service de <em>transport à
            la demande</em> baptisé « Allo le TAD ».  
            Les lignes régulières circulent principalement du lundi au vendredi,
            avec une offre réduite le samedi.
          </p>
        </div>
      </div>

      {/* --- SECTION LIGNES REGULIERES --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Lignes régulières</h3>
          <p>
            Voici les principales caractéristiques de chaque ligne :
          </p>

          {/* LIGNE 1 */}
          <div className="card mb-3 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">
                Ligne 1 : Fitz-James (Centre Aquatique / Louis Aragon) ↔ Breuil-le-Sec (Verdun / Croix Blanche)
              </h5>
              <p className="card-text">
                <strong>Ouverture :</strong> 3 janvier 2017  
                <br />
                <strong>Principales communes desservies :</strong> Fitz-James, Clermont, Breuil-le-Sec  
                <br />
                <strong>Nombre d’arrêts :</strong> 21  
                <br />
                <strong>Fréquence :</strong> env. toutes 90 min (lun-ven), 2 AR le samedi matin  
                <br />
                <strong>Particularités :</strong> dessert le centre-ville de Clermont en heures creuses, la Croix Blanche, le
                centre aquatique, etc.
              </p>
            </div>
          </div>

          {/* LIGNE 2 */}
          <div className="card mb-3 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">
                Ligne 2 : Clermont (Fernel) ↔ Breuil-le-Vert (Grand Air)
              </h5>
              <p className="card-text">
                <strong>Ouverture :</strong> 3 janvier 2017  
                <br />
                <strong>Communes desservies :</strong> Agnetz, Clermont, Breuil-le-Vert  
                <br />
                <strong>Nombre d’arrêts :</strong> 25  
                <br />
                <strong>Horaires :</strong> 7h-18h40 env., 8 à 6 AR selon période scolaire ou vacances, 3 AR le samedi  
                <br />
                <strong>Particularités :</strong> dessert le Collège Jean Fernel, le Lycée Cassini, gare de Clermont, 
                centre hospitalier, etc.
              </p>
            </div>
          </div>

          {/* LIGNE 3 */}
          <div className="card mb-3 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">
                Ligne 3 : Clermont (Gare SNCF) ↔ Mouy (Centre)
              </h5>
              <p className="card-text">
                <strong>Ouverture :</strong> 3 janvier 2017  
                <br />
                <strong>Communes desservies :</strong> Clermont, Breuil-le-Vert, Neuilly-sous-Clermont, Cambronne-lès-Clermont, 
                Bury, Mouy  
                <br />
                <strong>Nombre d’arrêts :</strong> 18  
                <br />
                <strong>Horaires :</strong> L-V : 6h20-18h50 (4,5 AR), samedi : 8h30-13h05 (2 AR)  
                <br />
                <strong>Particularités :</strong> dessert la gare de Clermont, la gare de Mouy-Bury, 
                centres hospitaliers, etc.
              </p>
            </div>
          </div>

          {/* LIGNE 4 */}
          <div className="card mb-3 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">
                Ligne 4 : Agnetz (Boulincourt / Canonnière) ↔ Breuil-le-Vert (Centre)
              </h5>
              <p className="card-text">
                <strong>Ouverture :</strong> 8 juillet 2022  
                <br />
                <strong>Communes desservies :</strong> Agnetz, Clermont, Breuil-le-Vert  
                <br />
                <strong>Nombre d’arrêts :</strong> 19  
                <br />
                <strong>Horaires :</strong> L-V (6h15-18h10) avec 7 AR.  
                <br />
                <strong>Particularités :</strong> dessert la gare de Clermont, le lycée Cassini, etc.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* --- SECTION TAD --- */}
      <div className="row mb-4">
        <div className="col">
          <h3>Transport à la demande : Allo le TAD</h3>
          <p>
            Ce service fonctionne sur cinq zones (1 à 5). Les déplacements
            s’effectuent entre les zones 1-4 et la zone 5 (communes desservies
            par les lignes régulières). Il est opéré par <strong>Transdev Oise
            Cabaro</strong> et nécessite une réservation préalable.
          </p>
          <p>
            <strong>Zones :</strong>  
            <ul>
              <li>Zone 1 : Agnetz, Étouy</li>
              <li>Zone 2 : Erquery, Fouilleuse, Lamécourt, Maimbeville, Rémécourt, Saint-Aubin-sous-Erquery</li>
              <li>Zone 3 : Catenoy, Nointel</li>
              <li>Zone 4 : Bury, Cambronne-lès-Clermont, Mouy, Neuilly-sous-Clermont</li>
              <li>Zone 5 : Breuil-le-Sec, Breuil-le-Vert, Clermont, Fitz-James</li>
            </ul>
            <strong>Horaires :</strong> mardi au vendredi (13h30-18h).  
            Chaque jour est dédié à une zone particulière (mardi = zone 1, mercredi = zone 2, etc.).
          </p>
        </div>
      </div>

      {/* --- SECTION MATERIEL ROULANT --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Matériel roulant</h2>
          <p>
            Jusqu’en 2019, les lignes étaient exploitées avec :
          </p>
          <ul>
            <li>Minibus <strong>Mercedes-Benz Sprinter</strong></li>
            <li>Midibus type <em>Heuliez GX 137</em></li>
            <li>Un autobus standard <em>Irisbus Citelis 12</em></li>
            <li>Pour le TAD : minibus <em>Fiat Scudo</em></li>
          </ul>
          <p>
            Depuis <strong>2020</strong>, <em>Transdev Oise Cabaro</em> utilise des
            minibus Mercedes-Benz Sprinter, midibus Heuliez (GX 117, GX 127, GX 137),
            ainsi qu’un autocar Irisbus Récréo 10m.  
          </p>
        </div>
      </div>

      {/* --- SECTION FINANCEMENT --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Financement, Billetterie et Tarification</h2>
          <p>
            Le coût du réseau (1,978 M€ sur trois ans) est principalement financé
            par les communes et, dès janvier 2014, par le versement transport
            (taux 0,30 % de la masse salariale).  
          </p>
          <h5>Tarifs (inchangés en 2023) :</h5>
          <ul>
            <li>
              <strong>Ticket unitaire (1 €)</strong> : valable 1 heure, toutes
              correspondances comprises, vendu à bord.
            </li>
            <li>
              <strong>Carnet 10 voyages (8 €)</strong> : valable 1 heure,
              non nominatif, à charger sur billet sans contact ou carte Pass Pass.
            </li>
            <li>
              <strong>Carnet 10 voyages tarif réduit (4 €)</strong> : réservé aux
              bénéficiaires de la CMU, nominatif, valable 1 heure.
            </li>
            <li>
              <strong>Abonnement mensuel (20 €)</strong> : nominatif, valable 1
              mois, voyages illimités (10 € pour les -26 ans et bénéficiaires CMU).
            </li>
            <li>
              <strong>Abonnement scolaire annuel</strong> : 2 voyages
              gratuits/jour scolaire (aller-retour) sur trajet domicile ↔
              établissement.
            </li>
          </ul>
        </div>
      </div>

      {/* --- SECTION TRAFIC --- */}
      <div className="row mb-4">
        <div className="col">
          <h2>Fréquentation</h2>
          <p>
            De <strong>22 000 voyageurs en 2015</strong>, la fréquentation est
            passée à <strong>32 000</strong> en 2016, puis <strong>42 000</strong>
            en 2017. Le service s’est élargi aux collégiens et lycéens pour
            faciliter leurs déplacements selon leurs horaires de cours.
          </p>
        </div>
      </div>

      {/* --- SECTION CONCLUSION --- */}
      <div className="row">
        <div className="col text-center">
          <h2>Conclusion</h2>
          <p>
            Le réseau <strong>Lebus</strong>, créé en 2014 pour l’agglomération
            clermontoise, offre aujourd’hui <strong>quatre lignes régulières</strong>
            et un service de <em>transport à la demande</em> sur cinq zones, 
            desservant plus largement la communauté de communes du Clermontois 
            depuis 2019. Son exploitation par <strong>Transdev Oise Cabaro</strong> 
            et sa tarification accessible (1 € le ticket, différents abonnements) 
            témoignent de la volonté de favoriser la mobilité locale à un coût 
            maîtrisé. Avec une fréquentation en forte hausse depuis son lancement, 
            Lebus s’impose comme un acteur essentiel des déplacements dans le 
            Clermontois.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Lebus;
