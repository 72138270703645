// StopDepartures.js
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import protobuf from 'protobufjs';
import { Container, ListGroup } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { IoCaretBackCircleOutline } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import ReactDOMServer from 'react-dom/server';
import './StopDepartures.css';
import { CiCircleMore } from "react-icons/ci";

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoid2NsZW1lbnQ2MCIsImEiOiJjbDEyOXRlaHEyZzkyM2JrYjJnN3ZrY2JrIn0.JrVxUKnkgR1vx8FWsNp9tQ";

/* ------------------------------------------
 *           Gestion cookies
 * ------------------------------------------ */
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

/* ------------------------------------------
 *   Fonction utilitaire pour assombrir une couleur
 * ------------------------------------------ */
function darkenColor(hexColor, amount = 0.1) {
  let color = hexColor.replace("#", "");
  if (color.length === 3) {
    color = color.split("").map(c => c + c).join("");
  }
  const num = parseInt(color, 16);
  let r = (num >> 16) & 0xff;
  let g = (num >> 8) & 0xff;
  let b = num & 0xff;

  r = Math.max(Math.floor(r * (1 - amount)), 0);
  g = Math.max(Math.floor(g * (1 - amount)), 0);
  b = Math.max(Math.floor(b * (1 - amount)), 0);

  return "#" + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
}

/* ------------------------------------------
 *           Fonction utilitaire : getContrastColor
 * ------------------------------------------ */
function getContrastColor(hexColor) {
  let color = hexColor.replace("#", "");
  if (color.length === 3) {
    color = color.split("").map(c => c + c).join("");
  }
  if (color.length !== 6) {
    return "#000000";
  }
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

/* ------------------------------------------
 *       Fonction utilitaire : getWarningMarkup
 * ------------------------------------------ */
function getWarningMarkup(reason, departure) {
  const originalColor =
    departure.route_color && departure.route_color.length === 6
      ? `#${departure.route_color}`
      : "#cccccc";
  const darkColor = darkenColor(originalColor, 0.1);
  const iconMarkup = ReactDOMServer.renderToStaticMarkup(
    <RiErrorWarningFill
      style={{
        marginRight: '4px',
        verticalAlign: 'middle',
        color: getContrastColor(darkColor)
      }}
    />
  );
  return `<span style="color:${getContrastColor(darkColor)};font-weight:bold;">${iconMarkup}Supprimé : ${reason}</span>`;
}

/* ------------------------------------------
 *   Fonction utilitaire : getTimeDifferenceInMinutes
 * ------------------------------------------ */
function getTimeDifferenceInMinutes(departureTimeStr) {
  const [H, M, S] = departureTimeStr.split(":").map(Number);
  const now = new Date();
  const depDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), H, M, S);
  const diffMs = depDate - now;
  return Math.round(diffMs / 60000);
}

/* ------------------------------------------
 *   Fonction utilitaire : getScheduledTimestamp
 * ------------------------------------------ */
function getScheduledTimestamp(timeStr, referenceTimestamp) {
  const [H, M, S] = timeStr.split(':').map(Number);
  const refDate = new Date(referenceTimestamp * 1000);
  let scheduled = new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate(), H, M, S);
  const diff = scheduled.getTime() / 1000 - referenceTimestamp;
  if (diff < -43200) {
    scheduled.setDate(scheduled.getDate() + 1);
  } else if (diff > 43200) {
    scheduled.setDate(scheduled.getDate() - 1);
  }
  return Math.floor(scheduled.getTime() / 1000);
}

/* ------------------------------------------
 *    Fonction utilitaire : getDisplayTime
 * ------------------------------------------
 * - > 60s avant : compte à rebours
 * - 0 à 60s avant : "à l'approche" (blink)
 * - 0 à -60s après : "à quai" (blink)
 * - Au-delà : affiche le délai (mais le départ sera retiré par le filtre)
 */
function getDisplayTime(departureTime) {
  const now = new Date();
  const [H, M, S] = departureTime.split(":").map(Number);
  const depDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), H, M, S);
  const diffSeconds = (depDate - now) / 1000; // différence en secondes

  if (diffSeconds >= 60) {
    const diffMinutes = Math.round(diffSeconds / 60);
    if (diffMinutes < 60) {
      return { topValue: `${diffMinutes}`, bottomValue: "MIN", blinking: false };
    } else {
      const HH = String(H).padStart(2, "0");
      const MM = String(M).padStart(2, "0");
      return { topValue: `${HH}:${MM}`, bottomValue: "", blinking: false };
    }
  } else if (diffSeconds >= 0 && diffSeconds < 60) {
    return { topValue: "à l'approche", bottomValue: "", blinking: true };
  } else if (diffSeconds < 0 && diffSeconds >= -40) {
    return { topValue: "à l'arrêt", bottomValue: "", blinking: true };
  } else if (diffSeconds < -40 && diffSeconds >= -90) {
    return { topValue: "déjà passé", bottomValue: "", blinking: true };
  } else {
    const delayMinutes = Math.round(Math.abs(diffSeconds) / 60);
    return { topValue: `${delayMinutes}`, bottomValue: "MIN", blinking: false };
  }
}

/* ------------------------------------------
 * Composant FadingText pour le fade in/out du topValue
 * ------------------------------------------ */
function FadingText({ value, style, className }) {
  const [displayValue, setDisplayValue] = useState(value);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    if (value !== displayValue) {
      setOpacity(0);
      const timeout = setTimeout(() => {
        setDisplayValue(value);
        setOpacity(1);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [value, displayValue]);

  return (
    <span style={{ ...style, transition: "opacity 500ms", opacity }} className={className}>
      {displayValue}
    </span>
  );
}

/* ------------------------------------------
 * Composant AnimatedBlinkingText pour l'effet slide-in puis blink
 * ------------------------------------------ */
function AnimatedBlinkingText({ value, style, className }) {
  const [animationPhase, setAnimationPhase] = useState('slide'); // 'slide' -> 'blink'

  useEffect(() => {
    // Relancer l'animation quand la valeur change
    setAnimationPhase('slide');
    const timer = setTimeout(() => {
      setAnimationPhase('blink');
    }, 500); // durée de la phase slide (500ms)
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <span
      className={`${className} ${animationPhase === 'slide' ? 'slide-in' : 'blinking'}`}
      style={style}
    >
      {value}
    </span>
  );
}

/* ------------------------------------------
 *          Composant StopDepartures
 * ------------------------------------------ */
function StopDepartures() {
  const { networkId, stopId } = useParams();

  // STATES
  const [initialDepartures, setInitialDepartures] = useState(null);
  const [stopName, setStopName] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [visibleCount, setVisibleCount] = useState(10);

  // REFS
  const rootRef = useRef(null);
  const gtfsHorairesRef = useRef(null);
  const departuresRef = useRef([]);
  const intervalIdRef = useRef(null);

  /* ------------------------------------------
   *      Au MONTAGE : Charger le .proto + Fetch
   * ------------------------------------------ */
  useEffect(() => {
    const controller = new AbortController();
    protobuf
      .load("/protos/gtfs-realtime.proto")
      .then((loadedRoot) => {
        rootRef.current = loadedRoot;
        fetchDepartures(controller);
      })
      .catch(() => {});

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      controller.abort();
    };
  }, [networkId, stopId]);

  /* ------------------------------------------
   *  Gérer la recherche du nom de ville (Mapbox)
   * ------------------------------------------ */
  useEffect(() => {
    if (latitude && longitude) {
      const cityCookieName = `cityName_${latitude}_${longitude}`;
      const cachedCity = getCookie(cityCookieName);
      if (cachedCity) {
        setCityName(cachedCity);
      } else {
        getCityNameFromAPI(latitude, longitude).then((city) => {
          if (city) {
            setCityName(city);
            setCookie(cityCookieName, city, 7);
          }
        });
      }
    }
  }, [latitude, longitude]);

  async function getCityNameFromAPI(lat, lon) {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?access_token=${MAPBOX_TOKEN}&language=fr`;
    try {
      const resp = await fetch(url);
      const data = await resp.json();
      if (data && data.features && data.features.length > 0) {
        let city = null;
        const feat = data.features[0];
        if (feat.context) {
          for (let c of feat.context) {
            if (c.id.startsWith("place") || c.id.startsWith("locality") || c.id.startsWith("region")) {
              city = c.text;
              break;
            }
          }
        }
        if (!city) {
          city = feat.text;
        }
        return city;
      }
    } catch {}
    return null;
  }

  /* ------------------------------------------
   *      FONCTION PRINCIPALE : fetchDepartures
   * ------------------------------------------ */
  async function fetchDepartures(controller) {
    setErrorMessage(null);
    try {
      const response = await axios.get(
        `/getNextDepartures.php?network_id=${encodeURIComponent(networkId)}&stop_id=${encodeURIComponent(stopId)}`,
        {
          signal: controller.signal,
          timeout: 15000,
        }
      );
      const data = response.data;
      if (data && Array.isArray(data.departures)) {
        departuresRef.current = data.departures.map((d) => ({
          ...d,
          delay: null,
          canceled: d.canceled || false,
          reason: d.reason || "",
          effective_departure_time: normalizeTimeStr(d.departure_time),
        }));
        gtfsHorairesRef.current = data.gtfs_horaires || null;
        if (data.stop_name) {
          setStopName(data.stop_name);
          setCookie(`stopName_${networkId}_${stopId}`, data.stop_name, 7);
        } else {
          setStopName(null);
        }
        setLatitude(data.latitude || null);
        setLongitude(data.longitude || null);

        await applyRealTimeImmediately(controller);
        filterAndSort();
        setInitialDepartures([...departuresRef.current]);
        startInterval();
      } else {
        departuresRef.current = [];
        gtfsHorairesRef.current = null;
        setInitialDepartures([]);
        setStopName(null);
        setLatitude(null);
        setLongitude(null);
      }
    } catch (err) {
      if (err.code === "ECONNABORTED") {
        setErrorMessage("Le serveur met trop de temps à répondre (timeout).");
      } else if (err.name === "CanceledError") {
        // Requête annulée
      } else {
        setErrorMessage("Une erreur est survenue : " + err.message);
      }
    }
  }

  /* ------------------------------------------
   *    Appliquer la RT immédiatement
   * ------------------------------------------ */
  async function applyRealTimeImmediately(controller) {
    const root = rootRef.current;
    const gtfsHoraires = gtfsHorairesRef.current;
    if (!root || !gtfsHoraires || departuresRef.current.length === 0) return;
    try {
      const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
      const cachedFeedUrl = '/getGtfsFeedCached.php?feedUrl=' + encodeURIComponent(gtfsHoraires);
      const response = await fetch(cachedFeedUrl, { signal: controller.signal });
      const arrayBuffer = await response.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);
      const message = FeedMessage.decode(buffer);
      const feed = FeedMessage.toObject(message, { longs: String, defaults: true });
      const realTimeUpdates = {};
      if (feed.entity) {
        feed.entity.forEach((ent) => {
          const globalTripCanceled = ent.tripUpdate?.trip?.scheduleRelationship === 4;
          const rtTripId = ent.tripUpdate?.trip?.tripId;
          if (!rtTripId) return;
          if (ent.tripUpdate?.stopTimeUpdate) {
            ent.tripUpdate.stopTimeUpdate.forEach((stu) => {
              if (!stu.stopId) return;
              const isStopSkipped = stu.scheduleRelationship === 1;
              const rawTime = stu.departure?.time ?? stu.arrival?.time ?? null;
              const rtTime = rawTime ? parseInt(rawTime, 10) : null;
              const rawDelay = stu.departure?.delay ?? stu.arrival?.delay ?? null;
              const rtDelay = rawDelay ? parseInt(rawDelay, 10) : null;
              const key = rtTripId + "|" + stu.stopId;
              if (globalTripCanceled) {
                realTimeUpdates[key] = { canceled: true, reason: "Trajet supprimé" };
              } else if (isStopSkipped) {
                realTimeUpdates[key] = { canceled: true, reason: "Supprimé" };
              } else if (rtTime !== null) {
                realTimeUpdates[key] = { time: rtTime, delay: rtDelay };
              }
            });
          }
        });
      }
      departuresRef.current = departuresRef.current.map((d) => {
        const key = d.trip_id + "|" + d.stop_id;
        if (d.canceled) {
          return {
            ...d,
            delay: getWarningMarkup(d.reason || "", d),
          };
        }
        const rt = realTimeUpdates[key];
        if (rt?.canceled) {
          return {
            ...d,
            canceled: true,
            reason: rt.reason,
            delay: getWarningMarkup(rt.reason, d),
          };
        } else if (rt?.time) {
          const normalized = normalizeTimeFromTimestamp(rt.time);
          let computedDelay;
          if (rt.delay === null || rt.delay === undefined) {
            computedDelay = rt.time - getScheduledTimestamp(d.departure_time, rt.time);
          } else {
            computedDelay = rt.delay;
          }
          return {
            ...d,
            effective_departure_time: normalized,
            delay: delayToString(computedDelay),
          };
        } else {
          const normalized = normalizeTimeStr(d.departure_time);
          return {
            ...d,
            effective_departure_time: normalized,
            delay: "Temps théorique",
          };
        }
      });
    } catch {
      // En cas d'erreur, ne rien faire
    }
  }

  /* ------------------------------------------
   *            updateWithRealTime
   * ------------------------------------------ */
  async function updateWithRealTime(controller) {
    const root = rootRef.current;
    const gtfsHoraires = gtfsHorairesRef.current;
    if (!root || !gtfsHoraires || departuresRef.current.length === 0) return;
    try {
      const FeedMessage = root.lookupType("transit_realtime.FeedMessage");
      const cachedFeedUrl = '/getGtfsFeedCached.php?feedUrl=' + encodeURIComponent(gtfsHoraires);
      const response = await fetch(cachedFeedUrl, { signal: controller.signal });
      const arrayBuffer = await response.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);
      const message = FeedMessage.decode(buffer);
      const feed = FeedMessage.toObject(message, { longs: String, defaults: true });
      const realTimeUpdates = {};
      if (feed.entity) {
        feed.entity.forEach((ent) => {
          const globalTripCanceled = ent.tripUpdate?.trip?.scheduleRelationship === 4;
          const rtTripId = ent.tripUpdate?.trip?.tripId;
          if (!rtTripId) return;
          if (ent.tripUpdate?.stopTimeUpdate) {
            ent.tripUpdate.stopTimeUpdate.forEach((stu) => {
              if (!stu.stopId) return;
              const isStopSkipped = stu.scheduleRelationship === 1;
              const rawTime = stu.departure?.time ?? stu.arrival?.time ?? null;
              const rtTime = rawTime ? parseInt(rawTime, 10) : null;
              const rawDelay = stu.departure?.delay ?? stu.arrival?.delay ?? null;
              const rtDelay = rawDelay ? parseInt(rawDelay, 10) : null;
              const key = rtTripId + "|" + stu.stopId;
              if (globalTripCanceled) {
                realTimeUpdates[key] = { canceled: true, reason: "Trajet supprimé" };
              } else if (isStopSkipped) {
                realTimeUpdates[key] = { canceled: true, reason: "Supprimé" };
              } else if (rtTime !== null) {
                realTimeUpdates[key] = { time: rtTime, delay: rtDelay };
              }
            });
          }
        });
      }
      departuresRef.current = departuresRef.current.map((d) => {
        const key = d.trip_id + "|" + d.stop_id;
        if (d.canceled) {
          return {
            ...d,
            delay: getWarningMarkup(d.reason, d),
          };
        }
        const rt = realTimeUpdates[key];
        if (rt?.canceled) {
          return {
            ...d,
            canceled: true,
            reason: rt.reason,
            delay: getWarningMarkup(rt.reason, d),
          };
        } else if (rt?.time) {
          const normalized = normalizeTimeFromTimestamp(rt.time);
          let computedDelay;
          if (rt.delay === null || rt.delay === undefined) {
            computedDelay = rt.time - getScheduledTimestamp(d.departure_time, rt.time);
          } else {
            computedDelay = rt.delay;
          }
          return {
            ...d,
            effective_departure_time: normalized,
            delay: delayToString(computedDelay),
          };
        } else {
          const normalized = normalizeTimeStr(d.departure_time);
          return {
            ...d,
            effective_departure_time: normalized,
            delay: "Temps théorique",
          };
        }
      });
    } catch {
      // En cas d'erreur, ne rien faire
    }
  }

  /* ------------------------------------------
   *               startInterval
   * ------------------------------------------ */
  function startInterval() {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    intervalIdRef.current = setInterval(() => {
      refreshData();
    }, 10000);
  }

  /* ------------------------------------------
   *                  refreshData
   * ------------------------------------------ */
  async function refreshData() {
    console.log("RefreshData déclenché à", new Date());
    const localController = new AbortController();
    setErrorMessage(null);
    try {
      const response = await axios.get(
        `/getNextDepartures.php?network_id=${encodeURIComponent(networkId)}&stop_id=${encodeURIComponent(stopId)}`,
        {
          signal: localController.signal,
          timeout: 15000,
        }
      );
      const data = response.data;
      if (data && Array.isArray(data.departures)) {
        const updatedMap = {};
        for (let u of data.departures) {
          updatedMap[u.trip_id + "|" + u.stop_id] = u;
        }
        departuresRef.current = departuresRef.current.map((d) => {
          const key = d.trip_id + "|" + d.stop_id;
          const newDep = updatedMap[key];
          if (newDep) {
            return {
              ...d,
              canceled: newDep.canceled || false,
              reason: newDep.reason || "",
              departure_time: newDep.departure_time,
            };
          }
          return d;
        });
        gtfsHorairesRef.current = data.gtfs_horaires || null;
        if (data.stop_name) {
          setStopName(data.stop_name);
          setCookie(`stopName_${networkId}_${stopId}`, data.stop_name, 7);
        } else {
          setStopName(null);
        }
        setLatitude(data.latitude || null);
        setLongitude(data.longitude || null);
        await updateWithRealTime(localController);
        filterAndSort();
        setInitialDepartures([...departuresRef.current]);
      }
    } catch (err) {
      if (err.code === "ECONNABORTED") {
        setErrorMessage("Le serveur met trop de temps à répondre (timeout).");
      } else if (err.name === "CanceledError") {
        // Requête annulée
      } else {
        setErrorMessage("Une erreur est survenue : " + err.message);
      }
    }
  }

  /* ------------------------------------------
   *              Filtre + Tri
   * ------------------------------------------
   * Modification : on calcule directement la différence en secondes pour exclure
   * les départs en retard de plus d'1 minute.
   */
  function filterAndSort() {
    departuresRef.current = departuresRef.current.filter((d) => {
      const now = new Date();
      const [H, M, S] = d.effective_departure_time.split(":").map(Number);
      const depDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), H, M, S);
      const diffSeconds = (depDate.getTime() - now.getTime()) / 1000;
      return diffSeconds >= -90; // garder uniquement si le départ n'est pas en retard de plus d'1 minute
    });
    departuresRef.current.sort((a, b) => {
      const now = new Date();
      const calcDiff = (dep) => {
        const [H, M, S] = dep.effective_departure_time.split(":").map(Number);
        const depDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), H, M, S);
        return (depDate.getTime() - now.getTime()) / 1000;
      };
      return calcDiff(a) - calcDiff(b);
    });
  }

  /* ------------------------------------------
   *                Utils
   * ------------------------------------------ */
  function delayToString(delay) {
    if (delay === null) return "Temps théorique";
    if (Math.abs(delay) < 60) return "À l'heure";
    const delayMin = Math.round(delay / 60);
    if (delayMin > 0) return `Retardé de <strong>${delayMin} min</strong>`;
    return `En avance de <strong>${Math.abs(delayMin)} min</strong>`;
  }

  function normalizeTimeStr(timeStr) {
    const [H, M, S] = timeStr.split(":").map(Number);
    return normalizeTimeFromParts(H, M, S);
  }

  function normalizeTimeFromTimestamp(rtTime) {
    const rtDate = new Date(rtTime * 1000);
    const H = rtDate.getHours();
    const M = rtDate.getMinutes();
    const S = rtDate.getSeconds();
    return normalizeTimeFromParts(H, M, S);
  }

  function normalizeTimeFromParts(H, M, S) {
    const hours = H % 24;
    const HH = String(hours).padStart(2, "0");
    const MM = String(M).padStart(2, "0");
    const SS = String(S).padStart(2, "0");
    return `${HH}:${MM}:${SS}`;
  }

  /* ------------------------------------------
   *   Récupération du stopName en cookie
   * ------------------------------------------ */
  useEffect(() => {
    const cachedStopName = getCookie(`stopName_${networkId}_${stopId}`);
    if (cachedStopName) {
      setStopName(cachedStopName);
    }
  }, [networkId, stopId]);

  /* ------------------------------------------
   *                 RENDER
   * ------------------------------------------ */
  const displayedStopName = stopName || "Nom de l'arrêt non disponible";
  const departuresToDisplay = initialDepartures && Array.isArray(initialDepartures)
    ? initialDepartures.slice(0, visibleCount)
    : [];

  return (
    <Container style={{ marginTop: "5px" }}>
      {errorMessage && (
        <div style={{
          backgroundColor: "red",
          color: "#fff",
          padding: "10px",
          fontWeight: "bold",
          marginBottom: "10px",
          textAlign: "center",
        }}>
          {errorMessage}
        </div>
      )}

      <div style={{ textAlign: "center", marginBottom: "20px", padding: "12px" }}>
        {initialDepartures === null ? (
          <div className="placeholder-glow" style={{ marginBottom: "10px" }}>
            <div className="placeholder col-4" style={{
              margin: "0 auto",
              fontSize: "1.5rem",
              height: "1.5em",
              borderRadius: "4px",
              backgroundColor: "#ccc",
            }} />
          </div>
        ) : (
          <>
            <div style={{ marginBottom: "-20px" }}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "-10px",
              }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.history.back();
                  }}
                  style={{
                    color: "#000",
                    fontSize: "3.5rem",
                    marginTop: "-20px",
                    textDecoration: "none",
                    transition: "transform 0.2s ease",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                  onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                >
                  <IoCaretBackCircleOutline />
                </a>
                <div style={{
                  marginTop: "-20px",
                  marginRight: "10px",
                  fontSize: "0.8rem",
                  color: "#222",
                  textAlign: "right",
                }}>
                  {cityName}
                </div>
              </div>
              <h2 style={{
                fontWeight: "bold",
                margin: "0",
                color: "#000",
                textTransform: "uppercase",
              }}>
                {displayedStopName}
              </h2>
            </div>
          </>
        )}
      </div>

      {initialDepartures === null ? (
        <div className="d-flex justify-content-center" style={{ marginTop: "20px" }}>
          <div className="spinner-border" style={{ color: "#0A78A4" }} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : initialDepartures.length > 0 ? (
        <>
          <TransitionGroup component={ListGroup}>
            {departuresToDisplay.map((dep) => {
              const originalColor = dep.route_color && dep.route_color.length === 6
                ? `#${dep.route_color}`
                : "#cccccc";
              const bgColor = darkenColor(originalColor, 0.1);
              const textColor = getContrastColor(bgColor);
              const { topValue, bottomValue, blinking } = getDisplayTime(dep.effective_departure_time);
              const key = dep.trip_id + "|" + dep.stop_id;

              return (
                <CSSTransition key={key} classNames="fade" timeout={500}>
                  <ListGroup.Item
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor: bgColor,
                      color: textColor,
                      border: "none",
                      borderRadius: "5px",
                      padding: "5px 8px",
                      marginBottom: "5px",
                      height: "85px",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                      position: "relative",
                      opacity: dep.canceled ? 0.4 : 1,
                    }}
                  >
                    {dep.delay &&
                      dep.delay !== "Temps théorique" &&
                      !dep.delay.includes("Supprimé") && (
                        <div
                          style={{
                            position: "absolute",
                            top: "-2px",
                            right: "15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 17"
                            className="fFjEnjsd"
                            width="1.5em"
                            height="1.5em"
                            style={{
                              transform: "rotate(125deg)",
                              marginTop: "-0.0em",
                              marginLeft: "-0.2em",
                            }}
                          >
                            <path
                              fill={textColor}
                              fillRule="evenodd"
                              d="M5.31 8.5c0-1.84.87-3.5 2.26-4.68L6.63 3A7.23 7.23 0 0 0 4 8.5 7.23 7.23 0 0 0 6.64 14l.93-.82A6.14 6.14 0 0 1 5.3 8.5Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 17"
                            className="dPFQzFsd"
                            width="1.2em"
                            height="1.2em"
                            style={{
                              transform: "rotate(125deg)",
                              marginLeft: "0em",
                            }}
                          >
                            <path
                              d="M8.5 8.5c0 1.67.79 3.18 2.06 4.25l1-.89A4.38 4.38 0 0 1 9.9 8.5c0-1.33.64-2.52 1.65-3.36l-1-.89A5.56 5.56 0 0 0 8.5 8.5"
                              style={{ fill: textColor }}
                            />
                          </svg>
                        </div>
                      )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingRight: "5px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "-2px",
                            marginTop: "-2px",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#ffffff",
                            color: bgColor,
                            height: "30px",
                            padding: "5px 10px",
                            borderRadius: "0px 0px 15px 0px",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {dep.route_short_name}
                        </div>

                        <div
                          style={{
                            marginTop: "5px",
                            marginLeft: "10px",
                            fontSize: "0.85rem",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: dep.delay || "Temps théorique",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          top: "45%",
                          right: "15px",
                          transform: "translateY(-50%)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                           overflow: "hidden"  // Ajouté pour empêcher le débordement
                        }}
                      >
                        {blinking ? (
                          <AnimatedBlinkingText
                            value={topValue}
                            style={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              textDecoration: dep.canceled ? "line-through" : "none",
                            }}
                          />
                        ) : (
                          <FadingText
                            value={topValue}
                            style={{
                              fontSize: "2.0rem",
                              fontWeight: "bold",
                              textAlign: "center",
                              textDecoration: dep.canceled ? "line-through" : "none",
                            }}
                          />
                        )}
                        <div
                          style={{
                            fontSize: "0.75rem",
                            textAlign: "center",
                            marginTop: "-5px"
                          }}
                        >
                          {bottomValue}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: "5px",
                        fontSize: "0.60rem",
                        fontWeight: "bold",
                      }}
                    >
                      <span style={{ fontSize: "0.60rem", fontWeight: "lighter", marginRight: "5px" }}>
                        vers
                      </span>
                      {dep.direction_name.toUpperCase()}
                    </div>
                  </ListGroup.Item>
                </CSSTransition>
              );
            })}
          </TransitionGroup>

          {visibleCount < initialDepartures.length && (
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <div
                onClick={() => setVisibleCount(visibleCount + 10)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "60px",
                  transition: "all 0.3s ease",
                  cursor: "pointer"
                }}
              >
                <CiCircleMore style={{ fontSize: "4rem" }} />
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{
          backgroundColor: "#CC4F39",
          color: "white",
          fontWeight: "bold",
          textAlign: "center",
          padding: "10px",
        }}>
          {stopName !== null ? "Aucun départ à venir." : "Nom de l'arrêt non disponible."}
        </div>
      )}
    </Container>
  );
}

export default StopDepartures;
